import React, { Component } from "react";
import RadisssonBluePicture from "../../../Assets/png/RadisssonBluePicture.png";

class Blog extends Component {
  render() {
    return (
      <div className="flex flex-col gap-4 md:gap-[2rem] justify-center items-center w-full h-fit px-4 md:px-[2rem] py-6 md:py-[3rem] bg-[#F8F8F9]">
        <div className="relative flex gap-4 md:gap-[2rem] items-center flex-col w-full max-w-[1300px] font-Alexandria">
          <div className="flex flex-col items-center gap-2 md:gap-[1rem]">
            <div className="font-[600] text-2xl sm:text-3xl md:text-[4rem] text-primary-blue text-center">
              Blog
            </div>
            <div className="font-[500] text-base sm:text-xl md:text-2xl lg:text-[2rem] text-[#667085] text-center leading-normal md:leading-[50px] px-2 md:px-6">
              Our Blog expands on the latest news related to CES, and our
              technological advantage in solving customer problems and given
              important results
            </div>
          </div>
        </div>
        <div className="w-full max-w-[1300px] overflow-hidden">
          <img
            className="w-full h-auto object-cover"
            src={RadisssonBluePicture}
            alt="Radissson Blue Picture"
          />
        </div>
        <div className="flex flex-col w-full max-w-[1300px] gap-2 md:gap-[1rem] font-inter px-2 sm:px-4">
          <div className="font-[700] text-xl sm:text-2xl md:text-[3rem]">
            CES LAUNCH
          </div>
          <div className="font-[500] text-base sm:text-xl md:text-[2.25rem] text-[#667085]">
            February 15, 2024
          </div>
          <div className="font-[400] text-sm sm:text-base md:text-[2rem]">
            28 Comments
          </div>
          <div className="underline cursor-pointer text-primary-blue font-[600] text-base sm:text-xl md:text-[2.25rem] leading-normal md:leading-[50px]">
            Read More
          </div>
        </div>
      </div>
    );
  }
}
export default Blog;
