import React, { Component } from "react";
import CESAIICONSVG from "../../../Assets/svg/CESAIICONSVG.svg";
import ShareIconSVG from "../../../Assets/svg/ShareIconSVG.svg";
import SpeakerIconSVG from "../../../Assets/svg/SpeakerIconSVG.svg";
import CopyIconSVG from "../../../Assets/svg/CopyIconSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import Ripple from "../../Animations/Ripple/Ripple"; // Ensure Ripple is correctly imported
import {
  FetchIndividualChatSessionAction,
  ResetIsNewChatSession,
  ClearIndividualChatSession,
} from "../../../Actions/AI";

class ChatStarted extends Component {
  static propTypes = {
    individualChatSession: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        role: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
      })
    ),
    isIndividualChatSessionFetchSuccess: PropTypes.bool,
    FetchIndividualChatSessionAction: PropTypes.func.isRequired,
    continueChatSessionId: PropTypes.number,
    ResetIsNewChatSession: PropTypes.func.isRequired,
    ClearIndividualChatSession: PropTypes.func.isRequired,
    individualChatSessionFetchSuccess: PropTypes.bool,
    individualChatSessionFailMessage: PropTypes.string,
    navigate: PropTypes.func.isRequired,
    isClicked: PropTypes.bool, // Add this to know if we're waiting for a response
  };

  state = {
    speakingMessageId: null, // Tracks the ID of the message currently being spoken
  };

  chatEndRef = React.createRef();

  componentDidMount() {
    this.props.ClearIndividualChatSession();
    const {
      FetchIndividualChatSessionAction,
      continueChatSessionId,
      location,
    } = this.props;
    const id = this.props.location.pathname.split("/").pop();
    if (
      location.pathname.includes("/dashboard/ai-chatbot/") &&
      continueChatSessionId
    ) {
      FetchIndividualChatSessionAction(id);
    }
    this.props.ResetIsNewChatSession();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.individualChatSession !== this.props.individualChatSession) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    if (this.chatEndRef.current) {
      this.chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleTextToSpeech = (messageId, text) => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      this.setState({ speakingMessageId: null });
    } else {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.onend = () => {
        this.setState({ speakingMessageId: null }); // Reset after speech ends
      };
      speechSynthesis.speak(utterance);
      this.setState({ speakingMessageId: messageId }); // Set current speaking message
    }
  };

  renderMessages() {
    const { individualChatSession, isClicked } = this.props;

    if (!individualChatSession || !Array.isArray(individualChatSession)) {
      return (
        <div className="text-center py-4 text-gray-500">
          No chat sessions available.
        </div>
      );
    }

    const messages = individualChatSession.map((message) => {
      // Check if this is a temporary message (optimistic update)
      const isTemporaryMessage =
        typeof message.id === "string" && message.id.startsWith("temp_");

      return (
        <div
          key={message.id}
          className="flex flex-col gap-2 md:gap-[1rem] w-full h-fit mb-3 md:mb-0"
        >
          {message.role === "user" ? (
            <div className="flex w-full h-fit pr-2 md:pr-[3rem] pl-2 md:pl-[2rem] justify-end">
              <div
                className={`w-fit max-w-[80%] md:max-w-[41.875rem] h-fit px-3 py-2 md:px-[1.75rem] md:py-[1rem] rounded-2xl md:rounded-[7.75rem] bg-[#EEEDED] text-sm md:text-base ${
                  isTemporaryMessage ? "opacity-70" : ""
                }`}
              >
                {message.content}
              </div>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row gap-3 md:gap-[2rem] w-full h-fit">
              <img
                src={CESAIICONSVG}
                alt="CES AI ICON"
                className="w-8 h-8 md:w-[3.5rem] md:h-[3.5rem] ml-2 md:ml-[2rem] hidden md:block"
              />
              <div className="flex ml-2 md:ml-0">
                <div
                  className="w-fit max-w-[90%] md:max-w-[60rem] h-fit text-gray-700 bot-message-content bg-gray-50 md:bg-transparent px-3 py-2 md:px-0 md:py-0 rounded-2xl md:rounded-none text-sm md:text-base"
                  dangerouslySetInnerHTML={{ __html: message.content }}
                />
              </div>
            </div>
          )}

          {message.role === "assistant" && (
            <div className="flex w-auto md:w-[7rem] gap-2 md:gap-[0.5rem] ml-10 md:ml-[7.5rem] items-center">
              <button
                onClick={() =>
                  this.handleTextToSpeech(message.id, message.content)
                }
                className="flex justify-center items-center"
              >
                {this.state.speakingMessageId === message.id ? (
                  <Ripple
                    size="small"
                    className="w-5 h-5 md:w-auto md:h-auto"
                  /> // Render ripple when speaking
                ) : (
                  <img
                    src={SpeakerIconSVG}
                    alt="speaker"
                    className="w-5 h-5 md:w-auto md:h-auto"
                  /> // Render speaker icon otherwise
                )}
              </button>
              <button
                onClick={() => navigator.clipboard.writeText(message.content)}
                className="flex justify-center items-center"
              >
                <img
                  src={CopyIconSVG}
                  alt="copy"
                  className="w-5 h-5 md:w-auto md:h-auto"
                />
              </button>
              <button className="flex justify-center items-center">
                <img
                  src={ShareIconSVG}
                  alt="share"
                  className="w-5 h-5 md:w-auto md:h-auto"
                />
              </button>
            </div>
          )}
        </div>
      );
    });

    // Show the typing indicator if waiting for a response
    if (isClicked) {
      messages.push(
        <div
          key="typing-indicator"
          className="flex flex-col md:flex-row gap-3 md:gap-[2rem] w-full h-fit mb-3 md:mb-0"
        >
          <img
            src={CESAIICONSVG}
            alt="CES AI ICON"
            className="w-8 h-8 md:w-[3.5rem] md:h-[3.5rem] ml-2 md:ml-[2rem] hidden md:block"
          />
          <div className="flex ml-2 md:ml-0">
            <div className="w-fit px-5 py-3 bg-gray-50 rounded-2xl md:rounded-none">
              <div className="typing-dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return messages;
  }

  render() {
    return (
      <div className="flex w-full h-full bg-white justify-center max-w-[100rem] relative">
        <div className="rounded-lg flex flex-col gap-4 md:gap-[2rem] h-full overflow-y-auto pb-[6rem] md:pb-[10rem] px-2 md:px-[2rem] w-full">
          {this.renderMessages()}
          {/* Anchor for scrolling to the bottom */}
          <div ref={this.chatEndRef} />
        </div>

        {/* Add CSS for typing animation */}
        <style jsx="true">{`
          .typing-dots span {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #888;
            margin: 0 2px;
            animation: typing 1.4s infinite ease-in-out both;
          }

          .typing-dots span:nth-child(1) {
            animation-delay: 0s;
          }

          .typing-dots span:nth-child(2) {
            animation-delay: 0.2s;
          }

          .typing-dots span:nth-child(3) {
            animation-delay: 0.4s;
          }

          @keyframes typing {
            0%,
            80%,
            100% {
              transform: scale(0.6);
              opacity: 0.6;
            }
            40% {
              transform: scale(1);
              opacity: 1;
            }
          }
        `}</style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  individualChatSession: state.ai.individualChatSession,
  isIndividualChatSessionFetchSuccess:
    state.ai.isIndividualChatSessionFetchSuccess,
  continueChatSessionId: state.ai.continueChatSessionId,
  individualChatSessionFetchSuccess: state.ai.individualChatSessionFetchSuccess,
  individualChatSessionFailMessage: state.ai.individualChatSessionFailMessage,
  isClicked: state.ai.isClicked, // Add this to know if we're waiting for a response
});

const mapDispatchToProps = {
  FetchIndividualChatSessionAction,
  ResetIsNewChatSession,
  ClearIndividualChatSession,
};

export default withRouterHooks(
  connect(mapStateToProps, mapDispatchToProps)(ChatStarted)
);
