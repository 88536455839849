import React, { Component } from "react";
import ArrowleftPNG from "../../../../Assets/png/arrowleft.png";
import PlaceholderImage1 from "../../../../Assets/png/user.png";
import XSVG from "../../../../Assets/svg/X.svg";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import { connect } from "react-redux";
import Spinner from "../../../Animations/Spinner/Spinner";
import {
  GetIndividualAIResponse,
  GenerateAIResponse,
  resetIsAIResponseGenerated,
  EditAIResponse,
  SendMessageAction,
  addMessageToRedux,
} from "../../../../Actions/Messaging";

class CustomizeAIResponse extends Component {
  state = {
    tone: "Neutral", // Default tone
    length: "Medium", // Default response length
    editedResponse: "", // Store the edited
    isGeneratingResponse: false,
  };

  static propTypes = {
    GetIndividualAIResponse: PropTypes.func.isRequired,
    GenerateAIResponse: PropTypes.func.isRequired,
    EditAIResponse: PropTypes.func.isRequired,
    resetIsAIResponseGenerated: PropTypes.func.isRequired,
    SendMessageAction: PropTypes.func.isRequired,
    addMessageToRedux: PropTypes.func.isRequired,
    fetchedIndividualAIResponse: PropTypes.object,
    phone_number: PropTypes.string.isRequired,
    backToMessageIntent: PropTypes.func.isRequired,
    closeShowAIResponse: PropTypes.func.isRequired,
    aiResponseGenerated: PropTypes.bool,
  };

  componentDidMount() {
    const { phone_number } = this.props;
    if (phone_number) {
      this.props.GetIndividualAIResponse(phone_number);
    }
  }

  componentDidUpdate(prevProps) {
    const { aiResponseGenerated, phone_number, resetIsAIResponseGenerated } =
      this.props;

    if (
      aiResponseGenerated &&
      aiResponseGenerated !== prevProps.aiResponseGenerated
    ) {
      this.props.GetIndividualAIResponse(phone_number);

      // Delay the reset to avoid immediate re-render loop
      setTimeout(() => {
        resetIsAIResponseGenerated();
        this.setState({ isGeneratingResponse: false });
      }, 100);
    }
  }

  handleToneChange = (event) => {
    this.setState({ tone: event.target.value });
  };

  handleLengthChange = (event) => {
    this.setState({ length: event.target.value });
  };

  handleContentBlur = (event) => {
    const { phone_number } = this.props;
    const editedResponse = event.target.textContent;

    if (!editedResponse.trim()) {
      alert("Response cannot be empty.");
      return;
    }

    this.props.EditAIResponse(phone_number, editedResponse);
    setTimeout(() => this.props.GetIndividualAIResponse(phone_number), 1000);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { phone_number } = this.props;
    const { tone, length } = this.state;

    if (!tone) {
      alert("Please select a response tone.");
      return;
    }

    if (!length) {
      alert("Please select a response length.");
      return;
    }

    // Call an action or API to generate the response
    this.props.GenerateAIResponse(phone_number, tone, length);
    this.setState({ isGeneratingResponse: true });
  };

  handleSend = () => {
    const { phone_number, fetchedIndividualAIResponse } = this.props;
    const response = fetchedIndividualAIResponse?.response;
    // const response = this.state.editedResponse;

    if (!response || !response.trim()) {
      alert("Cannot send an empty response.");
      return;
    }

    this.props.SendMessageAction(phone_number, response);
  };

  render() {
    const {
      backToMessageIntent,
      closeShowAIResponse,
      phone_number,
      message,
      handleMessageSentSuccess,
    } = this.props;
    const { tone, length } = this.state;

    console.log("This is the message taken from Overview:", message);

    const currentAIResponse =
      this.props.fetchedIndividualAIResponse?.response ||
      "No response, Please generate a response";

    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="flex flex-col gap-[2rem] bg-white w-[70%] rounded-[13px] pb-[2rem]">
          {/* Header */}
          <div className="flex justify-between px-[2rem] py-[1rem] bg-primary-blue rounded-t-[13px]">
            <div className="flex items-center gap-[1rem]">
              <button
                onClick={() => {
                  backToMessageIntent();
                  this.props.addMessageToRedux(message);
                }}
              >
                <img src={ArrowleftPNG} alt="arrow left" />
              </button>
              <img
                className="size-[3.5rem]"
                src={PlaceholderImage1}
                alt="Placeholder"
              />
              <div className="text-white font-SFPro font-[500] text-[1.25rem]">
                <div>{phone_number}</div>
                <div className="font-inter font-[400] text-[1rem]">
                  Customer since Jan
                </div>
              </div>
            </div>
            <button onClick={closeShowAIResponse}>
              <img src={XSVG} alt="close" />
            </button>
          </div>

          {/* Body */}
          <div className="flex flex-col px-[2rem] gap-[1.5rem]">
            {/* Current AI Response */}
            <div className="bg-gray-100 p-[1rem] rounded-[8px] border border-gray-300">
              <p className="text-[#333] font-[500] mb-[0.5rem]">
                Current AI Response:
              </p>
              <div
                contentEditable
                suppressContentEditableWarning
                onBlur={this.handleContentBlur}
                className="text-[#555] p-[0.5rem] border border-dashed border-gray-300 rounded-[8px] focus:outline-none focus:border-primary-blue"
              >
                {currentAIResponse}
              </div>
            </div>

            {/* Tone Selector */}
            <label className="flex flex-col">
              <span className="text-[#333] font-[500]">Tone</span>
              <select
                value={tone}
                onChange={this.handleToneChange}
                className="p-[0.5rem] rounded-[8px] border border-gray-300"
              >
                <option value="">-- Select Tone --</option>
                <option value="Friendly">Friendly</option>
                <option value="Professional">Professional</option>
                <option value="Neutral">Neutral</option>
              </select>
            </label>

            {/* Response Length Selector */}
            <label className="flex flex-col">
              <span className="text-[#333] font-[500]">Response Length</span>
              <select
                value={length}
                onChange={this.handleLengthChange}
                className="p-[0.5rem] rounded-[8px] border border-gray-300"
              >
                <option value="">-- Select Length --</option>
                <option value="Short">Short</option>
                <option value="Medium">Medium</option>
                <option value="Long">Long</option>
              </select>
            </label>

            {/* Buttons */}
            <div className="flex gap-[1rem]">
              <button
                onClick={this.handleSubmit}
                className="bg-primary-blue w-1/2 text-white py-[0.75rem] rounded-[8px] font-[500] hover:bg-primary-blue-light flex justify-center items-center gap-[0.5rem]"
              >
                <div>Generate Response</div>
                {this.state.isGeneratingResponse && <Spinner />}
              </button>
              <button
                onClick={() => {
                  this.handleSend();
                  handleMessageSentSuccess();
                }}
                className="hover:border-dashed border border-primary-blue bg-white w-1/2 text-primary-blue py-[0.75rem] rounded-[8px] font-[500]"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchedIndividualAIResponse: state.messaging.fetchedIndividualAIResponse,
  aiResponseGenerated: state.messaging.aiResponseGenerated,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    GetIndividualAIResponse,
    GenerateAIResponse,
    resetIsAIResponseGenerated,
    EditAIResponse,
    SendMessageAction,
    addMessageToRedux,
  })(CustomizeAIResponse)
);
