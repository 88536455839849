import React, { Component } from "react";
import NavBar from "./NavBar/NavBar";
import HeroSection from "./HeroSection/HeroSection";
import AboutUs from "./AboutUs/AboutUs";
import OurServices from "./OurServices/OurServices";
import Blog from "./Blog/Blog";
import Footer from "./Footer/Footer";
import Offerings from "./Offerings/Offerings";

class LandingPage extends Component {
  render() {
    return (
      <div className="w-full min-h-screen overflow-x-hidden">
        <NavBar />
        <HeroSection />
        <Offerings />
        <AboutUs />
        <OurServices />
        <Blog />
        <Footer />
      </div>
    );
  }
}

export default LandingPage;
