import React, { Component } from "react";
import UploadFiles from "./UploadFiles";
import RenderOtherFiles from "./RenderOtherFiles";
import FileUploader from "./FileUploader";

class FileManagerHome extends Component {
  state = {
    showFileUploader: false,
  };

  handleShowAndHideFileUploader = () => {
    this.setState((prevState) => ({
      showFileUploader: !prevState.showFileUploader,
    }));
  };

  render() {
    return (
      <div className="relative w-full h-full">
        {this.state.showFileUploader && (
          <FileUploader
            handleShowAndHideFileUploader={this.handleShowAndHideFileUploader}
          />
        )}
        <div className="flex gap-4 md:gap-[4rem] flex-col w-full p-4 md:py-[3rem] md:px-[3rem]">
          <UploadFiles
            handleShowAndHideFileUploader={this.handleShowAndHideFileUploader}
          />
          <RenderOtherFiles />
        </div>
      </div>
    );
  }
}

export default FileManagerHome;
