import React, { Component } from "react";
import { Link } from "react-router-dom";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import SignUpPageImage from "../../Assets/jpeg/LoginOverLay.jpg";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import { SignupAction } from "../../Actions/Authentication";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../Animations/Spinner/Spinner";
import ShowPasswordToggle from "./ShowPasswordToggle";

class SignUp extends Component {
  state = {
    first_name: "",
    last_name: "",
    middle_name: "",
    company_name: "",
    email: "",
    password: "",
    confirm_password: "",
    acceptTerms: false,
    signingup: false,
    showPassword: false,
  };

  static propTypes = {
    SignupAction: PropTypes.func.isRequired,
    isSignupSuccess: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      middle_name,
      company_name,
      email,
      password,
      confirm_password,
      acceptTerms,
    } = this.state;

    if (!acceptTerms) {
      alert("Please accept the terms and conditions.");
      return;
    }

    if (!email || !password || !confirm_password) {
      alert("Please fill in all required fields.");
      return;
    }

    this.setState({ signingup: true });

    setTimeout(() => {
      this.setState({ signingup: false });
    }, 3000);

    this.props.SignupAction(
      first_name,
      last_name,
      middle_name,
      company_name,
      email,
      password,
      confirm_password
    );
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  splitFullName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    let firstName = "";
    let middleName = "";
    let lastName = "";

    if (nameParts.length === 1) {
      firstName = nameParts[0];
    } else if (nameParts.length === 2) {
      firstName = nameParts[0];
      lastName = nameParts[1];
    } else {
      firstName = nameParts[0];
      middleName = nameParts.slice(1, -1).join(" ");
      lastName = nameParts[nameParts.length - 1];
    }

    this.setState({
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
    });
  };

  onCheckboxChange = (e) => {
    this.setState({ acceptTerms: e.target.checked });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.isSignupSuccess &&
      this.props.isSignupSuccess !== prevProps.isSignupSuccess
    ) {
      this.props.navigate("/verify-email");
    }
  }

  toggleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    const {
      full_name,
      company_name,
      email,
      password,
      confirm_password,
      showPassword,
    } = this.state;

    return (
      <div className="flex w-full min-h-screen justify-center items-center px-4 py-6 md:py-0 md:px-0">
        <div className="flex flex-col items-center w-full md:w-auto">
          <div className="flex flex-col md:flex-row w-full max-w-[1200px] shadow-lg md:shadow-none rounded-lg md:rounded-none overflow-hidden">
            {/* Form section */}
            <div className="flex items-center justify-center w-full md:w-[32.063rem] py-8 md:py-0 md:h-[55.188rem] bg-white">
              <div className="flex flex-col justify-center items-center w-full max-w-[400px] md:max-w-[27.313rem] gap-4 md:gap-[0.5rem] px-4 md:px-0">
                <div className="w-16 h-16 md:w-[4.438rem] md:h-[4.438rem]">
                  <img src={CESSVG} alt="CESSVG" className="w-full h-full" />
                </div>
                <div className="font-SFPro font-[700] text-xl md:text-[2.5rem] text-center">
                  Create an Account
                </div>
                <div className="text-center font-SFPro font-[400] text-sm md:text-[1.25rem] text-[#443F3F]">
                  Please input your details to create an account and enjoy our
                  services
                </div>
                <form
                  onSubmit={this.onSubmit}
                  className="flex flex-col gap-3 md:gap-[0.5rem] w-full"
                >
                  <div>
                    <div className="font-inter font-[600] text-sm md:text-base">
                      Full Name
                    </div>
                    <input
                      type="text"
                      name="full_name"
                      value={full_name}
                      onChange={(e) => this.splitFullName(e.target.value)}
                      placeholder="Enter your first, middle, and last name"
                      className="w-full md:w-[27.313rem] h-12 md:h-[3.75rem] border-[1px] border-primary-blue rounded-[0.625rem] mt-[0.4rem] pl-3 md:pl-[1.25rem] focus:outline-primary-blue bg-white"
                    />
                  </div>
                  <div>
                    <div className="font-inter font-[600] text-sm md:text-base">
                      Company Name
                    </div>
                    <input
                      type="text"
                      name="company_name"
                      value={company_name}
                      onChange={this.onChange}
                      placeholder="Enter your company's name"
                      className="w-full md:w-[27.313rem] h-12 md:h-[3.75rem] border-[1px] border-primary-blue rounded-[0.625rem] mt-[0.4rem] pl-3 md:pl-[1.25rem] focus:outline-primary-blue bg-white"
                    />
                  </div>
                  <div>
                    <div className="font-inter font-[600] text-sm md:text-base">
                      Email
                    </div>
                    <input
                      type="text"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      placeholder="Enter your email"
                      className="w-full md:w-[27.313rem] h-12 md:h-[3.75rem] border-[1px] border-primary-blue rounded-[0.625rem] mt-[0.4rem] pl-3 md:pl-[1.25rem] focus:outline-primary-blue bg-white"
                    />
                  </div>
                  <div className="relative">
                    <div className="font-inter font-[600] text-sm md:text-base">
                      Password
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={password}
                      onChange={this.onChange}
                      placeholder="Enter password"
                      autocomplete="new-password"
                      className="w-full md:w-[27.313rem] h-12 md:h-[3.75rem] border-[1px] border-primary-blue rounded-[0.625rem] mt-[0.4rem] pl-3 md:pl-[1.25rem] focus:outline-primary-blue bg-white"
                    />
                    <ShowPasswordToggle
                      showPassword={showPassword}
                      toggleShowPassword={this.toggleShowPassword}
                    />
                  </div>
                  <div>
                    <div className="font-inter font-[600] text-sm md:text-base">
                      Confirm Password
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="confirm_password"
                      value={confirm_password}
                      onChange={this.onChange}
                      placeholder="Confirm password"
                      className="w-full md:w-[27.313rem] h-12 md:h-[3.75rem] border-[1px] border-primary-blue rounded-[0.625rem] mt-[0.4rem] pl-3 md:pl-[1.25rem] focus:outline-primary-blue bg-white"
                    />
                  </div>
                  <div className="flex w-full h-auto md:h-[2.75rem] justify-center items-center py-2 md:py-0">
                    <div className="flex flex-wrap justify-center items-center gap-2 md:gap-[1rem] text-center">
                      <input
                        className="w-5 h-5 md:w-6 md:h-6 flex-shrink-0"
                        type="checkbox"
                        checked={this.state.acceptTerms}
                        onChange={this.onCheckboxChange}
                      />
                      <div className="font-inter font-[400] text-sm md:text-[1rem]">
                        Accept all{" "}
                        <Link className="text-primary-blue">terms</Link> and{" "}
                        <Link className="text-primary-blue">conditions</Link> of
                        this website
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-2 md:mt-0">
                    <button
                      type="submit"
                      className="flex justify-center items-center gap-[1rem] w-full h-12 md:h-[3.75rem] rounded-[2.438rem] bg-primary-blue text-white text-[600] font-inter text-base md:text-[1.5rem]"
                    >
                      <div>Sign Up</div> {this.state.signingup && <Spinner />}
                    </button>
                  </div>
                </form>
                <div className="mt-4 text-sm md:text-base text-center">
                  Already have an account?{" "}
                  <Link to={"/login"} className="text-primary-blue">
                    login
                  </Link>
                </div>
              </div>
            </div>

            {/* Image section - hidden on small screens */}
            <div className="hidden md:block">
              <img
                src={SignUpPageImage}
                alt="SignUpPageImage"
                className="w-[45.625rem] h-[55.188rem] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignupSuccess: state.authentication?.isSignupSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { SignupAction })(SignUp)
);
