import React, { Component } from "react";
import OfferingsJPG from "../../../Assets/jpeg/Offerings.jpg";

class Offerings extends Component {
  render() {
    return (
      <div className="flex w-full justify-center items-center px-4 md:px-8">
        <div className="w-full max-w-[1300px] overflow-hidden">
          <img
            className="w-full h-auto object-contain"
            src={OfferingsJPG}
            alt="Our Offerings"
          />
        </div>
      </div>
    );
  }
}

export default Offerings;
