import React, { Component } from "react";
import MessageSentSuccessSVG from "../../../../Assets/svg/MessageSentSuccess.svg";
import MessageSentFailedSVG from "../../../../Assets/svg/MessageSentFailed.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import GearsSpinner from "../../../Animations/GearsSpinner/GearsSpinner";
import { resetAllKnowledgeBaseBuilder } from "../../../../Actions/KnowledgeBaseBuilder";

class IngestStatus extends Component {
  static propTypes = {
    isIngesting: PropTypes.bool.isRequired,
    Ingested: PropTypes.bool,
  };

  renderMessageSentSuccess = () => {
    if (this.props.isIngesting === true && this.props.Ingested === null) {
      return (
        <div className="flex flex-col items-center h-full gap-[12rem] justify-center overflow-hidden">
          <div>
            <GearsSpinner />
          </div>
          <div className="font-[400] font-inter text-[2rem] text-[#475467]">
            The knowledge base is being updated please wait
          </div>
        </div>
      );
    } else if (this.props.Ingested === true) {
      return (
        <div className="flex flex-col items-center h-full gap-[6rem] justify-center overflow-hidden">
          <div className="size-[24rem]">
            <img className="size-full" src={MessageSentSuccessSVG} alt="Message Sent Failed" />
          </div>
          <div className="font-[400] font-inter text-[2rem] text-[#475467]">
            Knowledge base successfully updated
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="size-[12rem]">
            <img src={MessageSentSuccessSVG} alt="Awaiting Message" />
          </div>
          <div className="font-[700] font-SFPro text-[2.5rem]">
            Awaiting Message...
          </div>
          <div className="font-[400] font-inter text-[1.5rem] text-[#475467]">
            Sending Message to recipient
          </div>
        </>
      );
    }

    return null; // If messageSent is undefined, return nothing
  };

  render() {
    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-hidden">
        <div className="w-[80%] h-[70%] bg-white rounded-lg flex flex-col gap-[2rem] py-[4rem]">
          {this.renderMessageSentSuccess()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isIngesting: state.knowledgeBaseBuilder.isIngesting,
  Ingested: state.knowledgeBaseBuilder.Ingested,
});

export default connect(mapStateToProps, {resetAllKnowledgeBaseBuilder})(withRouterHooks(IngestStatus));
