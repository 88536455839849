import React, { Component } from "react";
import { connect } from "react-redux";
import {
  UploadCSVFileAction,
  TriggerFileUploadAction,
  SendCSVForAnalysisAction,
  AddAnalysisFileNamesToRedux,
  ResetAnalysisMessageAction,
  RenderOnSite,
  AddAnalysisFileIDToRedux,
} from "../../../Actions/Analysis";
import UploadSVG from "../../../Assets/svg/UploadSVG.svg";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import PropTypes from "prop-types";

class UploadCSVFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      error: null,
    };
    this.fileInputRef = React.createRef();
  }

  static propTypes = {
    UploadCSVFileAction: PropTypes.func.isRequired,
    TriggerFileUploadAction: PropTypes.func.isRequired,
    csvFileUploadSuccess: PropTypes.bool,
    csvFileUploadFail: PropTypes.bool,
    SendCSVForAnalysisAction: PropTypes.func.isRequired,
    AddAnalysisFileNamesToRedux: PropTypes.func.isRequired,
    ResetAnalysisMessageAction: PropTypes.func.isRequired,
    RenderOnSite: PropTypes.func.isRequired,
    AddAnalysisFileNamesToRedux: PropTypes.func.isRequired,
  };

  handlePath = () => {
    const { pathname } = this.props.location;
    return pathname === "/dashboard"
      ? "CSV"
      : pathname === "/dashboard/pdf-analyzer"
      ? "PDF"
      : "";
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 3 * 1024 * 1024) {
      this.setState({ error: "File size exceeds the 3MB limit.", file: null });
    } else if (file) {
      this.setState({ file, error: null });
      this.uploadFile(file);
    }
  };

  handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.size > 3 * 1024 * 1024) {
      this.setState({ error: "File size exceeds the 3MB limit.", file: null });
    } else if (file) {
      this.setState({ file, error: null });
      this.uploadFile(file);
    }
  };

  uploadFile = (file) => {
    const fileType = this.handlePath();
    if (!fileType) return;

    this.props.TriggerFileUploadAction();
    this.props.ResetAnalysisMessageAction();

    const uploadAction = () => {
      this.props.UploadCSVFileAction(file).then((res) => {
        const { id, file_name } = res.data || res;
        this.props.SendCSVForAnalysisAction(id);
        this.props.AddAnalysisFileIDToRedux(id);
        this.props.AddAnalysisFileNamesToRedux(file_name);

        // if (fileType === "PDF") {
        //   this.props.RenderOnSite();
        // }
      });
    };

    setTimeout(uploadAction, 3000);
  };

  handleChooseFileClick = () => {
    this.fileInputRef.current.click();
  };

  render() {
    const { file, error } = this.state;

    return (
      <div
        className="flex flex-col w-full h-auto md:h-[18.313rem] items-center justify-center font-inter text-base md:text-[1.5rem] leading-normal md:leading-[1.816rem] gap-4 md:gap-[2rem] border-[1px] border-primary-blue-light border-dashed bg-[#E7F1FF] p-4 md:p-6"
        onDragOver={(e) => e.preventDefault()}
        onDrop={this.handleDrop}
      >
        <img
          src={UploadSVG}
          alt="UploadSVG"
          className="w-12 h-12 md:w-[6.125rem] md:h-[6.125rem]"
        />
        <div className="flex flex-col gap-2 md:gap-[1rem] items-center text-center">
          <div>
            Drag & drop your file here or{" "}
            <button
              className="w-auto md:w-[10.375rem] h-fit p-[0.1rem] px-2 md:px-[0.5rem] py-1 md:py-[0.6rem] border-[1px] border-[#DDD9D9] bg-[#F9F9F9] text-sm md:text-base"
              onClick={this.handleChooseFileClick}
            >
              choose file
            </button>
            <input
              type="file"
              accept={
                this.handlePath() === "CSV"
                  ? ".csv,.xls,.xlsx"
                  : this.handlePath() === "PDF"
                  ? ".pdf"
                  : ""
              }
              ref={this.fileInputRef}
              onChange={this.handleFileChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="text-xs md:text-sm text-[#EC474A]">
            Maximum Size: 3MB
          </div>
          {file && (
            <div className="text-xs md:text-sm text-green-500">
              Selected file: {file.name}
            </div>
          )}
          {error && (
            <div className="text-xs md:text-sm text-red-500">{error}</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  csvFileUploadSuccess: state.analysis?.csvFileUploadSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    UploadCSVFileAction,
    TriggerFileUploadAction,
    SendCSVForAnalysisAction,
    AddAnalysisFileNamesToRedux,
    ResetAnalysisMessageAction,
    RenderOnSite,
    AddAnalysisFileIDToRedux,
  })(UploadCSVFile)
);
