import React, { Component } from "react";
import EmailChatList from "./EmailChatList";
import EmailChatStarted from "./EmailChatStarted";
import EmailChatStarted1 from "./EmailChatStarted1";
import EmailChatStarted2 from "./EmailChatStarted2";
import EmailChatStarted3 from "./EmailChatStarted3";

class Email extends Component {
  state = {
    selectedChat: 1, // Default chat
  };

  handleChatSelect = (chatId) => {
    this.setState({ selectedChat: chatId });
  };

  renderChatContent = () => {
    const { selectedChat } = this.state;

    switch (selectedChat) {
      case 1:
        return <EmailChatStarted />;
      case 2:
        return <EmailChatStarted1 />;
      case 3:
        return <EmailChatStarted2 />;
      case 4:
        return <EmailChatStarted3 />;
      default:
        return <EmailChatStarted />;
    }
  };

  render() {
    return (
      <div className="w-full h-screen bg-[#F9F9F9] p-[1rem]">
        <div className="flex bg-white w-full h-full gap-[1rem]">
          <EmailChatList onSelectChat={this.handleChatSelect} />
          {this.renderChatContent()}
        </div>
      </div>
    );
  }
}

export default Email;
