import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import ChangePasswordImage from "../../Assets/jpeg/OverLay.jpg";
import ArrowLeftSVG from "../../Assets/svg/ArrowLeftSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import { AddForgotPasswordToOTPAction } from "../../Actions/Authentication";

class VerifyConfirmPassword extends Component {
  state = {
    otp: "",
  };

  static propTypes = {
    AddForgotPasswordToOTPAction: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    ForgotPasswordOtp: PropTypes.string,
    forgotPasswordEmail: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.inputsRef = Array(4) // Create an array of refs for the inputs
      .fill(0)
      .map(() => createRef());
  }

  // Handle form submission
  onSubmit = (e) => {
    e.preventDefault();
    const { otp } = this.state;

    if (!otp || otp.length < 4) {
      alert("Please enter a 4-digit code.");
      return;
    }

    // Call the VerifyEmailAction with email and OTP
    this.props.AddForgotPasswordToOTPAction(otp);
  };

  // Handle OTP input change and update the state
  handleInput = (e, index) => {
    const { value } = e.target;

    if (value && index < this.inputsRef.length - 1) {
      this.inputsRef[index + 1].current.focus();
    }

    // Update the OTP state with the value entered
    let newOtp = this.state.otp.split("");
    newOtp[index] = value;
    this.setState({ otp: newOtp.join("") });
  };

  handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !e.target.value) {
      // If Backspace is pressed on an empty field, move to the previous input
      this.inputsRef[index - 1].current.focus();
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.ForgotPasswordOtp &&
      this.props.ForgotPasswordOtp !== prevProps.ForgotPasswordOtp
    ) {
      this.props.navigate("/confirmforgotpassword");
    }
  }

  render() {
    return (
      <div className="flex w-full min-h-screen justify-center items-center px-4 py-6 md:py-0 md:px-0">
        <div className="flex flex-col items-center w-full md:w-auto">
          <div className="flex flex-col md:flex-row w-full max-w-[1200px] shadow-lg md:shadow-none rounded-lg md:rounded-none overflow-hidden">
            {/* Form section */}
            <div className="flex items-start pt-4 md:pt-[1rem] justify-center w-full md:w-[32.063rem] h-auto md:h-[55.188rem] bg-white px-4 md:px-0">
              <div className="flex flex-col justify-center items-center w-full max-w-[400px] md:max-w-[27.313rem] gap-6 md:gap-[3rem]">
                <div className="flex flex-col gap-6 md:gap-[6rem] w-full">
                  <div className="w-16 h-8 md:w-[4.438rem] md:h-[1.925rem]">
                    <img src={CESSVG} alt="CESSVG" className="w-full h-full" />
                  </div>
                  <div className="flex flex-col">
                    <div className="font-SFPro font-[700] text-xl md:text-[2.5rem] relative right-0">
                      Password Reset
                    </div>
                    <div className="font-SFPro font-[400] text-sm md:text-[1.25rem] text-[#443F3F]">
                      We sent a code to{" "}
                      <span className="font-[600] text-xs md:text-[1.1rem]">
                        {this.props.forgotPasswordEmail}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4 md:gap-[1rem] w-full">
                  <div className="flex justify-center space-x-2 md:space-x-6">
                    {this.inputsRef.map((ref, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        ref={ref}
                        onChange={(e) => this.handleInput(e, index)}
                        onKeyDown={(e) => this.handleKeyDown(e, index)}
                        className="w-12 h-12 md:w-[4.063rem] md:h-[4.063rem] border-2 border-gray-300 rounded-md text-center text-lg md:text-xl focus:outline-none focus:border-[#8E3A90] focus:ring-2 focus:ring-[#8E3A90] transition"
                      />
                    ))}
                  </div>
                </div>
                <div className="w-full">
                  <button
                    onClick={this.onSubmit}
                    className="w-full h-12 md:h-[3.75rem] bg-[#7EC14D] text-white text-[600] font-inter text-base md:text-[1.5rem]"
                  >
                    Continue
                  </button>
                </div>
                <div className="font-inter font-[400] text-sm md:text-[1.125rem] text-center">
                  Didn't receive the email?{" "}
                  <span className="font-[600] cursor-pointer">
                    click to resend
                  </span>
                </div>
                <Link
                  to={"/login"}
                  className="flex gap-2 md:gap-[1rem] items-center"
                >
                  <div className="w-6 h-6 md:w-[2rem]">
                    <img
                      src={ArrowLeftSVG}
                      alt="arrow left svg"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="text-[#443F3F] text-sm md:text-[1.125rem] font-inter font-[400]">
                    Back to login
                  </div>
                </Link>
              </div>
            </div>

            {/* Image section - hidden on small screens */}
            <div className="hidden md:block">
              <img
                src={ChangePasswordImage}
                alt="Password Reset Image"
                className="w-[45.625rem] h-[55.188rem] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ForgotPasswordOtp: state.authentication?.ForgotPasswordOtp,
  forgotPasswordEmail: state.authentication?.forgotPasswordEmail,
});

export default withRouterHooks(
  connect(mapStateToProps, { AddForgotPasswordToOTPAction })(
    VerifyConfirmPassword
  )
);
