import React, { Component } from "react";
import CESNewLogoSVG from "../../../Assets/svg/CESSVG.svg";
import { Link } from "react-router-dom";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
    };
  }

  toggleMobileMenu = () => {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  };

  render() {
    const { mobileMenuOpen } = this.state;

    return (
      <div className="flex justify-center items-center w-full py-4 px-4 md:px-6 lg:px-8 md:h-[10rem]">
        <div className="flex h-auto md:h-[6rem] justify-between items-center w-full max-w-[1300px] rounded-lg md:rounded-[5rem] border border-[#CDCDCD] shadow-custom-2 px-4 py-3 md:px-[3rem]">
          <div>
            <img
              className="w-[50px] h-[50px] md:w-[70px] md:h-[70px]"
              src={CESNewLogoSVG}
              alt="CES Logo"
            />
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex gap-4 lg:gap-[3.063rem] font-inter font-[600] text-[#504A4A] text-base lg:text-[1.5rem]">
            <h1 className="text-primary-blue">Home</h1>
            <h1>About us</h1>
            <h1>Services</h1>
            <h1>Blog</h1>
          </div>

          <div className="hidden md:flex gap-4 lg:gap-[3.063rem] font-inter font-[600] text-[#504A4A] text-base lg:text-[1.5rem]">
            <Link to="/signup">
              <button className="px-3 md:px-[2rem] py-2 md:py-[0.75rem] bg-primary-blue text-[#F9F9F9] rounded-[2.5rem] text-sm md:text-base">
                Signup
              </button>
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-primary-blue"
            onClick={this.toggleMobileMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={
                  mobileMenuOpen
                    ? "M6 18L18 6M6 6l12 12"
                    : "M4 6h16M4 12h16M4 18h16"
                }
              />
            </svg>
          </button>
        </div>

        {/* Mobile Menu Dropdown */}
        {mobileMenuOpen && (
          <div className="md:hidden absolute top-[75px] left-0 right-0 bg-white z-50 shadow-lg p-4 mx-4 rounded-lg border border-[#CDCDCD]">
            <div className="flex flex-col gap-4 font-inter font-[600] text-[#504A4A] text-lg">
              <h1 className="text-primary-blue">Home</h1>
              <h1>About us</h1>
              <h1>Services</h1>
              <h1>Blog</h1>
              <Link to="/signup">
                <button className="w-full px-4 py-2 bg-primary-blue text-[#F9F9F9] rounded-[2.5rem]">
                  Signup
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NavBar;
