import React, { Component } from "react";
import { connect } from "react-redux";
import CancelSVG from "../../../../Assets/svg/CancelButtonSVG.svg";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import { SaveContactName } from "../../../../Actions/Messaging";


class SaveContact extends Component {
  state = {
    name: "",
    isSaving: false,
  };

  static propTypes = {
    SaveContactName: PropTypes.func.isRequired,
    handleShowSaveContact: PropTypes.func.isRequired,
    location: PropTypes.object,
  };

  onchange = (e) => {
    this.setState({ name: e.target.value });
  };

  getSessionIdFromPath = () => {
    const { location } = this.props;
    if (!location || !location.pathname) return null;
    return location.pathname.split("/").pop().replace(/\+/g, "");
  };

  handleSaveContact = async () => {
    const sessionId = this.getSessionIdFromPath();
    if (!this.state.name.trim()) {
      alert("Please enter a name before saving.");
      return;
    }

    this.setState({ isSaving: true });

    try {
      await this.props.SaveContactName(this.state.name, sessionId);
      console.log("Contact saved successfully");

      this.setState({ name: "", isSaving: false });

      this.props.handleShowSaveContact();
    } catch (error) {
      console.error("Error saving contact:", error);
      this.setState({ isSaving: false });
    }
  };

  render() {
    const { handleShowSaveContact } = this.props;
    const { name, isSaving } = this.state;

    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="bg-white w-[70%] h-[40%] rounded-lg flex flex-col justify-center gap-[2rem] px-[4rem]">
          <div className="font-[700] font-SFPro text-[1.5rem] flex w-full justify-between">
            <div>Create New or Edit Contact</div>
            <button onClick={handleShowSaveContact}>
              <img src={CancelSVG} alt="Cancel" />
            </button>
          </div>
          <input
            type="text"
            name="name"
            value={name}
            onChange={this.onchange}
            placeholder="Enter Name"
            className="w-full h-[2.5rem] border border-[#D9D9D9] rounded-[9px] px-[1rem] py-[2rem] focus:outline-none focus:border-[#0D6EFD] font-inter font-[500] text-[1.25rem]"
          />
          <button
            onClick={this.handleSaveContact}
            disabled={isSaving}
            className={`flex justify-center text-white ${
              isSaving ? "bg-gray-400" : "bg-[#0D6EFD]"
            } py-[1.5rem] rounded-[4px] font-inter font-[500] text-[1.25rem]`}
          >
            {isSaving ? "Saving..." : "Save Contact"}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouterHooks(
  connect(mapStateToProps, { SaveContactName })(SaveContact)
);
