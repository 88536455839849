import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";

class Options extends Component {
  static propTypes = {};
  render() {
    const { handleShowSaveContact } = this.props;
    return (
      <div className="absolute flex flex-col w-[17rem] z-50 right-[3rem] top-[4rem] shadow-custom-2">
        <div
          onClick={handleShowSaveContact}
          className="px-[1rem] py-[1rem] text-center bg-white text-black hover:bg-[#EEEDED] font-inter font-[600] text-[1.5rem] cursor-pointer"
        >
          Save Contact
        </div>
        {/* <div className="px-[1rem] py-[1rem] text-center bg-white hover:bg-[#EEEDED] font-inter font-[400] text-[1.5rem] cursor-pointer">
          More information
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withRouterHooks(Options));
