import axios from "axios";
import { getCookie } from "./Csrf";
import store from "../Store/Store";

// Base axios instance for regular requests
const instance = axios.create({
  baseURL: "https://creditregistry.cx2.ai",
  withCredentials: true,
});

// Create a separate instance for token refresh to avoid interceptor loops
export const refreshAxios = axios.create({
  baseURL: "https://creditregistry.cx2.ai",
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const token = getCookie("csrftoken");
    if (token) {
      config.headers["X-CSRFToken"] = token;
    }
    const accessToken = localStorage.getItem("access");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Standalone refresh token function to avoid circular imports
const refreshTokenFn = async () => {
  const refresh = localStorage.getItem("refresh");

  if (!refresh) {
    console.error("No refresh token available.");
    return Promise.reject(new Error("No refresh token available"));
  }

  try {
    // Use refreshAxios instead of refreshInstance
    const res = await refreshAxios.post("/api/userauth/token/refresh/", {
      refresh,
    });

    const newAccessToken = res.data.access;
    const newRefreshToken = res.data.refresh;

    console.log("Tokens refreshed successfully");

    // Store the new tokens
    localStorage.setItem("access", newAccessToken);
    localStorage.setItem("refresh", newRefreshToken);

    return { newAccessToken, newRefreshToken };
  } catch (err) {
    console.error(
      "Token refresh failed:",
      err.response ? err.response.data : err.message
    );

    // Clear tokens if refresh fails due to an invalid refresh token
    if (err.response && err.response.status === 401) {
      console.log("Refresh token is invalid or expired.");
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");

      // Import dynamically to avoid circular dependency
      const AuthModule = require("./Authentication");
      if (AuthModule.LogoutAction) {
        store.dispatch(AuthModule.LogoutAction());
      }
    }

    return Promise.reject(err);
  }
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (originalRequest._retry) {
      return Promise.reject(error);
    }

    // Only handle 401 errors that indicate token issues
    if (error.response && error.response.status === 401) {
      // Check if the request was already retried (avoid infinite loops)
      if (!originalRequest._retry) {
        console.log("Received 401, attempting to refresh token...");

        // If we're already refreshing, queue this request
        if (isRefreshing) {
          console.log("Already refreshing, adding request to queue");
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              console.log("Queue processed, retrying with new token");
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              return instance(originalRequest);
            })
            .catch((err) => {
              console.error("Queue processing failed:", err);
              return Promise.reject(err);
            });
        }

        // Set the flag to indicate we're refreshing
        originalRequest._retry = true;
        isRefreshing = true;

        try {
          console.log("Starting token refresh process...");
          // Use our local refreshTokenFn instead of the imported one
          const refreshResult = await refreshTokenFn();

          if (!refreshResult || !refreshResult.newAccessToken) {
            throw new Error("Failed to refresh token");
          }

          const newToken = refreshResult.newAccessToken;

          // Update token in current request
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

          // Process any queued requests with the new token
          processQueue(null, newToken);

          console.log(
            "Token refreshed successfully, retrying original request"
          );
          isRefreshing = false;

          // Retry original request
          return instance(originalRequest);
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);

          // Process failed queue
          processQueue(refreshError, null);

          // Reset flag
          isRefreshing = false;

          // Don't call LogoutAction directly to avoid circular dependency
          // Authentication.js will handle logout when refresh token fails

          return Promise.reject(refreshError);
        }
      }
    }

    // For all other errors, just reject
    return Promise.reject(error);
  }
);

export default instance;
