import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="flex flex-col gap-4 md:gap-[2rem] justify-center items-center w-full h-fit px-4 md:px-[2rem] py-6 md:py-[3rem] bg-black text-white">
        <div className="flex gap-4 md:gap-[2rem] items-center flex-col w-full max-w-[1300px] font-inter">
          <div className="flex w-full justify-center md:justify-start">
            <img src="" alt="CES logo" />
          </div>
          <div className="flex flex-col md:flex-row justify-between w-full gap-6 md:gap-0">
            <div className="font-[700] text-lg md:text-[1.5rem] text-center md:text-left">
              Customer Experience Solutions
            </div>
            <div className="flex flex-wrap justify-center md:justify-between gap-4 md:gap-[2rem] font-[500] text-base md:text-[1.25rem]">
              <div className="mx-2 md:mx-0">Home</div>
              <div className="mx-2 md:mx-0">About Us</div>
              <div className="mx-2 md:mx-0">Services</div>
              <div className="mx-2 md:mx-0">Blog</div>
            </div>
            <div className="flex gap-[2rem] justify-center md:justify-end">
              <img src="" alt="LinkedIn" />
              <img src="" alt="Facebook" />
              <img src="" alt="Twitter" />
            </div>
          </div>
          <div className="font-[500] text-sm md:text-[1.25rem] text-center md:text-left">
            © 2025 CES All Rights Reserved
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
