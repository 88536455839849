import React, { Component } from "react";
import UploadCSVFile from "./UploadCSVFile";
import UploadingCSVFile from "./UploadingCSVFile";
import UploadCSVComplete from "./UploadCSVComplete";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import { ResetAnalysisReduxStateAction } from "../../../Actions/Analysis";

class CSVFileUploader extends Component {
  static propTypes = {
    ResetAnalysisReduxStateAction: PropTypes.func.isRequired,
    isFileUploading: PropTypes.bool,
    csvFileUploadSuccess: PropTypes.bool,
    csvAnalysisSuccess: PropTypes.bool,
  };

  componentDidUpdate(prevProps) {
    if (this.props.csvFileUploadSuccess !== prevProps.csvFileUploadSuccess) {
      if (this.props.csvFileUploadSuccess) {
        // setTimeout(() => {
        //   this.props.ResetAnalysisReduxStateAction();
        // }, 2000);
      }
    }
  }

  handlePath = () => {
    let text = "";
    if (this.props.location.pathname === "/dashboard") {
      text = "Upload CSV File";
    } else if (this.props.location.pathname === "/dashboard/pdf-analyzer") {
      text = "Upload PDF File";
    }

    return text;
  };

  render() {
    return (
      <div className="flex flex-col w-full h-auto md:h-[23.063rem] justify-between">
        <div className="flex w-full md:w-[18.75rem] h-fit font-SFPro font-[700] text-xl md:text-[2rem] mb-4 md:mb-0">
          {this.handlePath()}
        </div>
        {this.props.csvFileUploadSuccess && !this.props.csvAnalysisSuccess && (
          <UploadCSVComplete />
        )}
        {this.props.isFileUploading && !this.props.csvFileUploadSuccess && (
          <UploadingCSVFile />
        )}
        {!this.props.isFileUploading && !this.props.csvFileUploadSuccess && (
          <UploadCSVFile />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFileUploading: state.analysis.isFileUploading,
  csvFileUploadSuccess: state.analysis.csvFileUploadSuccess,
  csvAnalysisSuccess: state.analysis.csvAnalysisSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { ResetAnalysisReduxStateAction })(CSVFileUploader)
);
