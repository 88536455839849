import React, { Component } from "react";
import EmailRPlaceHolder from "../../../../Assets/svg/EmailRPlaceHolder.svg";
import ChatInput from "../ChatInput";

class EmailChatStarted extends Component {
  render() {
    return (
      <div className="relative w-full h-full border borrder-[#D9D9D9] rounded-[9px]">
        <div className="w-full h-[6.875rem] bg-[#EBEAEA] px-[1rem] flex items-center gap-[1rem] rounded-t-[9px]">
          <img src={EmailRPlaceHolder} alt="Search Icon" />
          <div>
            <div>
              <div className="font-[700] font-SFPro text-[1.5rem]">
                Felix Oghene
              </div>
              <div className="font-inter font-[500] text-[0.875rem]">
                Tap profile for more info
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pt-[2rem] flex flex-col gap-[1rem]">
          <div className=" text-center font-inter font-[400] text-[1.125rem] text-[#302D2D]">
            All messages in this system are{" "}
            <span className="text-primary-blue">Secured</span>
          </div>

          <div className="w-full flex justify-center ">
            <div className="bg-[#F6F6F6] w-fit py-[0.625rem] px-[1rem] border border-[#D1D4DA]">
              Today
            </div>
          </div>
          <div>
            <div>
              <div className="flex flex-col gap-4 p-4  h-full">
                <div className="flex">
                  <div class="relative max-w-[70%] p-3 bg-white text-gray-900 rounded-lg shadow border border-[#D9D9D9]">
                    Dear Customer Support, <br /> I recently encountered an
                    issue with my bank account. My concern is regarding
                    <strong>account closure request</strong>. I noticed a
                    problem on <strong>February</strong> 6, 2025, and I would
                    like assistance in resolving this matter. Could you please
                    investigate and provide guidance on how to proceed? I would
                    also appreciate any recommendations on securing my account
                    or avoiding such issues in the future. Thank you for your
                    assistance.
                    <br /> <br />
                    Best regards, <br /> Felix Oghene
                    <div class="absolute top-2 left-[-8px] h-4 w-4 rotate-45 bg-white border-[#D9D9D9] border-l border-b"></div>
                  </div>
                </div>

                <div className="flex justify-end">
                  <div class="relative max-w-[70%] p-3 bg-primary-blue text-white rounded-lg shadow">
                    Dear Felix Oghene, <br />
                    Thank you for reaching out to <strong>ABC Bank</strong>{" "}
                    Customer Support. We understand your concern regarding
                    <strong>account closure request</strong> and we are here to
                    assist you. Here’s what we will do: <br />
                    1. <strong>Investigation</strong>: Our team is currently
                    reviewing the details of your complaint. <br /> 2.{" "}
                    <strong>Action Plan</strong>: If we identify any issue, we
                    will take the necessary steps to resolve it. <br />
                    3. <strong>Update</strong>: You will receive an update
                    within **7-10 business days** on the resolution status.{" "}
                    <br /> For future concerns, we recommend: <br /> ✔ Keeping
                    your contact details updated. <br />✔ Enabling transaction
                    alerts for account monitoring. <br />✔ Using secure banking
                    channels to report issues promptly. <br />
                    Thank you for banking with us. Let us know if you need
                    further assistance. <br />
                    Best regards, <br />
                    Emily Roberts <br />
                    Customer Support Representative <br />
                    ABC Bank
                    <div class="absolute top-2 right-[-8px] h-4 w-4 rotate-45 bg-primary-blue"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChatInput />
      </div>
    );
  }
}

export default EmailChatStarted;
