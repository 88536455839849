import React from "react";
import hidePasswordSVG from "../../Assets/png/hide.png";
import showPasswordSVG from "../../Assets/png/show.png";

const ShowPasswordToggle = ({ showPassword, toggleShowPassword }) => (
  <button
    type="button"
    onClick={toggleShowPassword}
    className="absolute top-[2.3rem] md:top-[2.7rem] right-2 md:right-[1rem]"
    aria-label={showPassword ? "Hide Password" : "Show Password"}
  >
    <img
      className="w-5 h-5 md:w-[2rem] md:h-[2rem]"
      src={showPassword ? showPasswordSVG : hidePasswordSVG}
      alt={showPassword ? "Hide Password" : "Show Password"}
    />
  </button>
);

export default ShowPasswordToggle;
