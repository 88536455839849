import React, { Component } from "react";
import FIleProcessedSuccessSVG from "../../../Assets/svg/FIleProcessedSuccessSVG.svg";

class FileProcessedSuccess extends Component {
  static propTypes = {};
  render() {
    return (
      <div className="flex fixed left-[62rem] top-[2.563rem] w-[25.438rem] h-[4.438rem] justify-center items-center bg-white border border-primary-blue shadow-custom-shadow rounded-[0.5rem]">
        <div className="flex gap-[0.625rem] w-fit h-fit items-center">
          <img src={FIleProcessedSuccessSVG} alt="File Processed Success" />
          <div className="font-[700] text-[1rem] font-SFPro">
            Your file has been successfully Processed!
          </div>
        </div>
      </div>
    );
  }
}

export default FileProcessedSuccess;
