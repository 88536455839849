import React, { Component } from "react";
import ArrowLeftSVG from "../../../../Assets/svg/ArrowLeftSVG.svg";
import PaperPlaneRightSVG from "../../../../Assets/svg/PaperPlaneRightSVG.svg";
import FilterIconSVG from "../../../../Assets/svg/FilterIconSVG.svg";
import MagnifyingGlassSVG from "../../../../Assets/svg/MagnifyingGlassSVG.svg";
import DotsThreeSVG from "../../../../Assets/svg/DotsThreeSVG.svg";
import ShowMessageIntent from "./ShowMessageIntent";
import { Link } from "react-router-dom";
import CustomizeAndMoreInformation from "./CustomizeAndMoreInformation";
import MessageHistory from "./MessageHistory";
import CustomizeAIResponse from "./CustomizeAIResponse";
import MessageSentSuccess from "./MessageSentSuccess";
import MessageBroadCastStatus from "./MessageBroadCastStatus";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FetchAllMessagesForOverview,
  addMessageToRedux,
  resetSendMessageRedux,
  QueueAIResponse,
  BroadCastQueuedMessages,
  resetBroadCastMessagesStatus,
} from "../../../../Actions/Messaging";

class Messaging extends Component {
  state = {
    activeCustomizeIndex: null,
    isShowMessageIntentActive: false,
    isShowMessageHistory: false,
    isShowAIResponse: false,
    phone_number: "",
    message: "",
    messageSentSuccess: false,
    showBroadcastMessageStatus: false,
    searchTerm: "",
    isSearchFocused: false,
  };

  static propTypes = {
    FetchAllMessagesForOverview: PropTypes.func.isRequired,
    addMessageToRedux: PropTypes.func.isRequired,
    resetSendMessageRedux: PropTypes.func.isRequired,
    QueueAIResponse: PropTypes.func.isRequired,
    BroadCastQueuedMessages: PropTypes.func.isRequired,
    resetBroadCastMessagesStatus: PropTypes.func.isRequired,
    allOverviewChatSessions: PropTypes.array,
    allOverviewChatSessionsFetchSuccess: PropTypes.bool,
    messageSent: PropTypes.bool,
    messageSuccessfullybroadcasted: PropTypes.bool,
  };

  // Create a ref for the search input
  searchInputRef = React.createRef();

  componentDidMount() {
    this.props.FetchAllMessagesForOverview();
    console.log("Component Did Mount", this.props.allOverviewChatSessions);

    // Mount keyboard shortcut listener
    document.addEventListener("keydown", this.handleGlobalKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.messageSent &&
      this.props.messageSent !== prevProps.messageSent
    ) {
      this.handleMessageSentSuccess();
    }
    // if (
    //   this.props.messageSuccessfullybroadcasted &&
    //   this.props.messageSuccessfullybroadcasted !==
    //     prevProps.messageSuccessfullybroadcasted
    // ) {
    //   this.setState({ showBroadcastMessageStatus: false });
    // }
  }

  componentWillUnmount() {
    // Remove keyboard shortcut listener
    document.removeEventListener("keydown", this.handleGlobalKeyDown);
  }

  handleGlobalKeyDown = (e) => {
    // Ctrl/Cmd + F to focus search
    if ((e.ctrlKey || e.metaKey) && e.key === "f") {
      e.preventDefault();
      this.focusSearch();
    }
  };

  handleCustomize = (index) => {
    // Toggle dropdown for the selected item
    this.setState((prevState) => ({
      activeCustomizeIndex:
        prevState.activeCustomizeIndex === index ? null : index,
    }));
  };

  closeCustomize = () => {
    this.setState({ activeCustomizeIndex: null });
  };

  showMessageIntent = (number) => {
    this.setState({ isShowMessageIntentActive: true });
    this.setState({ activeCustomizeIndex: null });
    this.setState({ phone_number: number });
  };

  closeShowMessageIntent = () => {
    this.setState({ isShowMessageIntentActive: false });
    this.setState({ isShowMessageHistory: false });
  };

  showMessageHistory = () => {
    this.setState({ isShowMessageHistory: true });
    this.setState({ isShowMessageIntentActive: false });
  };

  closeShowMessageHistory = () => {
    this.setState({ isShowMessageHistory: false });
  };

  backToMessageIntent = (message) => {
    this.setState({
      isShowMessageHistory: false,
      isShowMessageIntentActive: true,
      isShowAIResponse: false,
      ...(message && { message: message }),
    });
  };

  showAIResponse = (number, message) => {
    this.setState({
      phone_number: number,
      isShowAIResponse: true,
      isShowMessageIntentActive: false,
      ...(message && { message: message }),
    });
  };

  addPhoneNumberToState = (phone_number) => {
    this.setState({ phone_number });
  };

  closeShowAIResponse = () => {
    this.setState({ isShowAIResponse: false });
  };

  navigateToMessage = (number) => {
    window.location.assign(`/#/dashboard/message-overview/whatsapp/${number}`);
  };

  handleMessageTruncate = (message) => {
    const maxLength = 20;

    // Guard against null/undefined
    if (!message) return "";

    // If the message is longer than the max, truncate and add '...'
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + "...";
    }

    // Otherwise, return as is
    return message;
  };

  addMessageToState = (message) => {
    this.props.addMessageToRedux(message);
  };

  handleMessageSentSuccess = () => {
    this.setState({ messageSentSuccess: true });

    setTimeout(() => {
      this.setState({ messageSentSuccess: false });
      this.props.resetSendMessageRedux();
    }, 1500);
  };

  handleQueueBroadcast = (phone_number) => {
    this.props.QueueAIResponse(phone_number);
    setTimeout(() => {
      this.props.FetchAllMessagesForOverview();
    }, 1000);
  };

  handleShowBroadcastMessageStatus = () => {
    if (
      this.props.allOverviewChatSessions.some(
        (item) => item.queued_for_broadcast
      )
    ) {
      this.props.BroadCastQueuedMessages();

      this.setState({ showBroadcastMessageStatus: true });

      setTimeout(() => {
        this.setState({ showBroadcastMessageStatus: false });
      }, 3000);

      setTimeout(() => {
        this.props.resetBroadCastMessagesStatus();
        this.props.FetchAllMessagesForOverview();
      }, 4000);
    } else {
      alert("please queue a message or messages to broadcast");
    }
  };

  // Handle search input changes
  handleSearchInputChange = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  // Handle search input focus
  handleSearchFocus = () => {
    this.setState({ isSearchFocused: true });
  };

  // Handle search input blur
  handleSearchBlur = () => {
    this.setState({ isSearchFocused: false });
  };

  // Handle keyboard events for search
  handleSearchKeyDown = (e) => {
    // Press Escape to clear search
    if (e.key === "Escape") {
      this.handleClearSearch();
    }

    // Press Enter to focus on first result (if any)
    if (e.key === "Enter" && this.getFilteredMessages().length > 0) {
      // Could add functionality to select first result
      e.preventDefault();
    }
  };

  // Focus the search input
  focusSearch = () => {
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
  };

  // Filter messages based on search term
  getFilteredMessages = () => {
    const { searchTerm } = this.state;
    const messages = this.props.allOverviewChatSessions || [];

    if (!searchTerm.trim()) {
      return messages; // Return all messages if search term is empty
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return messages.filter((item) => {
      // Search in customer name/phone number
      const customerMatch = (item.user_name || item.phone_number || "")
        .toLowerCase()
        .includes(lowerCaseSearchTerm);

      // Search in message content
      const messageMatch = (item.unread_message?.body || "")
        .toLowerCase()
        .includes(lowerCaseSearchTerm);

      // Search in AI response
      const aiResponseMatch = (item.ai_response || "")
        .toLowerCase()
        .includes(lowerCaseSearchTerm);

      // Return true if any of the fields match
      return customerMatch || messageMatch || aiResponseMatch;
    });
  };

  // Clear search input
  handleClearSearch = () => {
    this.setState({ searchTerm: "" });
  };

  render() {
    // Use filtered messages instead of directly accessing props
    const filteredMessages = this.getFilteredMessages();
    const { searchTerm, isSearchFocused } = this.state;

    return (
      <div className="relative w-full h-screen bg-[#F9F9F9] p-[0.5rem] sm:p-[1rem]">
        {this.state.isShowMessageIntentActive && (
          <ShowMessageIntent
            closeShowMessageIntent={this.closeShowMessageIntent}
            showMessageHistory={this.showMessageHistory}
            showAIResponse={this.showAIResponse}
            message={this.state.message}
            phone_number={this.state.phone_number}
          />
        )}
        {this.state.isShowMessageHistory && (
          <MessageHistory
            closeShowMessageHistory={this.closeShowMessageHistory}
            closeShowMessageIntent={this.closeShowMessageIntent}
            backToMessageIntent={this.backToMessageIntent}
            phone_number={this.state.phone_number}
          />
        )}
        {this.state.isShowAIResponse && (
          <CustomizeAIResponse
            closeShowAIResponse={this.closeShowAIResponse}
            backToMessageIntent={this.backToMessageIntent}
            phone_number={this.state.phone_number}
            message={this.state.message}
            handleMessageSentSuccess={this.handleMessageSentSuccess}
          />
        )}
        {this.state.showBroadcastMessageStatus && <MessageBroadCastStatus />}
        {this.state.messageSentSuccess && <MessageSentSuccess />}
        <div className="w-full h-full bg-[#FFFFFF] px-[0.75rem] sm:px-[1rem] md:px-[2rem] py-[1rem] sm:py-[1.5rem] md:py-[3rem] rounded-[9px] overflow-y-auto">
          <div className="w-full h-full flex flex-col gap-[0.75rem] sm:gap-[1rem] md:gap-[2rem]">
            {/* Header */}
            <div className="w-full flex flex-col sm:flex-row justify-between gap-3 sm:gap-4 items-center">
              <Link
                to={"/dashboard"}
                className="flex items-center hover:opacity-80 transition-opacity"
              >
                <div className="flex gap-[0.5rem] items-center text-[1.25rem] sm:text-[1.5rem] md:text-[2.5rem] font-SFPro font-[700]">
                  <img
                    src={ArrowLeftSVG}
                    alt="Back"
                    className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
                  />
                  <div>All Messages</div>
                </div>
              </Link>
              <button
                onClick={this.handleShowBroadcastMessageStatus}
                className="w-full sm:w-auto flex items-center justify-center gap-[0.5rem] px-[1rem] md:px-[2rem] py-[0.5rem] md:py-[0.75rem] rounded-full bg-primary-blue text-white hover:bg-blue-700 transition-colors"
              >
                <img
                  src={PaperPlaneRightSVG}
                  alt="Send"
                  className="w-4 h-4 md:w-5 md:h-5"
                />
                <div className="text-[0.875rem] md:text-[1rem] font-[700] font-SFPro">
                  Send Queue
                </div>
              </button>
            </div>

            {/* Search and Filter */}
            <div className="flex flex-col w-full gap-2">
              <div className="flex justify-between gap-[0.5rem] md:gap-[1rem] relative">
                <div className="relative w-full">
                  <img
                    className="absolute top-[50%] transform -translate-y-1/2 left-[0.5rem] sm:left-[1rem] z-20 cursor-pointer w-4 h-4 md:w-5 md:h-5"
                    src={MagnifyingGlassSVG}
                    alt="Search"
                  />
                  <input
                    ref={this.searchInputRef}
                    type="text"
                    placeholder={
                      isSearchFocused
                        ? "Type to search..."
                        : "Search for customer, message or AI response"
                    }
                    className={`w-full py-2 md:py-3 pl-[2.5rem] md:pl-[3rem] pr-[2.5rem] font-SFPro text-[0.75rem] sm:text-[0.875rem] md:text-[1rem] font-[400] border ${
                      searchTerm
                        ? "border-primary-blue"
                        : isSearchFocused
                        ? "border-blue-300"
                        : "border-[#DDD9D9]"
                    } focus:border-primary-blue focus:ring-1 focus:ring-primary-blue outline-none rounded-[7px] shadow-sm transition-all`}
                    value={searchTerm}
                    onChange={this.handleSearchInputChange}
                    onFocus={this.handleSearchFocus}
                    onBlur={this.handleSearchBlur}
                    onKeyDown={this.handleSearchKeyDown}
                  />
                  {searchTerm && (
                    <button
                      onClick={this.handleClearSearch}
                      className="absolute top-[50%] transform -translate-y-1/2 right-[0.5rem] sm:right-[1rem] z-20 cursor-pointer"
                      aria-label="Clear search"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 md:h-5 md:w-5 text-gray-500 hover:text-gray-700"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  )}
                  {!searchTerm && !isSearchFocused && (
                    <div className="hidden sm:flex absolute top-[50%] transform -translate-y-1/2 right-[0.75rem] z-20 items-center gap-1 text-gray-400 text-xs">
                      <kbd className="px-1.5 py-0.5 bg-gray-100 border border-gray-300 rounded-md">
                        Ctrl
                      </kbd>
                      <span>+</span>
                      <kbd className="px-1.5 py-0.5 bg-gray-100 border border-gray-300 rounded-md">
                        F
                      </kbd>
                    </div>
                  )}
                </div>
                <button className="flex justify-center items-center min-w-[2.5rem] sm:min-w-[3rem] md:min-w-[4rem] h-[2.5rem] md:h-[3.25rem] border border-[#DDD9D9] rounded-[7px] hover:bg-gray-50 transition-colors">
                  <img
                    src={FilterIconSVG}
                    alt="Filter"
                    className="w-4 h-4 md:w-5 md:h-5"
                  />
                </button>
              </div>

              {/* Search results count */}
              {searchTerm && (
                <div className="text-xs sm:text-sm text-gray-600 px-1">
                  {filteredMessages.length === 0 ? (
                    <span>
                      No messages found for "
                      <span className="font-medium">{searchTerm}</span>"
                    </span>
                  ) : (
                    <span>
                      Found {filteredMessages.length}{" "}
                      {filteredMessages.length === 1 ? "message" : "messages"}{" "}
                      for "<span className="font-medium">{searchTerm}</span>"
                    </span>
                  )}
                </div>
              )}
            </div>

            {/* Mobile View for Extra Small Screens */}
            <div className="sm:hidden">
              {filteredMessages.length > 0 ? (
                filteredMessages.map((item, index) => (
                  <div
                    key={`mobile-${index}`}
                    className="mb-4 p-3 border border-gray-200 rounded-lg shadow-sm hover:bg-gray-50"
                  >
                    <div className="flex justify-between items-center mb-2">
                      <div className="font-medium text-sm">
                        {item.user_name || item.phone_number}
                        <div className="text-xs text-gray-500">
                          <em>No platform info</em>
                        </div>
                      </div>
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${
                          item.queued_for_broadcast === true
                            ? "bg-blue-100 text-blue-800"
                            : item.unread_message?.read === false
                            ? "bg-purple-100 text-purple-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {item.queued_for_broadcast === true
                          ? "Queued"
                          : item.unread_message?.read === false
                          ? "New"
                          : "Read"}
                      </span>
                    </div>

                    <div className="grid grid-cols-1 gap-1 mb-3">
                      <div>
                        <div className="text-xs font-semibold text-gray-500">
                          Message:
                        </div>
                        <div className="text-sm text-blue-800 break-words">
                          {this.handleMessageTruncate(
                            item.unread_message?.body
                          )}
                        </div>
                      </div>

                      <div>
                        <div className="text-xs font-semibold text-gray-500">
                          AI Response:
                        </div>
                        <div className="text-sm text-blue-800 break-words">
                          {item.ai_response === "NA" || !item.ai_response
                            ? "Not available"
                            : this.handleMessageTruncate(item.ai_response)}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <button
                        onClick={() =>
                          this.handleQueueBroadcast(item.phone_number)
                        }
                        className="px-3 py-1 bg-[#198754] rounded-full text-white text-xs hover:bg-[#157347] transition-colors"
                      >
                        {item.queued_for_broadcast === true
                          ? "Dequeue"
                          : "Queue"}
                      </button>

                      <div className="flex gap-2">
                        <button
                          onClick={() =>
                            this.navigateToMessage(item.phone_number)
                          }
                          className="p-1.5 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                        </button>

                        <div className="relative">
                          <button
                            onClick={() => {
                              this.handleCustomize(index);
                              this.addPhoneNumberToState(item.phone_number);
                            }}
                            className="p-1.5 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors"
                          >
                            <img
                              src={DotsThreeSVG}
                              alt="Options"
                              className="h-4 w-4"
                            />
                          </button>

                          {this.state.activeCustomizeIndex === index && (
                            <div className="absolute top-[2.5rem] right-0 z-10 shadow-lg rounded-md">
                              <CustomizeAndMoreInformation
                                ShowMessageIntent={this.showMessageIntent}
                                phone_number={item.phone_number}
                                message={item.unread_message?.body}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : this.state.searchTerm ? (
                <div className="w-full flex flex-col items-center justify-center py-8 px-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 text-gray-300 mb-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                  <div className="text-gray-500 text-base font-medium text-center">
                    No messages match your search
                  </div>
                  <div className="text-gray-400 text-xs text-center mt-1 mb-3">
                    Try adjusting your search terms
                  </div>
                  <button
                    onClick={this.handleClearSearch}
                    className="px-4 py-1.5 bg-primary-blue text-white rounded-full hover:bg-blue-700 transition-colors text-xs font-medium"
                  >
                    Clear search
                  </button>
                </div>
              ) : (
                <div className="w-full py-8 text-center text-gray-500">
                  No messages to display
                </div>
              )}
            </div>

            {/* Table View for Larger Screens */}
            <div className="hidden sm:block overflow-x-auto w-full">
              <div className="min-w-[768px] max-w-[1920px] mx-auto grid grid-cols-12 font-SFPro font-[500] text-[0.875rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.5rem] py-[0.75rem] sm:py-[1rem] md:py-[1.5rem] px-[0.75rem] sm:px-[1rem] md:px-[2.5rem] bg-[#F1F2F3] border-[1px] border-[#D1D4DA]">
                <div className="col-span-3 break-words">Customer Name</div>
                <div className="col-span-3 break-words">Message</div>
                <div className="col-span-3 break-words">AI Response</div>
                <div className="col-span-1 break-words text-center">Status</div>
                <div className="col-span-2 break-words text-center">
                  Actions
                </div>
              </div>

              {/* Messages Rows */}
              <div className="flex flex-col min-w-[768px] max-w-[1920px] mx-auto">
                {filteredMessages.length > 0 ? (
                  filteredMessages.map((item, index) => (
                    <div
                      key={`desktop-${index}`}
                      className="grid grid-cols-12 font-inter font-[400] text-[0.75rem] sm:text-[0.875rem] md:text-[1rem] lg:text-[1.25rem] py-[0.75rem] sm:py-[1rem] md:py-[1.5rem] px-[0.75rem] sm:px-[1rem] md:px-[2.5rem] bg-[#FFFFFF] border-[1px] border-x-[#D1D4DA] border-b-[#D1D4DA] hover:bg-gray-50"
                    >
                      {/* 1) Customer Name => phone_number */}
                      <div className="col-span-3">
                        <div className="truncate font-medium">
                          {item.user_name || item.phone_number}
                        </div>
                        <div className="font-[300] text-[0.7rem] sm:text-[0.75rem] md:text-[0.875rem] font-inter text-[#5D5D5D]">
                          <em>No platform info</em>
                        </div>
                      </div>

                      {/* 2) Message => item.unread_message.body */}
                      <button
                        onClick={() =>
                          this.navigateToMessage(item.phone_number)
                        }
                        className="col-span-3 text-left"
                      >
                        <div className="w-full break-words text-blue-800 truncate hover:text-blue-600 transition-colors">
                          {this.handleMessageTruncate(
                            item.unread_message?.body
                          )}
                        </div>
                      </button>

                      {/* 3) AI Response => item.ai_response */}
                      <button
                        onClick={() =>
                          this.showAIResponse(
                            item.phone_number,
                            item.unread_message?.body
                          )
                        }
                        className="col-span-3 text-left"
                      >
                        <div
                          className={`w-full break-words truncate text-blue-800 hover:text-blue-600 transition-colors ${
                            item.ai_response === "NA" || !item.ai_response
                              ? "text-center"
                              : ""
                          }`}
                        >
                          {this.handleMessageTruncate(item.ai_response)}
                        </div>
                      </button>

                      {/* 4) Status => you can decide what "status" means. Maybe "New" if read=false */}
                      <div className="col-span-1 text-center">
                        <span
                          className={`inline-block px-2 py-1 rounded-full text-[0.65rem] sm:text-xs md:text-sm ${
                            item.queued_for_broadcast === true
                              ? "bg-blue-100 text-blue-800"
                              : item.unread_message?.read === false
                              ? "bg-purple-100 text-purple-800"
                              : "bg-gray-100 text-gray-800"
                          }`}
                        >
                          {item.queued_for_broadcast === true
                            ? "Queued"
                            : item.unread_message?.read === false
                            ? "New"
                            : "Read"}
                        </span>
                      </div>

                      {/* 5) Actions */}
                      <div className="col-span-2 flex justify-center items-center gap-[0.5rem] md:gap-[1rem]">
                        <button
                          onClick={() =>
                            this.handleQueueBroadcast(item.phone_number)
                          }
                          className="break-words px-2 py-1 text-center sm:w-[6rem] md:w-[8rem] h-[1.75rem] md:h-[2.25rem] bg-[#198754] rounded-[49px] text-white text-[0.7rem] sm:text-[0.75rem] md:text-[0.875rem] lg:text-[1rem] hover:bg-[#157347] transition-colors"
                        >
                          {item.queued_for_broadcast === true
                            ? "Dequeue"
                            : "Queue"}
                        </button>
                        <div className="relative">
                          <button
                            onClick={() => {
                              this.handleCustomize(index);
                              this.addPhoneNumberToState(item.phone_number);
                            }}
                            className="p-1 md:p-2 hover:bg-gray-100 rounded-full transition-colors"
                          >
                            <img
                              src={DotsThreeSVG}
                              alt="Options"
                              className="w-4 h-4 md:w-5 md:h-5"
                            />
                          </button>

                          {this.state.activeCustomizeIndex === index && (
                            <div className="absolute top-[2.5rem] right-0 z-10 shadow-lg rounded-md">
                              <CustomizeAndMoreInformation
                                ShowMessageIntent={this.showMessageIntent}
                                phone_number={item.phone_number}
                                message={item.unread_message?.body}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : this.state.searchTerm ? (
                  <div className="w-full flex flex-col items-center justify-center py-12 px-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-12 w-12 text-gray-300 mb-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                    <div className="text-gray-500 text-lg font-medium text-center">
                      No messages match your search
                    </div>
                    <div className="text-gray-400 text-sm text-center mt-1 mb-4">
                      Try adjusting your search terms or clear the search
                    </div>
                    <button
                      onClick={this.handleClearSearch}
                      className="px-4 py-2 bg-primary-blue text-white rounded-full hover:bg-blue-700 transition-colors text-sm font-medium"
                    >
                      Clear search
                    </button>
                  </div>
                ) : (
                  <div className="w-full py-12 text-center text-gray-500 text-lg">
                    No messages to display
                  </div>
                )}
              </div>
            </div>

            {/* Pagination - Works for all screen sizes */}
            <div className="flex w-full justify-center items-center mt-2 sm:mt-4">
              <div className="flex flex-wrap items-center justify-center gap-1 sm:gap-2">
                <button className="flex justify-center items-center px-[0.5rem] sm:px-[1rem] md:px-[1.5rem] py-[0.25rem] sm:py-[0.5rem] gap-[0.25rem] sm:gap-[0.5rem] border border-[#D5D7DA] text-[0.75rem] sm:text-[0.875rem] rounded-md hover:bg-gray-50 transition-colors">
                  <img
                    className="w-2 h-2 sm:w-3 sm:h-3 md:w-4 md:h-4"
                    src={ArrowLeftSVG}
                    alt="Previous"
                  />
                  <span className="hidden xs:inline">Previous</span>
                </button>

                <div className="flex items-center gap-1 mx-1 sm:mx-2">
                  <button className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 flex items-center justify-center font-medium text-white bg-primary-blue rounded-md">
                    1
                  </button>
                  <button className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 flex items-center justify-center hover:bg-gray-100 rounded-md transition-colors">
                    2
                  </button>
                  <button className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 flex items-center justify-center hover:bg-gray-100 rounded-md transition-colors">
                    3
                  </button>
                  <span className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 flex items-center justify-center">
                    ...
                  </span>
                  <button className="hidden xs:flex w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 items-center justify-center hover:bg-gray-100 rounded-md transition-colors">
                    8
                  </button>
                  <button className="hidden xs:flex w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 items-center justify-center hover:bg-gray-100 rounded-md transition-colors">
                    9
                  </button>
                  <button className="hidden xs:flex w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 items-center justify-center hover:bg-gray-100 rounded-md transition-colors">
                    10
                  </button>
                </div>

                <button className="flex justify-center items-center px-[0.5rem] sm:px-[1rem] md:px-[1.5rem] py-[0.25rem] sm:py-[0.5rem] gap-[0.25rem] sm:gap-[0.5rem] border border-[#D5D7DA] text-[0.75rem] sm:text-[0.875rem] rounded-md hover:bg-gray-50 transition-colors">
                  <span className="hidden xs:inline">Next</span>
                  <img
                    className="w-2 h-2 sm:w-3 sm:h-3 md:w-4 md:h-4 rotate-180"
                    src={ArrowLeftSVG}
                    alt="Next"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allOverviewChatSessions: state.messaging.allOverviewChatSessions,
  allOverviewChatSessionsFetchSuccess:
    state.messaging.allOverviewChatSessionsFetchSuccess,
  messageSent: state.messaging.messageSent,
  messageSuccessfullybroadcasted:
    state.messaging.messageSuccessfullybroadcasted,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    FetchAllMessagesForOverview,
    addMessageToRedux,
    resetSendMessageRedux,
    QueueAIResponse,
    BroadCastQueuedMessages,
    resetBroadCastMessagesStatus,
  })(Messaging)
);
