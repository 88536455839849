import React, { Component } from "react";
import PlaceholderImage1 from "../../../../Assets/svg/PlaceholderImage1.svg";
import XSVG from "../../../../Assets/svg/X.svg";
import IntentSVG from "../../../../Assets/svg/IntentSVG.svg";
import LanguageSVG from "../../../../Assets/svg/languageSVG.svg";
import SmileSVG from "../../../../Assets/svg/SmileSVG.svg";
import NeutralSVG from "../../../../Assets/svg/neutral.svg";
import SadSVG from "../../../../Assets/svg/sad-face.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import PropType from "prop-types";
import {
  GetMessageSentiment,
  GetIndividualAIResponse,
  GenerateMessageSummary,
} from "../../../../Actions/Messaging";

class ShowMessageIntent extends Component {
  static propTypes = {
    GetMessageSentiment: PropType.func.isRequired,
    GenerateMessageSummary: PropType.func.isRequired,
    messageSentiment: PropType.object.isRequired,
    message: PropType.string,
    messageSentiment: PropType.object.isRequired,
    fetchedIndividualAIResponse: PropType.object,
    phone_number: PropType.string,
    messageSummaryForOverview: PropType.string,
    messageSummaryForOverSuccess: PropType.bool,
  };

  componentDidMount() {
    this.props.GetMessageSentiment(this.props.message);
    this.props.GetIndividualAIResponse(this.props.phone_number);
    this.props.GenerateMessageSummary(this.props.message);
  }

  navigateToMessage = (number) => {
    window.location.assign(`/#/dashboard/message-overview/whatsapp/${number}`);
  };

  renderSentimentSVG = (sentiment) => {
    switch (sentiment) {
      case "positive":
        return <img src={SmileSVG} alt="Smile" />;
      case "neutral":
        return <img src={NeutralSVG} alt="Neutral" />;
      case "negative":
        return <img src={SadSVG} alt="Sad" />;
      default:
        return <img src={NeutralSVG} alt="Neutral" />;
    }
  };

  render() {
    const {
      closeShowMessageIntent,
      showMessageHistory,
      showAIResponse,
      phone_number,
      messageSummaryForOverview,
    } = this.props;
    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="flex flex-col gap-[1rem] bg-white w-[70%] rounded-[13px] pb-[2rem]">
          <div className="flex justify-between px-[2rem] py-[1rem] bg-primary-blue rounded-t-[13px]">
            <div className="flex items-center gap-[1rem]">
              <img src={PlaceholderImage1} alt="Placeholder Image 1" />
              <div className="text-white font-SFPro font-[500] text-[1.25rem]">
                <div>{phone_number}</div>
                <div className=" font-inter font-[400] text-[1rem]">
                  First Contact: 1/25/2025
                </div>
              </div>
            </div>
            <button onClick={closeShowMessageIntent}>
              <img src={XSVG} alt="Placeholder Image 1" />
            </button>
          </div>
          <div className="flex w-full px-[2rem] gap-[0.5rem]">
            <div className="w-full">
              <div className="flex bg-[#F8F9FA] border border-[#EBEAEA] px-[1rem] py-[1.5rem] gap-[1rem] rounded-[7px]">
                <img src={IntentSVG} alt="Intent" />
                <div>
                  <div className="font-[700] font-SFPro text-[1.25rem]">
                    Intent
                  </div>{" "}
                  <div className="font-inter font-[500] text-[1rem] bg-[#B61E21] w-fit px-[0.5rem] py-[0.25rem text-white rounded-[37px]">
                    {this.props.messageSentiment?.message_intent || "NA"}
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-full">
              <div className="flex bg-[#F8F9FA] border border-[#EBEAEA] px-[1rem] py-[1.5rem] gap-[1rem] rounded-[7px]">
                <img src={LanguageSVG} alt="Intent" />
                <div>
                  <div className="font-[700] font-SFPro text-[1.25rem]">
                    Language
                  </div>
                  <div className="font-inter font-[400] text-[1rem] text-[#302D2D] w-fit">
                    {this.props.messageSentiment?.language || "NA"}
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-full">
              <div className="flex bg-[#F8F9FA] border border-[#EBEAEA] px-[1rem] py-[1.5rem] gap-[1rem] rounded-[7px]">
                {this.renderSentimentSVG(
                  this.props.messageSentiment?.sentiment
                )}
                <div>
                  <div className="font-[700] font-SFPro text-[1.25rem]">
                    Sentiment
                  </div>
                  <div className="font-inter font-[400] text-[1rem] text-[#302D2D] w-fit">
                    {this.props.messageSentiment?.sentiment || "NA"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-[2rem]">
            <button>
              <div className="font-[700] font-SFPro text-[1.25rem] text-blue-800">
                Message Summary
              </div>
            </button>
            <div className="flex w-full h-auto justify-center items-stretch py-[1rem]">
              <div className="w-[11px] bg-primary-blue rounded-l-[8px]"></div>
              <div className="font-[400] w-full font-inter text-[1.25rem] py-[1rem] px-[1rem] bg-[#F8F9FA] border border-[#EBEAEA]">
                {messageSummaryForOverview?.summary || "No summary"}
              </div>
            </div>
          </div>
          <div className="px-[2rem]">
            <button onClick={showMessageHistory}>
              <div className="font-[700] font-SFPro text-[1.25rem] text-blue-800">
                Message
              </div>
            </button>
            <div className="flex w-full h-auto justify-center items-stretch py-[1rem]">
              <div className="w-[11px] bg-primary-blue rounded-l-[8px]"></div>
              <div className="font-[400] w-full font-inter text-[1.25rem] py-[1rem] px-[1rem] bg-[#F8F9FA] border border-[#EBEAEA]">
                {this.props.message}
              </div>
            </div>
          </div>

          <div className="px-[2rem]">
            <button onClick={() => showAIResponse()}>
              <div className="font-[700] font-SFPro text-[1.25rem] text-blue-800">
                Auto Response
              </div>
            </button>
            <div className="flex w-full h-auto justify-center items-stretch py-[1rem]">
              <div className="w-[11px] bg-primary-blue rounded-l-[8px]"></div>
              <div className="font-[400] w-full font-inter text-[1.25rem] py-[1rem] px-[1rem] bg-[#F8F9FA] border border-[#EBEAEA]">
                {this.props.fetchedIndividualAIResponse?.response ||
                  "No response, Please generate a response"}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[1rem] px-[2rem] py-[1rem]">
            <button
              onClick={() => this.navigateToMessage(phone_number)}
              className="bg-primary-blue text-white w-[100%] py-[1.5rem] font-[700] font-SFPro text-[1.5rem]"
            >
              Reply Message
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messageSentiment: state.messaging.messageSentiment,
  message: state.messaging.message,
  messageSentiment: state.messaging.messageSentiment,
  fetchedIndividualAIResponse: state.messaging.fetchedIndividualAIResponse,
  messageSummaryForOverview: state.messaging.messageSummaryForOverview,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    GetMessageSentiment,
    GetIndividualAIResponse,
    GenerateMessageSummary,
  })(ShowMessageIntent)
);
