import React, { Component, createRef } from "react";
import UploadSVG from "../../../../Assets/svg/UploadSVG.svg";
import XLargeBlackSVG from "../../../../Assets/svg/XLargeBlack.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import {
  UploadFileKnowledgeBase,
  IngestData,
  addFileIDToRedux,
  IsIngesting,
  resetIngested,
  resetAllKnowledgeBaseBuilder,
} from "../../../../Actions/KnowledgeBaseBuilder";
import Spinner from "../../../Animations/Spinner/Spinner";

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      error: "",
    };
    this.fileInputRef = createRef();
  }

  state = {
    isloading: false,
  };

  static propTypes = {
    UploadFileKnowledgeBase: PropTypes.func.isRequired,
    IngestData: PropTypes.func.isRequired,
    addFileIDToRedux: PropTypes.func.isRequired,
    file: PropTypes.object,
    fileUploaded: PropTypes.bool.isRequired,
    fileID: PropTypes.string,
  };

  componentWillUnmount() {
    this.props.resetAllKnowledgeBaseBuilder();
  }

  handleChooseFileClick = () => {
    this.fileInputRef.current.click();
  };

  // Handle file selection
  handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check file size (max 3MB)
      if (file.size > 3 * 1024 * 1024) {
        this.setState({ error: "File size exceeds 3MB limit." });
        return;
      }

      this.setState({ selectedFile: file, error: "" });
    }
  };

  // Handle file upload
  handleFileUpload = () => {
    const { selectedFile } = this.state;

    if (selectedFile) {
      this.props.UploadFileKnowledgeBase(selectedFile).then((data) => {
        if (data && data.file_id) {
          this.props.addFileIDToRedux(data.file_id);
        }
      });
    }
  };

  // Handle file ingestion
  handleIngestData = () => {
    const { fileID } = this.props;
    if (fileID) {
      this.props.IngestData(fileID).then(() => {
        this.props.IsIngesting(false);
        setTimeout(() => {
          this.props.resetIngested();
          this.props.handleHideModelCreate();
        }, 5000);
      });
      this.props.IsIngesting(true);
    }
    // setTimeout(() => {
    //   this.props.handleHideModelCreate();
    // }, 2000);
  };

  handleUploadLoader = () => {
    this.setState({ isloading: true });
  };

  render() {
    const { fileUploaded, fileID } = this.props;
    const { selectedFile, error } = this.state;

    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="flex flex-col gap-[1rem] w-[80%] bg-white px-[6rem] py-[3rem]">
          <div className="flex justify-end items-center">
            <button onClick={this.props.handleHideModelCreate}>
              <img src={XLargeBlackSVG} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <div className="font-inter font-[700] text-[2rem]">
              Knowledge Base Name
            </div>
            <input
              type="text"
              className="w-full h-[3rem] border-[1px] rounded-[5px] px-[1rem] mt-[0.5rem] py-[2rem] font-[500] font-inter text-[1.5rem] leading-[1.816rem] border-primary-blue-light focus:outline-primary-blue"
              //   value={this.state.name}
              //   onChange={this.handleNameChange}
              placeholder="Enter Knowledge Base Name"
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <div className="font-inter font-[700] text-[2rem]">Description</div>
            <input
              type="text"
              className="w-full h-[3rem] border-[1px] rounded-[5px] px-[1rem] mt-[0.5rem] py-[2rem] font-[500] font-inter text-[1.5rem] leading-[1.816rem] border-primary-blue-light focus:outline-primary-blue"
              //   value={this.state.name}
              //   onChange={this.handleNameChange}
              placeholder="Enter Description here"
            />
          </div>

          <div className="flex flex-col gap-[2rem]">
            <div className="flex flex-col h-[18.313rem] items-center justify-center font-inter text-[1.5rem] leading-[1.816rem] gap-[2rem] border-[1px] border-primary-blue-light border-dashed">
              <img
                src={UploadSVG}
                alt="Upload"
                className="w-[6.125rem] h-[6.125rem]"
              />
              <div className="flex flex-col gap-[1rem] items-center">
                <div>
                  Drag & drop your file here or{" "}
                  <button
                    className="w-[10.375rem] p-[0.1rem] px-[0.5rem] py-[0.6rem] border-[1px] border-[#DDD9D9] bg-[#F9F9F9]"
                    onClick={this.handleChooseFileClick}
                  >
                    choose file
                  </button>
                  <input
                    type="file"
                    accept=".pdf, .csv, .xls, .xlsx, .txt"
                    ref={this.fileInputRef}
                    onChange={this.handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
                {selectedFile && (
                  <div className="text-green-600">
                    {selectedFile.name} selected
                  </div>
                )}
                {error && <div className="text-red-500">{error}</div>}
                <div className="text-[#EC474A]">Maximum Size: 3MB</div>
              </div>
            </div>

            {/* Upload File Button switches to Ingest File when uploaded */}
            <button
              className="bg-primary-blue py-[1.5rem] font-SFPro font-[700] text-[1.5rem] text-white"
              onClick={() => {
                if (fileUploaded) {
                  this.handleIngestData();
                } else {
                  this.handleFileUpload();
                }
                this.handleUploadLoader();
              }}
              disabled={fileUploaded ? !fileID : !selectedFile}
            >
              {fileUploaded ? (
                <div>
                  <div>Ingest File</div>
                </div>
              ) : (
                <div className="flex justify-center items-center gap-[0.5rem]">
                  <div>Upload File</div> {this.state.isloading && <Spinner />}
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

// 🔹 **Ensure fileID is correctly mapped from Redux**
const mapStateToProps = (state) => ({
  file: state.knowledgeBaseBuilder.file,
  fileUploaded: state.knowledgeBaseBuilder.fileUploaded,
  fileID: state.knowledgeBaseBuilder.fileID,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    UploadFileKnowledgeBase,
    IngestData,
    addFileIDToRedux,
    IsIngesting,
    resetIngested,
    resetAllKnowledgeBaseBuilder,
  })(UploadFile)
);
