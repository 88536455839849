import {
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILED,
  INGESTION_SUCCESS,
  INGESTION_FAILED,
  ADDED_FILE_ID_TO_REDUX,
  IS_INGESTING,
  INGESTION_STATE_RESET,
  ALL_KNOWLEDGE_BASE_STATE_RESET,
  GET_FILES_SUCCESS,
  GET_FILES_FAILED,
} from "../Actions/Types";

const initialState = {
  fileUploaded: null,
  file: null,
  Ingested: null,
  fileID: null,
  isIngesting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        file: action.payload,
        fileUploaded: true,
      };
    case FILE_UPLOAD_FAILED:
      return {
        ...state,
        file: null,
        fileUploaded: false,
      };
    case INGESTION_SUCCESS:
      return {
        ...state,
        Ingested: true,
      };
    case INGESTION_FAILED:
      return {
        ...state,
        Ingested: false,
      };
    case ADDED_FILE_ID_TO_REDUX:
      return {
        ...state,
        fileID: action.payload,
      };
    case IS_INGESTING:
      return {
        ...state,
        isIngesting: action.payload,
      };
    case INGESTION_STATE_RESET:
      return {
        ...state,
        Ingested: null,
      };
    case ALL_KNOWLEDGE_BASE_STATE_RESET:
      return {
        ...state,
        fileUploaded: action.payload,
        file: action.payload,
        Ingested: action.payload,
        fileID: action.payload,
        isIngesting: action.payload,
      };
    case GET_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
      };
    case GET_FILES_FAILED:
      return {
        ...state,
        files: null,
      };
    default:
      return state;
  }
}
