import {
  FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS,
  FETCH_ALL_WHATSAPP_MESSAGES_FAILED,
  FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS,
  FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED,
  RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE,
  SUMMARIZE_MESSAGE_SUCCESS,
  SUMMARIZE_MESSAGE_FAILED,
  ADD_PHONE_NUMBER,
  FETCH_ALL_MESSAGE_FOR_OVERVIEW_SUCCESS,
  FETCH_ALL_MESSAGE_FOR_OVERVIEW_FAILED,
  GENERATED_AI_RESPONSE_SUCCESS,
  GENERATED_AI_RESPONSE_FAILED,
  GET_INDIVIDUAL_AI_GENERATED_RESPONSE_SUCCESS,
  GET_INDIVIDUAL_AI_GENERATED_RESPONSE_FAILED,
  RESET_IS_FETCHED_AI_RESPONSE,
  GET_MESSAGE_SENTIMENT_SUCCESS,
  GET_MESSAGE_SENTIMENT_FAILED,
  ADD_MESSAGE_TO_REDUX,
  AI_RESPONSE_EDITED_SUCCESS,
  AI_RESPONSE_EDITED_FAILED,
  FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_SUCCESS,
  FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_FAILED,
  GENERATE_SUMMARY_FOR_OVERVIEW_SUCCESS,
  GENERATE_SUMMARY_FOR_OVERVIEW_FAILED,
  CONTACT_SAVED_SUCCESS,
  CONTACT_SAVED_FAILED,
  RESET_SEND_MESSAGE_REDUX,
  ADD_USER_NAME,
  MESSAGE_ADDED_TO_BROADCAST_QUEUE_SUCCESS,
  MESSAGE_ADDED_TO_BROADCAST_QUEUE_FAILED,
  QUEUED_MESSAGE_SENT_SUCCESS,
  QUEUED_MESSAGE_SENT_FAILED,
  RESET_BROADCAST_MESSAGE_STATUS,
} from "../Actions/Types";

const initialState = {
  allChatSessions: null,
  allChatSessionsFetchSuccess: false,
  individualChatSession: null,
  individualChatSessionFetchSuccess: false,
  individualChatSessionFailMessage: null,
  //This my break sone codes
  messageSent: null,
  messageSentFailMessage: null,
  messageSummarySuccess: null,
  summarizedMessageAndTimeline: null,
  phoneNumber: null,
  allOverviewChatSessions: null,
  allOverviewChatSessionsFetchSuccess: false,
  generatedAIResponse: null,
  aiResponseGenerated: false,
  fetchedIndividualAIResponse: null,
  aiResponseFetched: false,
  messageSentiment: null,
  messageSentimentFetchSuccess: false,
  message: null,
  aiResponseEdited: false,
  allIncomingMessages: null,
  allIncomingMessagesFetchSuccess: false,
  messageSummaryForOverview: null,
  messageSummaryForOverSuccess: false,
  contactSaved: false,
  userName: null,
  messageAddedToBroadcastQueue: false,
  messageSuccessfullybroadcasted: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS:
      return {
        ...state,
        allChatSessions: action.payload,
        allChatSessionsFetchSuccess: true,
      };
    case FETCH_ALL_WHATSAPP_MESSAGES_FAILED:
      return {
        ...state,
        allChatSessions: null,
        allChatSessionsFetchSuccess: false,
      };
    case FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS:
      return {
        ...state,
        individualChatSession: action.payload,
        individualChatSessionFetchSuccess: true,
      };
    case FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED:
      return {
        ...state,
        individualChatSession: null,
        individualChatSessionFetchSuccess: false,
        individualChatSessionFailMessage: action.payload.detail,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSent: true,
      };
    case SEND_MESSAGE_FAILED:
      return {
        ...state,
        messageSent: false,
        messageSentFailMessage: action.payload.detail,
      };
    case RESET_SEND_MESSAGE_REDUX:
      return {
        ...state,
        messageSent: null,
      };
    case RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE:
      return {
        ...state,
        messageSent: false,
        messageSentFailMessage: null,
      };
    case SUMMARIZE_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSummarySuccess: true,
        summarizedMessageAndTimeline: action.payload,
      };
    case SUMMARIZE_MESSAGE_FAILED:
      return {
        ...state,
        messageSummarySuccess: false,
        summarizedMessageAndTimeline: null,
      };
    case ADD_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case FETCH_ALL_MESSAGE_FOR_OVERVIEW_SUCCESS:
      return {
        ...state,
        allOverviewChatSessions: action.payload,
        allOverviewChatSessionsFetchSuccess: true,
      };
    case FETCH_ALL_MESSAGE_FOR_OVERVIEW_FAILED:
      return {
        ...state,
        allOverviewChatSessions: null,
        allOverviewChatSessionsFetchSuccess: false,
      };
    case GENERATED_AI_RESPONSE_SUCCESS:
      return {
        ...state,
        generatedAIResponse: action.payload,
        aiResponseGenerated: true,
      };
    case GENERATED_AI_RESPONSE_FAILED:
      return {
        ...state,
        generatedAIResponse: null,
        messaaiResponseGeneratedgeSent: false,
      };
    case GET_INDIVIDUAL_AI_GENERATED_RESPONSE_SUCCESS:
      return {
        ...state,
        fetchedIndividualAIResponse: action.payload,
        aiResponseFetched: true,
      };
    case GET_INDIVIDUAL_AI_GENERATED_RESPONSE_FAILED:
      return {
        ...state,
        fetchedIndividualAIResponse: null,
        aiResponseFetched: false,
      };
    case RESET_IS_FETCHED_AI_RESPONSE:
      return {
        ...state,
        aiResponseGenerated: false,
      };
    case GET_MESSAGE_SENTIMENT_SUCCESS:
      return {
        ...state,
        messageSentiment: action.payload,
        messageSentimentFetchSuccess: true,
      };
    case GET_MESSAGE_SENTIMENT_FAILED:
      return {
        ...state,
        messageSentiment: null,
        messageSentimentFetchSuccess: false,
      };
    case ADD_MESSAGE_TO_REDUX:
      return {
        ...state,
        message: action.payload,
      };
    case AI_RESPONSE_EDITED_SUCCESS:
      return {
        ...state,
        aiResponseEdited: true,
      };
    case AI_RESPONSE_EDITED_FAILED:
      return {
        ...state,
        aiResponseEdited: false,
      };
    case FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_SUCCESS:
      return {
        ...state,
        allIncomingMessages: action.payload,
        allIncomingMessagesFetchSuccess: true,
      };
    case FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_FAILED:
      return {
        ...state,
        allIncomingMessages: null,
        allIncomingMessagesFetchSuccess: false,
      };
    case GENERATE_SUMMARY_FOR_OVERVIEW_SUCCESS:
      return {
        ...state,
        messageSummaryForOverview: action.payload,
        messageSummaryForOverSuccess: true,
      };
    case GENERATE_SUMMARY_FOR_OVERVIEW_FAILED:
      return {
        ...state,
        messageSummaryForOverview: null,
        messageSummaryForOverSuccess: false,
      };
    case CONTACT_SAVED_SUCCESS:
      return {
        ...state,
        contactSaved: true,
      };
    case CONTACT_SAVED_FAILED:
      return {
        ...state,
        contactSaved: false,
      };
    case ADD_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };
    case MESSAGE_ADDED_TO_BROADCAST_QUEUE_SUCCESS:
      return {
        ...state,
        messageAddedToBroadcastQueue: true,
      };
    case MESSAGE_ADDED_TO_BROADCAST_QUEUE_FAILED:
      return {
        ...state,
        messageAddedToBroadcastQueue: false,
      };
    case QUEUED_MESSAGE_SENT_SUCCESS:
      return {
        ...state,
        messageSuccessfullybroadcasted: true,
      };
    case QUEUED_MESSAGE_SENT_FAILED:
      return {
        ...state,
        messageSuccessfullybroadcasted: false,
      };
    case RESET_BROADCAST_MESSAGE_STATUS:
      return {
        ...state,
        messageSuccessfullybroadcasted: null,
      };
    default:
      return state;
  }
}
