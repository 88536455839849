import React, { Component } from "react";
import MessageSentSuccessSVG from "../../../../Assets/svg/MessageSentSuccess.svg";
import MessageSentFailedSVG from "../../../../Assets/svg/MessageSentFailed.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";

class MessageBroadCastStatus extends Component {
  static propTypes = {
    messageSuccessfullybroadcasted: PropTypes.bool,
  };

  renderMessageSentSuccess = () => {
    if (this.props.messageSuccessfullybroadcasted === true) {
      return (
        <>
          <img src={MessageSentSuccessSVG} alt="Message Sent Success" />
          <div className="font-[700] font-SFPro text-[2.5rem]">
            Bulk Message Sent Successfully
          </div>
          <div className="font-[400] font-inter text-[1.5rem] text-[#475467]">
            You have successfully sent bulk messages to the queued recipients
          </div>
        </>
      );
    } else if (this.props.messageSuccessfullybroadcasted === false) {
      return (
        <>
          <img src={MessageSentFailedSVG} alt="Message Sent Failed" />
          <div className="font-[700] font-SFPro text-[2.5rem]">
            Bulk Message Failed to Send
          </div>
          <div className="font-[400] font-inter text-[1.5rem] text-[#475467]">
            Resend message to recipient
          </div>
        </>
      );
    } else {
      return (
        <>
          <img src={MessageSentSuccessSVG} alt="Awaiting Message" />
          <div className="font-[700] font-SFPro text-[2.5rem]">
            Sending Bulk Message...
          </div>
          <div className="font-[400] font-inter text-[1.5rem] text-[#475467]">
            Sending Message to recipients
          </div>
        </>
      );
    }

    return null; // If messageSent is undefined, return nothing
  };

  render() {
    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="w-[70%] h-[40%] bg-white rounded-lg flex flex-col items-center justify-center gap-[2rem]">
          {this.renderMessageSentSuccess()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messageSuccessfullybroadcasted:
    state.messaging.messageSuccessfullybroadcasted,
});

export default connect(
  mapStateToProps,
  {}
)(withRouterHooks(MessageBroadCastStatus));
