// NewChat.js

import React, { Component } from "react";
import CESSVG from "../../../Assets/svg/CESSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import { resetContinueChatSessionAction } from "../../../Actions/AI";
import { GetUserAction } from "../../../Actions/Authentication";

class NewChat extends Component {
  static propTypes = {
    resetContinueChatSessionAction: PropTypes.func.isRequired,
    GetUserAction: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.props.resetContinueChatSessionAction();
    this.props.GetUserAction();
  }

  render() {
    return (
      <div className="flex-col relative w-full max-w-[51.438rem] h-auto min-h-[12rem] md:h-[20.188rem] bg-white rounded-lg flex items-center justify-center gap-3 md:gap-[1.063rem] mb-[3rem] md:mb-[6rem] px-4 py-6 md:p-0">
        <div className="flex flex-col w-fit h-auto md:h-[12rem] justify-between items-center text-center gap-4 md:gap-0">
          <img
            className="w-16 h-10 md:w-[113px] md:h-[58px]"
            src={CESSVG}
            alt="CES logo"
          />
          <div className="font-[700] font-SFPro text-xl md:text-[4rem] w-fit break-words">
            Hello, {this.props.user?.first_name} {this.props.user?.last_name}
          </div>
          <div className="font-[500] font-SFPro text-base md:text-[1.5rem]">
            How can I assist you?
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    resetContinueChatSessionAction,
    GetUserAction,
  })(NewChat)
);
