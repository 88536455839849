import React, { Component } from "react";
import PlaceholderImage1 from "../../../../Assets/svg/PlaceholderImage1.svg";
import XSVG from "../../../../Assets/svg/X.svg";
import IntentSVG from "../../../../Assets/svg/IntentSVG.svg";
import LanguageSVG from "../../../../Assets/svg/languageSVG.svg";
import SmileSVG from "../../../../Assets/svg/SmileSVG.svg";
import ArrowleftPNG from "../../../../Assets/png/arrowleft.png";
import { GetIncomingMessageForOverview } from "../../../../Actions/Messaging";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import { connect } from "react-redux";

class MessageHistory extends Component {
  static propTypes = {
    GetIncomingMessageForOverview: PropTypes.func.isRequired,
    allIncomingMessages: PropTypes.array,
    allIncomingMessagesFetchSuccess: PropTypes.bool,
    phone_number: PropTypes.string.isRequired,
    closeShowMessageIntent: PropTypes.func.isRequired,
    backToMessageIntent: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // Fetch messages when the component mounts
    this.props.GetIncomingMessageForOverview(this.props.phone_number);
  }

  render() {
    const {
      closeShowMessageIntent,
      backToMessageIntent,
      phone_number,
      allIncomingMessages,
    } = this.props;

    return (
      <div className="absolute flex w-[100%] justify-center items-center h-[100%] bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="flex flex-col gap-[4rem] bg-white w-[70%] rounded-[13px] pb-[2rem]">
          {/* Header Section */}
          <div className="flex justify-between px-[2rem] py-[1rem] bg-primary-blue rounded-t-[13px]">
            <div className="flex items-center gap-[1rem]">
              <button onClick={backToMessageIntent}>
                <img src={ArrowleftPNG} alt="arrow left" />
              </button>
              <img src={PlaceholderImage1} alt="Placeholder Image 1" />
              <div className="text-white font-SFPro font-[500] text-[1.25rem]">
                <div>{phone_number}</div>
                <div className=" font-inter font-[400] text-[1rem]">
                  Customer since Jan
                </div>
              </div>
            </div>
            <button onClick={closeShowMessageIntent}>
              <img src={XSVG} alt="Close" />
            </button>
          </div>

          {/* Messages Section */}
          <div className="flex flex-col gap-[1rem]">
            <div className="flex flex-col gap-[1rem] px-[2rem]">
              {allIncomingMessages && allIncomingMessages.length > 0 ? (
                allIncomingMessages.map((message, index) => (
                  <div key={index} className="flex gap-[1rem]">
                    {/* Message Body */}
                    <div className="px-[1rem] py-[0.75rem] w-fit rounded-[37px] text-black font-inter font-[400] text-[1.125rem]">
                      {new Date(message.created_at).toLocaleString()}
                    </div>
                    {/* Message Timestamp */}
                    <div className="font-[500] w-full font-inter text-[1.125rem] px-[1rem] py-[2rem] bg-[#F8F9FA] border border-[#EBEAEA] rounded-[7px]">
                      {message.body}
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-gray-500 font-inter text-center">
                  No messages found.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Map the `results` array from the API response to the component's props
const mapStateToProps = (state) => ({
  allIncomingMessages: state.messaging.allIncomingMessages?.results || [],
  allIncomingMessagesFetchSuccess:
    state.messaging.allIncomingMessagesFetchSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { GetIncomingMessageForOverview })(MessageHistory)
);
