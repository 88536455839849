import React, { Component } from "react";
import ArrowLeftSVG from "../../../Assets/svg/ArrowLeftSVG.svg";
import IdentificationCardSmartfill from "../../../Assets/svg/IdentificationCardSmartfill.svg";
import { Link } from "react-router-dom";
import SmartFlowPopUp from "./SmartFlowPopUp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import {
  FetchFormTypesAction,
  CreateFormInstanceAction,
  AddFormInstanceIDToReduxAction,
  ResetFormReduxStateAction,
} from "../../../Actions/Forms";

class SmartflowHome extends Component {
  static propTypes = {
    formTypes: PropTypes.array,
    FetchFormTypesAction: PropTypes.func.isRequired,
    CreateFormInstanceAction: PropTypes.func.isRequired,
    formInstance: PropTypes.object,
    formInstanceID: PropTypes.number,
    ResetFormReduxStateAction: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.FetchFormTypesAction();
    this.props.ResetFormReduxStateAction();
  }

  handCreateFormInstance = (formType) => {
    this.props.CreateFormInstanceAction(formType).then((data) => {
      this.props.AddFormInstanceIDToReduxAction(data.id, data.form);
    });
  };

  render() {
    const { formTypes } = this.props;

    return (
      <div className="w-full h-screen">
        {this.props.formInstance && <SmartFlowPopUp />}
        <div className="p-[4rem] flex flex-col gap-[2rem] w-full">
          <div className="flex flex-col gap-[1rem]">
            <div className="flex gap-[2rem] items-center">
              <Link to={".."}>
                <img
                  src={ArrowLeftSVG}
                  alt="ArrowLeftSVG"
                  className="w-[1.5rem] h-auto"
                />
              </Link>
              <div className="font-[700] font-SFPro text-[2rem]">
                Select Form Type
              </div>
            </div>
            <div className="font-[400] font-SFPro text-[1.5rem] pl-[3.5rem]">
              Easily fill documents and download results
            </div>
          </div>
          {formTypes?.map((formType, index) => (
            <div className="flex flex-col gap-[1rem] w-[100%]">
              <div className="flex flex-col gap-[0.5rem]">
                <div className="font-SFPro font-[700] text-[40px]">
                  Account Management
                </div>
                <div className="font-SFPro font-[400] text-[36px] text-[#667085]">
                  Forms related to your banking credentials and personal
                  informations
                </div>
              </div>
              <div
                onClick={() => this.handCreateFormInstance(formType.id)}
                className="flex gap-[2rem] pl-[2rem] w-full py-[4rem] justify-center bg-gradient-to-r from-[#3395D7] to-[#2B85C1] text-white cursor-pointer"
              >
                <div className="py-[2rem] bg-[#5AA8DB] px-[2rem] flex justify-center items-center rounded-[6px]">
                  <img src={IdentificationCardSmartfill} alt="ArrowRightSVG" />
                </div>
                <div className="flex flex-col gap-[1rem] w-[90%]">
                  <div className="flex flex-col gap-[1rem]">
                    <div
                      key={index}
                      className="h-[8.313rem] w-full flex flex-col gap-[0.5rem] justify-center items-start py-[2rem]"
                    >
                      <div className="font-[500] font-inter text-[2rem] w-fit">
                        {formType.name || "NA"}
                      </div>
                      <div className="font-[400] font-inter text-[1.5rem] w-fit">
                        {formType.description || "NA"}
                      </div>
                    </div>
                  </div>
                  <div className="flex px-[2rem] rounded-[10px] py-[1rem] w-fit bg-[#5AA8DB] text-white font-SFPro font-[700] text-[1.5rem]">
                    Get Started
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  formTypes: state.forms.formTypes,
  formInstance: state.forms.formInstance,
  formInstanceID: state.forms.formInstanceID,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    FetchFormTypesAction,
    CreateFormInstanceAction,
    AddFormInstanceIDToReduxAction,
    ResetFormReduxStateAction,
  })(SmartflowHome)
);
