export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const ACCOUNT_VERIFICATION_SUCCESS = "ACCOUNT_VERIFICATION_SUCCESS";
export const ACCOUNT_VERIFICATION_FAIL = "ACCOUNT_VERIFICATION_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS =
  "FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS";
export const FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_FAIL =
  "FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_FAIL";

export const PASSWORD_CHANGED_SUCCESS = "PASSWORD_CHANGED_SUCCESS";
export const PASSWORD_CHANGED_FAIL = "PASSWORD_CHANGED_FAIL";

export const FLUSH_ALL_AUTHENTICATION_REDUX_STATE =
  "FLUSH_ALL_AUTHENTICATION_REDUX_STATE";

export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAILED = "TOKEN_REFRESH_FAILED";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const CSV_FILE_UPLOAD_SUCCESS = "CSV_FILE_UPLOAD_SUCCESS";
export const CSV_FILE_UPLOAD_FAIL = "CSV_FILE_UPLOAD_FAIL";

export const FILE_UPLOAD_TRIGGERED_SUCCESS = "FILE_UPLOAD_TRIGGERED_SUCCESS";
export const FILE_UPLOAD_TRIGGERED_FAILED = "FILE_UPLOAD_TRIGGERED_FAILED";

export const RESET_ANALYSIS_REDUX_STATE = "RESET_ANALYSIS_REDUX_STATE";

export const FETCH_CSV_SUCCESS = "FETCH_CSV_SUCCESS";
export const FETCH_CSV_FAIL = "FETCH_CSV_FAIL";

export const DELETE_CSV_SUCCESS = "DELETE_CSV_SUCCESS";
export const DELETE_CSV_FAIL = "DELETE_CSV_FAIL";

export const FETCH_CHAT_SESSIONS_SUCCESS = "FETCH_CHAT_SESSIONS_SUCCESS";
export const FETCH_CHAT_SESSIONS_FAIL = "FETCH_CHAT_SESSIONS_FAIL";

export const FETCH_INDIVIDUAL_CHAT_SESSION_SUCCESS =
  "FETCH_INDIVIDUAL_CHAT_SESSION_SUCCESS";
export const FETCH_INDIVIDUAL_CHAT_SESSION_FAIL =
  "FETCH_INDIVIDUAL_CHAT_SESSION_FAIL";

export const CONTINUE_CHAT_SESSION_ADD_SESSION_ID_TO_REDUX =
  "CONTINUE_CHAT_SESSION_ADD_SESSION_ID_TO_REDUX";

export const MESSAGE_SENT_TO_CHAT_BOT_SUCCESS =
  "MESSAGE_SENT_TO_CHAT_BOT_SUCCESS";
export const MESSAGE_SENT_TO_CHAT_BOT_FAIL = "MESSAGE_SENT_TO_CHAT_BOT_FAIL";

export const SET_HAS_RESPONDED = "SET_HAS_RESPONDED";

export const NEW_CHAT_SESSION_STARTED_SUCCESS =
  "NEW_CHAT_SESSION_STARTED_SUCCESS";
export const NEW_CHAT_SESSION_STARTED_FAIL = "NEW_CHAT_SESSION_STARTED_FAIL";

export const RESET_CONTINUE_CHAT_SESSION = "RESET_CONTINUE_CHAT_SESSION";

export const CHAT_SESSION_IS_NEW_RESET = "CHAT_SESSION_IS_NEW_RESET";

export const SESSION_DELETE_SUCCESS = "SESSION_DELETE_SUCCESS";
export const SESSION_DELETE_FAIL = "SESSION_DELETE_FAIL";

export const CSV_ANALYSIS_SUCCESS = "CSV_ANALYSIS_SUCCESS";
export const CSV_ANALYSIS_FAIL = "CSV_ANALYSIS_FAIL";

export const SENT_TO_MAIL = "RECIEVE_IN_MAIL";
export const RENDER_ON_SITE = "RENDER_ON_SITE";

export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const ADD_ANALYSIS_FILE_NAMES_TO_REDUX =
  "ADD_ANALYSIS_FILE_NAMES_TO_REDUX";

export const RESET_ANALYSIS_MESSAGE_REDUX_STATE =
  "RESET_ANALYSIS_MESSAGE_REDUX_STATE";

export const FETCH_INDIVIDUAL_FILE_SUCCESS = "FETCH_INDIVIDUAL_FILE_SUCCESS";
export const FETCH_INDIVIDUAL_FILE_FAIL = "FETCH_INDIVIDUAL_FILE_FAIL";

export const RENDER_PDF_FILE_SUCCESS = "RENDER_PDF_FILE_SUCCESS";
export const RENDER_PDF_FILE_FAIL = "RENDER_PDF_FILE_FAIL";

export const ADD_ANALYSIS_FILE_ID_TO_REDUX = "ADD_ANALYSIS_FILE_ID_TO_REDUX";

export const FAKE_CSV_ANALYSIS_SUCCESS = "FAKE_CSV_ANALYSIS_SUCCESS";

export const COLLAPSE_CX2_SIDEBAR_ACTIVE = "COLLAPSE_CX2_SIDEBAR_ACTIVE";
export const COLLAPSE_CX2_SIDEBAR_INACTIVE = "COLLAPSE_CX2_SIDEBAR_INACTIVE";

export const PDF_ANALYSIS_COMPLETE_POP_UP = "PDF_ANALYSIS_COMPLETE_POP_UP";
export const PDF_ANALYSIS_COMPLETE_POP_UP_RESET =
  "PDF_ANALYSIS_COMPLETE_POP_UP_RESET";

export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAIL = "FETCH_ALL_USERS_FAIL";

export const BAN_USER_SUCCESS = "BAN_USER_SUCCESS";
export const BAN_USER_FAIL = "BAN_USER_FAIL";

export const SERVICE_ACCESS_GRANTED_SUCCESS = "SERVICE_ACCESS_GRANTED_SUCCESS";
export const SERVICE_ACCESS_GRANTED_FAIL = "SERVICE_ACCESS_GRANTED_FAIL";

export const CLEAR_INDIVIDUAL_CHAT_SESSION = "CLEAR_INDIVIDUAL_CHAT_SESSION";

export const HAS_CLICKED_ON_SEND_ACTIVE = "HAS_CLICKED_ON_SEND_ACTIVE";
export const HAS_CLICKED_ON_SEND_INACTIVE = "HAS_CLICKED_ON_SEND_INACTIVE";

export const FETCH_FORM_TYPES_SUCCESS = "FETCH_FORM_TYPES_SUCCESS";
export const FETCH_FORM_TYPES_FAIL = "FETCH_FORM_TYPES_FAIL";

export const CREATE_FORM_INSTANCE_SUCCESS = "CREATE_FORM_INSTANCE_SUCCESS";
export const CREATE_FORM_INSTANCE_FAIL = "CREATE_FORM_INSTANCE_FAIL";

export const FETCH_FORM_STRUCTURE_SUCCESS = "FETCH_FORM_STRUCTURE_SUCCESS";
export const FETCH_FORM_STRUCTURE_FAIL = "FETCH_FORM_STRUCTURE_FAIL";

export const ADD_FORM_INSTANCE_ID_TO_REDUX = "ADD_FORM_INSTANCE_ID_TO_REDUX";

export const FORM_ENTRY_ADDED_SUCCESS = "FORM_ENTRY_ADDED_SUCCESS";
export const FORM_ENTRY_ADDED_FAIL = "FORM_ENTRY_ADDED_FAIL";

export const RESET_FORM_REDUX_STATE = "RESET_FORM_REDUX_STATE";

export const FORM_ENTRY_RENDERED_SUCCESS = "FORM_ENTRY_RENDERED_SUCCESS";
export const FORM_ENTRY_RENDERED_FAIL = "FORM_ENTRY_RENDERED_FAIL";

export const ADDED_AI_MODEL = "ADDED_AI_MODEL";

export const FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS =
  "FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS";
export const FETCH_ALL_WHATSAPP_MESSAGES_FAILED =
  "FETCH_ALL_WHATSAPP_MESSAGES_FAILED";

export const FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS =
  "FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS";
export const FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED =
  "FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED";

export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";

export const RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE =
  "RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE";

export const SUMMARIZE_MESSAGE_SUCCESS = "SUMMARIZE_MESSAGE_SUCCESS";
export const SUMMARIZE_MESSAGE_FAILED = "SUMMARIZE_MESSAGE_FAILED";

export const ADD_PHONE_NUMBER = "ADD_PHONE_NUMBER";

export const FETCH_ALL_MESSAGE_FOR_OVERVIEW_SUCCESS =
  "FETCH_ALL_MESSAGE_FOR_OVERVIEW_SUCCESS";
export const FETCH_ALL_MESSAGE_FOR_OVERVIEW_FAILED =
  "FETCH_ALL_MESSAGE_FOR_OVERVIEW_FAILED";

export const GENERATED_AI_RESPONSE_SUCCESS = "GENERATED_AI_RESPONSE_SUCCESS";
export const GENERATED_AI_RESPONSE_FAILED = "GENERATED_AI_RESPONSE_FAILED";

export const GET_INDIVIDUAL_AI_GENERATED_RESPONSE_SUCCESS =
  "GET_INDIVIDUAL_AI_GENERATED_RESPONSE_SUCCESS";
export const GET_INDIVIDUAL_AI_GENERATED_RESPONSE_FAILED =
  "GET_INDIVIDUAL_AI_GENERATED_RESPONSE_FAILED";

export const RESET_IS_FETCHED_AI_RESPONSE = "RESET_IS_FETCHED_AI_RESPONSE";

export const GET_MESSAGE_SENTIMENT_SUCCESS = "GET_MESSAGE_SENTIMENT_SUCCESS";
export const GET_MESSAGE_SENTIMENT_FAILED = "GET_MESSAGE_SENTIMENT_FAILED";

export const ADD_MESSAGE_TO_REDUX = "ADD_MESSAGE_TO_REDUX";

export const AI_RESPONSE_EDITED_SUCCESS = "AI_RESPONSE_EDITED_SUCCESS";
export const AI_RESPONSE_EDITED_FAILED = "AI_RESPONSE_EDITED_FAILED";

export const FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_SUCCESS =
  "FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_SUCCESS";
export const FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_FAILED =
  "FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_FAILED";

export const GENERATE_SUMMARY_FOR_OVERVIEW_SUCCESS =
  "GENERATE_SUMMARY_FOR_OVERVIEW";
export const GENERATE_SUMMARY_FOR_OVERVIEW_FAILED =
  "GENERATE_SUMMARY_FOR_OVERVIEW_FAILED";

export const CONTACT_SAVED_SUCCESS = "CONTACT_SAVED_SUCCESS";
export const CONTACT_SAVED_FAILED = "CONTACT_SAVED_FAILED";

export const RESET_SEND_MESSAGE_REDUX = "RESET_SEND_MESSAGE_REDUX";

export const ADD_USER_NAME = "ADD_USER_NAME";

export const MESSAGE_ADDED_TO_BROADCAST_QUEUE_SUCCESS =
  "MESSAGE_ADDED_TO_BROADCAST_QUEUE_SUCCESS";
export const MESSAGE_ADDED_TO_BROADCAST_QUEUE_FAILED =
  "MESSAGE_ADDED_TO_BROADCAST_QUEUE_FAILED";

export const QUEUED_MESSAGE_SENT_SUCCESS = "QUEUED_MESSAGE_SENT_SUCCESS";
export const QUEUED_MESSAGE_SENT_FAILED = "QUEUED_MESSAGE_SENT_FAILED";

export const RESET_BROADCAST_MESSAGE_STATUS = "RESET_BROADCAST_MESSAGE_STATUS";

export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";

export const INGESTION_SUCCESS = "INGESTION_SUCCESS";
export const INGESTION_FAILED = "INGESTION_FAILED";

export const ADDED_FILE_ID_TO_REDUX = "ADD_FILE_ID_TO_REDUX";

export const IS_INGESTING = "IS_INGESTING";

export const INGESTION_STATE_RESET = "INGESTION_STATE_RESET";

export const ALL_KNOWLEDGE_BASE_STATE_RESET = "ALL_KNOWLEDGE_BASE_STATE_RESET";

export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const GET_FILES_FAILED = "GET_FILES_FAILED";

export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";

// Add optimistic message to chat without waiting for server response
export const ADD_OPTIMISTIC_MESSAGE = "ADD_OPTIMISTIC_MESSAGE";
