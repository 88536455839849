import React, { Component } from "react";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FetchCSVAction } from "../../../Actions/Analysis";
import UserAndNotification from "../UserAndNotification/UserAndNotification";

class FileUploadMetrics extends Component {
  static propTypes = {
    FetchCSVAction: PropTypes.func.isRequired,
    csvData: PropTypes.array,
    csvFileUploadSuccess: PropTypes.bool,
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.FetchCSVAction();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.csvFileUploadSuccess &&
      this.props.csvFileUploadSuccess !== prevProps.csvFileUploadSuccess
    ) {
      this.props.FetchCSVAction();
    }
  }

  handleFileFilter = () => {
    const { csvData, location } = this.props;

    if (!csvData || !Array.isArray(csvData)) {
      return [];
    }

    return csvData.filter((file) => {
      if (location.pathname === "/dashboard" && file.file_type === "CSV") {
        return true;
      } else if (
        location.pathname === "/dashboard/pdf-analyzer" &&
        file.file_type === "PDF"
      ) {
        return true;
      }
      return false;
    });
  };

  getMetrics = () => {
    const filteredData = this.handleFileFilter();

    const totalFiles = filteredData.length;
    const successfullyProcessed = filteredData.filter(
      (file) => file.processing_status === "Processed"
    ).length;
    const failedUploads = filteredData.filter(
      (file) => file.processing_status === "Not Processed"
    ).length;

    return {
      total: totalFiles,
      successful: successfullyProcessed,
      failed: failedUploads,
    };
  };

  render() {
    const { total, successful, failed } = this.getMetrics();

    return (
      <div className="flex flex-col gap-2 md:gap-[1.375rem]">
        <div className="font-SFPro font-[400] text-sm md:text-[1.25rem] leading-normal md:leading-[1.492rem]">
          Below are the number of files you have processed
        </div>
        <div className="flex flex-col sm:flex-row w-full gap-2 md:gap-[1rem]">
          <div className="w-full sm:w-1/3 py-3 md:py-[1.5rem] h-auto md:h-[10rem] px-3 md:px-[2rem] font-inter font-[500] text-base md:text-[1.5rem] bg-[#F4F3F4] rounded-md md:rounded-none">
            <div>Total Files Processed</div>
            <div className="text-2xl md:text-[4rem] leading-normal md:leading-[4.774rem]">
              {total}
            </div>
          </div>
          <div className="w-full sm:w-1/3 py-3 md:py-[1.5rem] h-auto md:h-[10rem] px-3 md:px-[2rem] font-inter font-[500] text-base md:text-[1.5rem] bg-[#E7F8DB] rounded-md md:rounded-none">
            <div>Successful Analysis</div>
            <div className="text-2xl md:text-[4rem] leading-normal md:leading-[4.774rem]">
              {successful}
            </div>
          </div>
          <div className="w-full sm:w-1/3 py-3 md:py-[1.5rem] h-auto md:h-[10rem] px-3 md:px-[2rem] font-inter font-[500] text-base md:text-[1.5rem] bg-[#F9EAE5] rounded-md md:rounded-none">
            <div>Failed Analysis</div>
            <div className="text-2xl md:text-[4rem] leading-normal md:leading-[4.774rem]">
              {failed}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  csvData: state.analysis.csvData,
  csvFileUploadSuccess: state.analysis.csvFileUploadSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { FetchCSVAction })(FileUploadMetrics)
);
