import React, { Component } from "react";
import UploadSVG from "../../../Assets/svg/UploadSVG.svg";
import XLargeBlackSVG from "../../../Assets/svg/XLargeBlack.svg";
import {
  UploadFileKnowledgeBase,
  IngestData,
} from "../../../Actions/KnowledgeBaseBuilder";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";

class FileUploader extends Component {
  static propTypes = {
    fileUploaded: PropTypes.bool,
    fileID: PropTypes.number,
    UploadFileKnowledgeBase: PropTypes.func.isRequired,
    handleShowAndHideFileUploader: PropTypes.func.isRequired,
    IngestData: PropTypes.func.isRequired,
  };

  state = {
    selectedFile: null,
    fileName: "",
    description: "",
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ selectedFile: file, fileName: file.name });
    }
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleUpload = () => {
    const { selectedFile, fileName, description } = this.state;
    const { UploadFileKnowledgeBase, IngestData } = this.props;

    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("file_name", fileName || selectedFile.name);
    formData.append("description", description);

    UploadFileKnowledgeBase(formData)
      .then((data) => {
        IngestData(data.file_id);
      })
      .catch(() => alert("File upload failed"));
  };

  render() {
    const { fileUploaded, fileID, handleShowAndHideFileUploader } = this.props;
    const { fileName, description, selectedFile } = this.state;

    return (
      <div className="absolute flex w-full justify-center items-center h-full bg-black bg-opacity-40 z-50 overflow-y-auto">
        <div className="flex flex-col gap-4 w-4/5 bg-white px-24 py-12">
          {/* Close Button */}
          <div className="flex justify-end">
            <button onClick={handleShowAndHideFileUploader}>
              <img src={XLargeBlackSVG} alt="Close" />
            </button>
          </div>

          {/* Knowledge Base Name Input */}
          <div className="flex flex-col gap-2">
            <label className="font-inter font-bold text-2xl">File Name</label>
            <input
              type="text"
              name="fileName"
              value={fileName}
              onChange={this.handleInputChange}
              className="w-full h-12 border rounded px-4 py-2 text-lg border-primary-blue-light focus:outline-primary-blue"
              placeholder="Enter File Name"
            />
          </div>

          {/* Description Input */}
          <div className="flex flex-col gap-2">
            <label className="font-inter font-bold text-2xl">Description</label>
            <input
              type="text"
              name="description"
              value={description}
              onChange={this.handleInputChange}
              className="w-full h-12 border rounded px-4 py-2 text-lg border-primary-blue-light focus:outline-primary-blue"
              placeholder="Enter Description here"
            />
          </div>

          {/* File Upload Section */}
          <div className="flex flex-col gap-8">
            <div className="flex flex-col h-72 items-center justify-center text-lg gap-8 border border-primary-blue-light border-dashed bg-[#E7F1FF]">
              <img src={UploadSVG} alt="Upload" className="w-24 h-24" />
              <div className="flex flex-col gap-4 items-center">
                <p>
                  Drag & drop your file here or{" "}
                  <button
                    type="button"
                    className="border p-2 bg-gray-100"
                    onClick={() => this.fileInput.click()}
                  >
                    choose file
                  </button>
                  <input
                    type="file"
                    accept=".pdf, .csv, .xls, .xlsx, .txt"
                    style={{ display: "none" }}
                    ref={(input) => (this.fileInput = input)}
                    onChange={this.handleFileChange}
                  />
                </p>
                {selectedFile && (
                  <p className="text-green-600">
                    Selected File: {selectedFile.name}
                  </p>
                )}
                <p className="text-[#EC474A]">Maximum Size: 3MB</p>
              </div>
            </div>

            {/* Upload/Ingest Button */}
            <button
              className="bg-primary-blue py-6 font-SFPro font-bold text-xl text-white"
              onClick={this.handleUpload}
              disabled={!this.state.selectedFile}
            >
              {fileUploaded ? "Ingest File" : "Upload File"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fileUploaded: state.KnowledgeBaseBuilder?.fileUploaded,
  fileID: state.KnowledgeBaseBuilder?.fileID,
});

export default withRouterHooks(
  connect(mapStateToProps, { UploadFileKnowledgeBase, IngestData })(
    FileUploader
  )
);
