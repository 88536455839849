import {
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILED,
  INGESTION_SUCCESS,
  INGESTION_FAILED,
  ADDED_FILE_ID_TO_REDUX,
  IS_INGESTING,
  INGESTION_STATE_RESET,
  ALL_KNOWLEDGE_BASE_STATE_RESET,
  GET_FILES_SUCCESS,
  GET_FILES_FAILED,
} from "./Types";
import axios from "./Axios";
import { returnErrors } from "./Errors";

export const UploadFileKnowledgeBase = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    const res = await axios.post(
      `/api/file-transfer/upload/`,
      formData,
      config
    );

    dispatch({
      type: FILE_UPLOAD_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    console.error("File upload failed:", err.response?.data || err.message);

    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: FILE_UPLOAD_FAILED,
    });
    throw err;
  }
};

export const IngestData = (fileID) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/file-transfer/ingest/${fileID}/`);

    dispatch({
      type: INGESTION_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    console.error("Ingestion failed:", err.response?.data || err.message);

    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: INGESTION_FAILED,
    });
    throw err;
  }
};

export const addFileIDToRedux = (fileID) => async (dispatch) => {
  dispatch({
    type: ADDED_FILE_ID_TO_REDUX,
    payload: fileID,
  });
};

export const IsIngesting = (ingestState) => async (dispatch) => {
  dispatch({
    type: IS_INGESTING,
    payload: ingestState,
  });
};

export const resetIngested = () => async (dispatch) => {
  dispatch({
    type: INGESTION_STATE_RESET,
    payload: null,
  });
};

export const resetAllKnowledgeBaseBuilder = () => async (dispatch) => {
  dispatch({
    type: ALL_KNOWLEDGE_BASE_STATE_RESET,
    payload: null,
  });
};

export const getFiles =
  (page = 1) =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        `/api/file-transfer/list-files/all/?page=${page}`
      );

      dispatch({
        type: GET_FILES_SUCCESS,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      console.error(
        "File list retrieval failed:",
        err.response?.data || err.message
      );

      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: GET_FILES_FAILED,
      });

      throw err;
    }
  };

