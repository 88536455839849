import React, { Component } from "react";
import ResultSentSuccessSVG from "../../../Assets/svg/ResultSentSuccessSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import { GetUserAction } from "../../../Actions/Authentication";
import { ResetAnalysisReduxStateAction } from "../../../Actions/Analysis";

class CSVSentToMail extends Component {
  static propTypes = {
    GetUserAction: PropTypes.func.isRequired,
    user: PropTypes.object,
    userFetchSuccess: PropTypes.bool,
    ResetAnalysisReduxStateAction: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.GetUserAction();
  }

  handleBackToDashboard = () => {
    const { location, navigate, ResetAnalysisReduxStateAction } = this.props;

    if (location.pathname.includes("/dashboard/pdf-analyzer")) {
      navigate("/dashboard/pdf-analyzer");
    } else if (location.pathname.includes("/dashboard")) {
      navigate("/dashboard");
    }

    // Reset the analysis state in Redux
    ResetAnalysisReduxStateAction();
  };

  render() {
    return (
      <div className="flex flex-col gap-[2rem] w-[44.938rem] h-[29.375rem] bg-white justify-center rounded-lg items-center z-100">
        <img
          src={ResultSentSuccessSVG}
          alt="AccountCreatedSuccessSVG"
          className="w-[5.25rem] h-[5.018rem]"
        />
        <div className="font-[500] font-inter text-[1.25rem] w-[30.027rem] text-center leading-[1.828rem]">
          Success! Your analyzed data is on its way to{" "}
          <span className="font-[700]">
            {this.props.userFetchSuccess && this.props.user.email}
          </span>
        </div>
        <div className="font-[400] font-inter text-[1rem] text-[#504A4A] leading-[1.219rem]">
          Please check your inbox shortly.
        </div>
        <div className="flex w-[34.063rem] h-[2.75rem] justify-evenly font-SFPro leading-[1.193rem]">
          <button
            onClick={this.handleBackToDashboard}
            className="w-[12.563rem] h-[2.75rem]  font-[500] text[1px] bg-primary-blue rounded-[7px] text-white"
          >
            Go back to dashboard
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  userFetchSuccess: state.authentication.userFetchSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { GetUserAction, ResetAnalysisReduxStateAction })(
    CSVSentToMail
  )
);
