import {
  FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS,
  FETCH_ALL_WHATSAPP_MESSAGES_FAILED,
  FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS,
  FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED,
  RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE,
  SUMMARIZE_MESSAGE_SUCCESS,
  SUMMARIZE_MESSAGE_FAILED,
  ADD_PHONE_NUMBER,
  FETCH_ALL_MESSAGE_FOR_OVERVIEW_SUCCESS,
  FETCH_ALL_MESSAGE_FOR_OVERVIEW_FAILED,
  GENERATED_AI_RESPONSE_SUCCESS,
  GENERATED_AI_RESPONSE_FAILED,
  GET_INDIVIDUAL_AI_GENERATED_RESPONSE_SUCCESS,
  GET_INDIVIDUAL_AI_GENERATED_RESPONSE_FAILED,
  RESET_IS_FETCHED_AI_RESPONSE,
  GET_MESSAGE_SENTIMENT_SUCCESS,
  GET_MESSAGE_SENTIMENT_FAILED,
  ADD_MESSAGE_TO_REDUX,
  AI_RESPONSE_EDITED_SUCCESS,
  AI_RESPONSE_EDITED_FAILED,
  FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_SUCCESS,
  FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_FAILED,
  GENERATE_SUMMARY_FOR_OVERVIEW_SUCCESS,
  GENERATE_SUMMARY_FOR_OVERVIEW_FAILED,
  CONTACT_SAVED_SUCCESS,
  CONTACT_SAVED_FAILED,
  RESET_SEND_MESSAGE_REDUX,
  ADD_USER_NAME,
  MESSAGE_ADDED_TO_BROADCAST_QUEUE_SUCCESS,
  MESSAGE_ADDED_TO_BROADCAST_QUEUE_FAILED,
  QUEUED_MESSAGE_SENT_SUCCESS,
  QUEUED_MESSAGE_SENT_FAILED,
  RESET_BROADCAST_MESSAGE_STATUS,
} from "./Types";
import axios from "./Axios";
import { returnErrors } from "./Errors";

export const FetchAllWhatAppMessagesAction = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/messaging/all-chat-sessions/`);

    dispatch({
      type: FETCH_ALL_WHATSAPP_MESSAGES_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));

    dispatch({
      type: FETCH_ALL_WHATSAPP_MESSAGES_FAILED,
    });
    throw err;
  }
};

export const FetchIndividualWhatsAppMessagesAction =
  (number) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/messaging/chat-session/${number}/`);

      dispatch({
        type: FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: FETCH_INDIVIDUAL_WHATSAPP_MESSAGES_FAILED,
      });
      throw err;
    }
  };

export const SendMessageAction = (to, message) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/messaging/send-message/`, {
      to,
      message,
    });

    dispatch({
      type: SEND_MESSAGE_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: SEND_MESSAGE_FAILED,
    });
    throw err;
  }
};

export const resetHasRespondedToWhatsAppMessage = () => (dispatch) => {
  dispatch({
    type: RESET_HAS_RESPONDED_TO_WHATSAPP_MESSAGE,
  });
};

export const SummarizeMessage =
  (phone_number, start_date, end_date) => async (dispatch) => {
    try {
      // Dispatch loading state
      const res = await axios.post(`/api/messaging/get-and-summarize-chat/`, {
        phone_number,
        start_date,
        end_date,
      });

      dispatch({
        type: SUMMARIZE_MESSAGE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: SUMMARIZE_MESSAGE_FAILED,
      });
      return err.response?.data;
    }
  };

export const addPhoneNumberToRedux = (phoneNumber) => (dispatch) => {
  dispatch({
    type: ADD_PHONE_NUMBER,
    payload: phoneNumber,
  });
};

export const FetchAllMessagesForOverview = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/messaging/get-all-unread-messages/`);

    dispatch({
      type: FETCH_ALL_MESSAGE_FOR_OVERVIEW_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: FETCH_ALL_MESSAGE_FOR_OVERVIEW_FAILED,
    });
    return err.response?.data;
  }
};

export const GenerateAIResponse =
  (phone_number, tone, length) => async (dispatch) => {
    try {
      const res = await axios.post(
        `/api/messaging/generate-response-to-unread-messages/${phone_number}/`,
        {
          tone,
          length,
        }
      );

      dispatch({
        type: GENERATED_AI_RESPONSE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: GENERATED_AI_RESPONSE_FAILED,
      });
      return err.response?.data;
    }
  };

export const GetIndividualAIResponse = (phone_number) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/messaging/get-ai-response/${phone_number}/`
    );

    dispatch({
      type: GET_INDIVIDUAL_AI_GENERATED_RESPONSE_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: GET_INDIVIDUAL_AI_GENERATED_RESPONSE_FAILED,
    });
    return err.response?.data;
  }
};

export const resetIsAIResponseGenerated = () => (dispatch) => {
  dispatch({
    type: RESET_IS_FETCHED_AI_RESPONSE,
  });
};

export const GetMessageSentiment = (message) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/messaging/get-message-sentiment/`, {
      message,
    });

    dispatch({
      type: GET_MESSAGE_SENTIMENT_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: GET_MESSAGE_SENTIMENT_FAILED,
    });
    return err.response?.data;
  }
};

export const addMessageToRedux = (message) => (dispatch) => {
  dispatch({
    type: ADD_MESSAGE_TO_REDUX,
    payload: message,
  });
};

export const EditAIResponse = (phone_number, response) => async (dispatch) => {
  try {
    const res = await axios.post(
      `/api/messaging/edit-ai-response/${phone_number}/`,
      {
        response,
      }
    );

    dispatch({
      type: AI_RESPONSE_EDITED_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: AI_RESPONSE_EDITED_FAILED,
    });
    return err.response?.data;
  }
};

export const GetIncomingMessageForOverview =
  (phone_number) => async (dispatch) => {
    try {
      const res = await axios.get(
        `/api/messaging/get-individual-messages-paginated/${phone_number}/`
      );

      dispatch({
        type: FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: FETCH_INCOMING_MESSAGES_FOR_OVERVIEW_FAILED,
      });
      return err.response?.data;
    }
  };

export const GenerateMessageSummary = (message) => async (dispatch) => {
  try {
    const res = await axios.post(
      `/api/messaging/summarize-message-for-overview/`,
      {
        message,
      }
    );

    dispatch({
      type: GENERATE_SUMMARY_FOR_OVERVIEW_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: GENERATE_SUMMARY_FOR_OVERVIEW_FAILED,
    });
  }
};

export const SaveContactName =
  (user_name, phone_number) => async (dispatch) => {
    try {
      const res = await axios.post(
        `/api/messaging/save-user-name/${phone_number}/`,
        {
          user_name,
        }
      );
      dispatch({
        type: CONTACT_SAVED_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: CONTACT_SAVED_FAILED,
      });
    }
  };

export const resetSendMessageRedux = () => (dispatch) => {
  dispatch({
    type: RESET_SEND_MESSAGE_REDUX,
  });
};

export const addUserNameToRedux = (user_name) => (dispatch) => {
  dispatch({
    type: ADD_USER_NAME,
    payload: user_name,
  });
};

export const QueueAIResponse = (phone_number) => async (dispatch) => {
  try {
    const res = await axios.post(
      `/api/messaging/queue-ai-response/${phone_number}/`
    );
    dispatch({
      type: MESSAGE_ADDED_TO_BROADCAST_QUEUE_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: MESSAGE_ADDED_TO_BROADCAST_QUEUE_FAILED,
    });
    return err.response?.data;
  }
};

export const BroadCastQueuedMessages = () => async (dispatch) => {
  try {
    const res = await axios.post(`/api/messaging/send-queued-ai-responses/`);
    dispatch({
      type: QUEUED_MESSAGE_SENT_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: QUEUED_MESSAGE_SENT_FAILED,
    });
    return err.response?.data;
  }
};

export const resetBroadCastMessagesStatus = () => (dispatch) => {
  dispatch({
    type: RESET_BROADCAST_MESSAGE_STATUS,
  });
};