import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import { motion, AnimatePresence } from "framer-motion";
import {
  FetchFormStructureAction,
  UpdateFormInstanceAction,
  ResetFormReduxStateAction,
} from "../../../Actions/Forms";
import CancelButtonSVG from "../../../Assets/svg/CancelButtonSVG.svg";
import CompletCheckMarkSVG from "../../../Assets/svg/CompletCheckMarkSVG.svg";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";
import calendarPNG from "../../../Assets/png/calendar.png";

const SmartFlowInputs = ({
  formStructure,
  fetchFormStructure,
  formInstanceID,
  updateFormInstance,
  ResetFormReduxStateAction,
  navigate,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward
  const [showSecondMessage, setShowSecondMessage] = useState(false);

  useEffect(() => {
    if (formInstanceID) {
      fetchFormStructure(formInstanceID).then((data) => {
        if (data && data.values) {
          setFormData(data.values);
        }
      });
    }
  }, [fetchFormStructure, formInstanceID]);

  useEffect(() => {
    setShowSecondMessage(false);
    const timer = setTimeout(() => {
      setShowSecondMessage(true);
    }, 2000); // Increased from 1000ms to 2000ms (2 seconds)

    return () => clearTimeout(timer);
  }, [currentStep]);

  if (!formStructure || !formStructure.values) {
    return <div>Loading...</div>;
  }

  const fieldKeys = Object.keys(formStructure.values);

  if (fieldKeys.length === 0) {
    return <div>No fields found</div>;
  }

  const currentFieldKey = fieldKeys[currentStep];
  const currentValue = formData[currentFieldKey];

  // Calculate progress percentage
  const progressPercentage = Math.round(
    ((currentStep + 1) / fieldKeys.length) * 100
  );

  const isBooleanField =
    currentFieldKey &&
    currentFieldKey.startsWith("(") &&
    currentFieldKey.endsWith(")");

  const isDateField =
    currentFieldKey &&
    (formatFieldLabel(currentFieldKey).toLowerCase() ===
      "previous date of birth" ||
      formatFieldLabel(currentFieldKey).toLowerCase() ===
        "update date of birth" ||
      formatFieldLabel(currentFieldKey).toLowerCase() === "date of birth" ||
      // Add other exact date field matches here as needed
      false);

  // Add gender field detection
  const isGenderField =
    currentFieldKey &&
    (formatFieldLabel(currentFieldKey).toLowerCase() === "previous gender" ||
      formatFieldLabel(currentFieldKey).toLowerCase() === "update gender" ||
      // Add other gender field matches here as needed
      false);

  const handleChange = (e) => {
    const value = isBooleanField ? e.target.checked : e.target.value;
    setFormData((prev) => ({ ...prev, [currentFieldKey]: value }));
  };

  const handleNext = async () => {
    if (currentStep < fieldKeys.length - 1) {
      const updatedField = { [currentFieldKey]: formData[currentFieldKey] };
      try {
        setIsSubmitting(true);
        await updateFormInstance(formInstanceID, updatedField);
        setDirection(1);
        setCurrentStep((prevStep) => prevStep + 1);
      } catch (error) {
        console.error("Error updating form instance:", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setDirection(-1);
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await updateFormInstance(formInstanceID, formData);
      setIsCompleted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleExitForm = () => {
    ResetFormReduxStateAction();
  };

  const handlePreview = () => {
    navigate(`preview/${formInstanceID}`);
  };

  // Animation variants
  const contentVariants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
      transition: {
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      },
    },
    exit: (direction) => ({
      x: direction > 0 ? -1000 : 1000,
      opacity: 0,
      transition: {
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      },
    }),
  };

  if (isCompleted) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full h-full flex flex-col justify-center items-center bg-[#FFFFFF] rounded-[1rem] gap-[1rem] px-[4rem] py-[2rem]"
      >
        <img
          className="size-[7.875rem]"
          src={CompletCheckMarkSVG}
          alt="Completion Checkmark"
        />
        <div className="font-[700] font-inter text-[2rem] flex flex-col gap-[1.5rem]">
          <div className="flex items-center gap-[1rem]">
            <div className="font-[500] font-inter text-[1.625rem]">
              You have successfully updated your form.
            </div>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="font-[700] font-inter text-[1.5rem] w-full h-[4.25rem] bg-primary-blue text-white rounded-[0.5rem]"
            onClick={handlePreview}
          >
            Preview Form
          </motion.button>
        </div>
      </motion.div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col bg-[#FFFFFF]">
      {/* Progress bar header */}
      <div className="w-full px-6 py-3 border-b flex items-center justify-between">
        <button
          onClick={handleExitForm}
          className="flex items-center justify-center"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 12H5"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 19L5 12L12 5"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <div className="flex-1 mx-4">
          <div className="w-full bg-gray-200 rounded-full h-2">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${progressPercentage}%` }}
              transition={{ duration: 0.5 }}
              className="bg-blue-600 h-2 rounded-full"
            ></motion.div>
          </div>
        </div>

        <div className="text-black font-medium">{progressPercentage}%</div>
      </div>

      {/* Main content */}
      <div className="flex-1 flex flex-col items-center px-[4rem] py-[2rem] gap-[1rem] overflow-hidden">
        <AnimatePresence initial={false} custom={direction} mode="wait">
          <motion.div
            key={currentStep}
            custom={direction}
            variants={contentVariants}
            initial="enter"
            animate="center"
            exit="exit"
            className="font-[700] font-inter text-[2rem] flex flex-col gap-[1.5rem] w-full max-w-[90rem]"
          >
            <div className="mb-[1.5rem]">
              <div>
                {isBooleanField ? (
                  <div className="flex flex-col gap-[2rem]">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      className="font-[400] font-inter text-[1.875rem] p-[4rem] bg-[#CADFFF] rounded-tl-[0px] rounded-tr-[10px] rounded-bl-[20px] rounded-br-[10px]"
                    >
                      Hi there, to continue select the option that best applies
                      to you
                    </motion.div>

                    <AnimatePresence>
                      {showSecondMessage && (
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.5 }}
                          className="font-[400] font-inter text-[1.875rem] p-[4rem] bg-[#CADFFF] rounded-tl-[0px] rounded-tr-[10px] rounded-bl-[20px] rounded-br-[10px]"
                        >
                          Will you be updating your{" "}
                          {formatFieldLabel(currentFieldKey)}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <div className="font-[400] font-inter text-[1.875rem] bg-[#B7CDE7] p-[4rem] rounded-tl-[0px] rounded-tr-[10px] rounded-bl-[20px] rounded-br-[10px]">
                    To continue, please enter your{" "}
                    {isGenderField
                      ? "Gender"
                      : formatFieldLabel(currentFieldKey)}
                  </div>
                )}
              </div>
            </div>

            {isBooleanField ? (
              <div className="flex gap-[1rem] w-full justify-start">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      [currentFieldKey]: true,
                    }))
                  }
                  className={`font-[500] font-inter text-[1.5rem] w-[8rem] h-[3.5rem] rounded-[0.5rem] transition duration-200 ease-in-out ${
                    currentValue === true
                      ? "bg-primary-blue text-white"
                      : "bg-[#F5F5F5] text-[#333333] border-[1px] border-[#E0E0E0]"
                  }`}
                >
                  Yes
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      [currentFieldKey]: false,
                    }))
                  }
                  className={`font-[500] font-inter text-[1.5rem] w-[8rem] h-[3.5rem] rounded-[0.5rem] transition duration-200 ease-in-out ${
                    currentValue === false
                      ? "bg-primary-blue text-white"
                      : "bg-[#F5F5F5] text-[#333333] border-[1px] border-[#E0E0E0]"
                  }`}
                >
                  No
                </motion.button>
              </div>
            ) : isDateField ? (
              <div className="relative">
                <div className="font-inter font-500 text-[1rem] text-[#0D6EFD] bg-white px-[1rem] absolute top-[-0.75rem] left-[2rem] z-10">
                  {formatFieldLabel(currentFieldKey)}
                </div>
                <div className="relative">
                  <input
                    type="date"
                    value={currentValue || ""}
                    onChange={handleChange}
                    className="font-[500] font-inter text-[1.25rem] text-[#5D5D5D] w-full h-[4.375rem] rounded-[0.5rem] border-[1px] border-primary-blue-light pl-[0.5rem] pr-[2.5rem] focus:border-primary-blue focus:outline-none transition duration-200 ease-in-out appearance-none"
                  />
                </div>
              </div>
            ) : isGenderField ? (
              <div className="relative">
                <div className="font-inter font-500 text-[1rem] text-[#0D6EFD] bg-white px-[1rem] absolute top-[-0.75rem] left-[2rem] z-10">
                  {formatFieldLabel(currentFieldKey).toLowerCase() ===
                    "previous gender" ||
                  formatFieldLabel(currentFieldKey).toLowerCase() ===
                    "update gender"
                    ? ""
                    : formatFieldLabel(currentFieldKey)}
                </div>
                <div className="flex gap-[1rem] w-full justify-start">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() =>
                      setFormData((prev) => ({
                        ...prev,
                        [currentFieldKey]: "Male",
                      }))
                    }
                    className={`font-[500] font-inter text-[1.5rem] w-[8rem] h-[3.5rem] rounded-[0.5rem] transition duration-200 ease-in-out ${
                      currentValue === "Male"
                        ? "bg-[#0D6EFD] text-white"
                        : "bg-[#F5F5F5] text-[#333333] border-[1px] border-[#E0E0E0]"
                    }`}
                  >
                    Male
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() =>
                      setFormData((prev) => ({
                        ...prev,
                        [currentFieldKey]: "Female",
                      }))
                    }
                    className={`font-[500] font-inter text-[1.5rem] w-[8rem] h-[3.5rem] rounded-[0.5rem] transition duration-200 ease-in-out ${
                      currentValue === "Female"
                        ? "bg-[#0D6EFD] text-white"
                        : "bg-[#F5F5F5] text-[#333333] border-[1px] border-[#E0E0E0]"
                    }`}
                  >
                    Female
                  </motion.button>
                </div>
              </div>
            ) : (
              <div className="relative">
                <div className="font-inter font-500 text-[1rem] text-[#0D6EFD] bg-white px-[1rem] absolute top-[-0.75rem] left-[2rem]">
                  {formatFieldLabel(currentFieldKey)}
                </div>
                <input
                  type="text"
                  placeholder={`Enter ${formatFieldLabel(currentFieldKey)}`}
                  value={currentValue || ""}
                  onChange={handleChange}
                  className="font-[500] font-inter text-[1.25rem] text-[#5D5D5D] w-full h-[4.375rem] rounded-[0.5rem] border-[1px] border-primary-blue-light pl-[0.5rem] focus:border-primary-blue focus:outline-none transition duration-200 ease-in-out placeholder-gray-400"
                />
              </div>
            )}

            <div
              className={`flex ${
                currentStep === 0 ? "justify-end" : "justify-between"
              } w-full mt-[1rem]`}
            >
              {currentStep > 0 && (
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleBack}
                  className="font-[700] font-inter text-[2rem] w-[18%] h-[4.25rem] bg-[#D9D9D9] text-black rounded-[3rem]"
                >
                  Back
                </motion.button>
              )}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleNext}
                disabled={isSubmitting}
                className={`font-[700] font-inter text-[2rem] w-[18%] h-[4.25rem] bg-[#0D6EFD] text-white rounded-[3rem] ${
                  isSubmitting
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-[#0A58CA] transition"
                }`}
              >
                {isSubmitting
                  ? "Submitting..."
                  : currentStep === fieldKeys.length - 1
                  ? "Finish"
                  : "Next"}
              </motion.button>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

// Helper function to format field labels
const formatFieldLabel = (fieldKey) => {
  if (!fieldKey) return ""; // Return empty string for invalid keys
  const cleanedKey = fieldKey.replace(/^\(|\)$/g, ""); // Remove parentheses
  return cleanedKey
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

SmartFlowInputs.propTypes = {
  formStructure: PropTypes.object.isRequired,
  fetchFormStructure: PropTypes.func.isRequired,
  formInstanceID: PropTypes.number,
  updateFormInstance: PropTypes.func.isRequired,
  ResetFormReduxStateAction: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  formStructure: state.forms.formStructure,
  formInstanceID: state.forms.formInstanceID,
});

const mapDispatchToProps = {
  fetchFormStructure: FetchFormStructureAction,
  updateFormInstance: UpdateFormInstanceAction,
  ResetFormReduxStateAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(SmartFlowInputs));
