import React, { Component } from "react";
import { getFiles } from "../../../Actions/KnowledgeBaseBuilder";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ArrowLeftSVG from "../../../Assets/svg/ArrowLeftSVG.svg";

class FileManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      currentPage: 1,
      totalPages: 1,
      hasNext: false,
      hasPrevious: false,
      itemsPerPage: 10,
      hoveredRow: null,
    };
  }

  static propTypes = {
    getFiles: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchFiles(1);
  }

  fetchFiles = async (page) => {
    try {
      const response = await this.props.getFiles(page);
      if (response) {
        this.setState({
          files: response.files || [],
          currentPage: response.current_page || page,
          totalPages: response.total_pages || 1,
          hasNext: response.has_next || false,
          hasPrevious: response.has_previous || false,
          itemsPerPage: response.items_per_page || 10,
        });
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= this.state.totalPages) {
      this.setState({ currentPage: newPage }, () => {
        this.fetchFiles(newPage);
      });
    }
  };

  handleDescription = (index) => {
    this.setState({ hoveredRow: index });
  };

  handleExitDescription = () => {
    this.setState({ hoveredRow: null });
  };

  shortenFileName = (fileName, maxLength = 20) => {
    if (fileName.length <= maxLength) return fileName;
    const extensionIndex = fileName.lastIndexOf(".");
    const extension =
      extensionIndex !== -1 ? fileName.slice(extensionIndex) : "";
    const baseName = fileName.slice(0, maxLength - 5);
    return `${baseName} ...${extension}`;
  };

  renderPagination = () => {
    const { currentPage, totalPages } = this.state;
    const pageNumbers = [];

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      if (currentPage > 3) pageNumbers.push("...");

      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - 2) pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }

    return (
      <div className="flex justify-between items-center gap-4 mt-4">
        {/* Previous Button */}
        <button
          onClick={() => this.handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="flex justify-center items-center gap-[2rem] px-4 py-2 border rounded-md text-gray-700 bg-white hover:bg-gray-100 disabled:opacity-50 text-[1rem]"
        >
          <img className="size-[1rem]" src={ArrowLeftSVG} alt="Next" /> Previous
        </button>

        {/* Page Number Buttons */}
        <div className="flex gap-[2rem]">
          {pageNumbers.map((number, index) => (
            <button
              key={index}
              onClick={() =>
                typeof number === "number" && this.handlePageChange(number)
              }
              className={`px-4 py-2 rounded-md text-[1rem] ${
                currentPage === number
                  ? "bg-blue-600 text-white font-bold"
                  : "bg-white text-gray-700 hover:bg-gray-100"
              } ${number === "..." ? "cursor-default" : ""}`}
              disabled={number === "..."}
            >
              {number}
            </button>
          ))}
        </div>

        {/* Next Button */}
        <button
          onClick={() => this.handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="flex justify-center items-center gap-[2rem] px-4 py-2 border rounded-md text-gray-700 bg-white hover:bg-gray-100 disabled:opacity-50 text-[1rem]"
        >
          Next{" "}
          <img
            className=" rotate-180 size-[1rem]"
            src={ArrowLeftSVG}
            alt="Next"
          />
        </button>
      </div>
    );
  };

  render() {
    return (
      <div className="flex flex-col gap-[2rem]">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-[0.4rem]">
            <div className="font-SFPro font-[700] text-[2rem]">
              Upload All Files
            </div>
            <div className="font-SFPro font-[400] text-[1.25rem]">
              Put your files into our database now
            </div>
          </div>
          <button
            onClick={this.props.handleShowAndHideFileUploader}
            className="bg-[#0D6EFD] px-[2rem] py-[0.7rem] text-white font-SFPro font-[700] text-[1.5rem] rounded-[9px]"
          >
            Upload File
          </button>
        </div>

        {/* Table Headers */}
        <div>
          <div className="grid grid-cols-[0.6fr_0.7fr_0.4fr_0.6fr_0.3fr_0.3fr] py-[1rem] bg-[#F2F2F3] px-[1rem] gap-[1rem] font-inter font-[400] text-[1.25rem]">
            <div>File Name</div>
            <div>Date Uploaded</div>
            <div>File Size</div>
            <div>Description</div>
            <div>Actions</div>
          </div>

          {/* File Rows */}
          {this.state.files.map((file, index) => (
            <div
              key={file.id}
              onMouseLeave={this.handleExitDescription}
              className={`grid grid-cols-[0.6fr_0.7fr_0.4fr_0.6fr_0.3fr_0.3fr] py-[1rem] px-[1rem] gap-[1rem] border border-x-[#DDD9D9] border-t-0 font-inter font-[400] text-[1.125rem] transition-all duration-300 ${
                this.state.hoveredRow === index ? "bg-white" : "bg-white"
              }`}
            >
              {this.state.hoveredRow === index ? (
                <div className="col-span-6 flex flex-col gap-[0.2rem] text-[1.2rem] font-semibold p-2">
                  <div className="font-inter font-[500] text-[1.25rem]">
                    Description
                  </div>
                  <div className="font-inter font-[400] text-[1.125rem] text-[#667085]">
                    {file.description || "No description available"}
                  </div>
                </div>
              ) : (
                <>
                  <div>{this.shortenFileName(file.file_name)}</div>
                  <div>{new Date(file.uploaded_at).toLocaleString()}</div>
                  <div>
                    {file.file_size ? `${file.file_size} MB` : "Unknown"}
                  </div>
                  <div
                    className="text-[#0D6EFD] cursor-pointer"
                    onMouseEnter={() => this.handleDescription(index)}
                  >
                    {file.description || "No description"}
                  </div>
                  <div>Actions</div>
                </>
              )}
            </div>
          ))}
        </div>

        {/* Pagination UI */}
        {this.renderPagination()}
      </div>
    );
  }
}

// Connect Redux
const mapStateToProps = (state) => ({
  files: state.knowledgeBaseBuilder.files,
});

const mapDispatchToProps = (dispatch) => ({
  getFiles: (page) => dispatch(getFiles(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileManager);
