import axios from "./Axios";
import { returnErrors } from "./Errors";
import {
  FETCH_CHAT_SESSIONS_SUCCESS,
  FETCH_CHAT_SESSIONS_FAIL,
  FETCH_INDIVIDUAL_CHAT_SESSION_SUCCESS,
  FETCH_INDIVIDUAL_CHAT_SESSION_FAIL,
  CONTINUE_CHAT_SESSION_ADD_SESSION_ID_TO_REDUX,
  MESSAGE_SENT_TO_CHAT_BOT_SUCCESS,
  MESSAGE_SENT_TO_CHAT_BOT_FAIL,
  SET_HAS_RESPONDED,
  NEW_CHAT_SESSION_STARTED_SUCCESS,
  NEW_CHAT_SESSION_STARTED_FAIL,
  RESET_CONTINUE_CHAT_SESSION,
  CHAT_SESSION_IS_NEW_RESET,
  SESSION_DELETE_SUCCESS,
  SESSION_DELETE_FAIL,
  CLEAR_INDIVIDUAL_CHAT_SESSION,
  HAS_CLICKED_ON_SEND_ACTIVE,
  HAS_CLICKED_ON_SEND_INACTIVE,
  ADDED_AI_MODEL,
  ADD_OPTIMISTIC_MESSAGE,
} from "./Types";

export const FetchChatSessionsAction = () => (dispatch) => {
  axios
    .get(`/api/ai/sessions/`)
    .then((res) => {
      dispatch({
        type: FETCH_CHAT_SESSIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: FETCH_CHAT_SESSIONS_FAIL,
      });
    });
};

export const FetchIndividualChatSessionAction = (id) => (dispatch) => {
  axios
    .get(`/api/ai/sessions/${id}/messages/`)
    .then((res) => {
      dispatch({
        type: FETCH_INDIVIDUAL_CHAT_SESSION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: FETCH_INDIVIDUAL_CHAT_SESSION_FAIL,
        payload: err.response && err.response.data,
      });
    });
};

export const ContinueChatSessionAddSessionIDToReduxAction = (id) => {
  return {
    type: CONTINUE_CHAT_SESSION_ADD_SESSION_ID_TO_REDUX,
    payload: id,
  };
};

export const addOptimisticMessage = (message, sessionId) => ({
  type: ADD_OPTIMISTIC_MESSAGE,
  payload: {
    content: message,
    role: "user",
    timestamp: new Date().toISOString(),
    sessionId,
    id: `temp_${Date.now()}_${Math.floor(Math.random() * 1000)}`,
  },
});

export const chatInputAction =
  (message, sessionID, model = "cx2") =>
  (dispatch) => {
    dispatch(addOptimisticMessage(message, sessionID));

    axios
      .post(`/api/ai/sessions/${sessionID}/messages/`, {
        content: message,
        // model: model || "cx2",
      })
      .then((res) => {
        dispatch({
          type: MESSAGE_SENT_TO_CHAT_BOT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: MESSAGE_SENT_TO_CHAT_BOT_FAIL,
        });
      });
  };

export const resetHasResponded = () => ({
  type: SET_HAS_RESPONDED,
  payload: false,
});

export const StartNewChatSessionAction = () => async (dispatch) => {
  try {
    const res = await axios.post(`/api/ai/sessions/`);
    dispatch({
      type: NEW_CHAT_SESSION_STARTED_SUCCESS,
      payload: res.data,
    });
    return res.data; // Return res.data for use in subsequent calls
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({
      type: NEW_CHAT_SESSION_STARTED_FAIL,
    });
    throw err; // Throw error if you want to handle it later
  }
};

export const resetContinueChatSessionAction = () => ({
  type: RESET_CONTINUE_CHAT_SESSION,
});

export const DeleteChatSessionAction = (id) => (dispatch) => {
  return axios
    .delete(`/api/ai/delete-sessions/${id}/`)
    .then(() => {
      dispatch({ type: SESSION_DELETE_SUCCESS, payload: id });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: SESSION_DELETE_FAIL });
    });
};

export const ResetIsNewChatSession = () => ({
  type: CHAT_SESSION_IS_NEW_RESET,
  payload: false,
});

export const ClearIndividualChatSession = () => ({
  type: CLEAR_INDIVIDUAL_CHAT_SESSION,
});

export const hasClicked = () => ({
  type: HAS_CLICKED_ON_SEND_ACTIVE,
});

export const hasNotClicked = () => ({
  type: HAS_CLICKED_ON_SEND_INACTIVE,
});

export const AddAIModelToRedux = (model, modelName) => ({
  type: ADDED_AI_MODEL,
  payload: { model, modelName },
});
