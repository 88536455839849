import React, { Component } from "react";
import HomeSVG from "../../Assets/svg/HouseSVG.svg";
import CSVSVG from "../../Assets/svg/CSVSVG.svg";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import LogoutSVG from "../../Assets/svg/LogoutSVG.svg";
import { LogoutAction } from "../../Actions/Authentication";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import CESRobotSVG from "../../Assets/svg/CESRobotSVG.svg";
import SettingGearSVG from "../../Assets/svg/SettingGearSVG.svg";
import FilePdfSVG from "../../Assets/svg/FilePdfSVG.svg";
import FilesBlackSVG from "../../Assets/svg/FilesBlackSVG.svg";
import { Link } from "react-router-dom";
import WhiteFileCsvSVG from "../../Assets/svg/WhiteFileCsvSVG.svg";
import WhiteFilePdfSVG from "../../Assets/svg/WhiteFilePdfSVG.svg";
import EnvelopeIconSVG from "../../Assets/svg/EnvelopeIconSVG.svg";
import PaintBucketSVG from "../../Assets/svg/PaintBucketSVG.svg";
import AIGenerateIconDarkSVG from "../../Assets/svg/AssistGenAILogoSVG.svg";
import { FetchAllMessagesForOverview } from "../../Actions/Messaging";

class DashboardSidebar extends Component {
  static propTypes = {
    LogoutAction: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    FetchAllMessagesForOverview: PropTypes.func.isRequired,
    allOverviewChatSessions: PropTypes.array,
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.FetchAllMessagesForOverview();
  }

  handlePath = () => {
    const { pathname } = this.props.location;
    if (
      pathname === "/dashboard" ||
      pathname.includes("/dashboard/result-summary")
    ) {
      return "dashboard";
    }
    if (
      pathname.includes("/dashboard/pdf-analyzer") ||
      pathname.includes("/dashboard/pdf-analyzer/result-summary") ||
      pathname.includes("/dashboard/pdf-analyzer/result-summary/compare")
    ) {
      return "pdf-analyzer";
    }
    return null;
  };

  handleLogout = () => {
    this.props.LogoutAction();
  };

  handleCollapseDisable = () => {
    this.props.CollapseCX2SidebarActiveAction();
  };

  render() {
    const currentPath = this.handlePath();

    return (
      <div className="flex flex-col w-full md:w-[16.625rem] h-screen items-center justify-between gap-4 md:gap-[4rem] bg-white border-r z-20 overflow-auto">
        <div className="flex flex-col mt-4 md:mt-[3rem] gap-[2rem] w-full overflow-auto px-2 md:px-0">
          <div className="w-[3.438rem] mx-auto">
            <img src={CESSVG} alt="CESSVG" className="w-full h-auto" />
          </div>
          <div className="flex flex-col gap-4 md:gap-[1.625rem] w-full md:w-[12.5rem] h-fit mx-auto">
            <Link to={"/dashboard"}>
              <button
                className={`w-full h-[2.125rem] pl-2 md:pl-[1rem] gap-[0.3rem] border-none flex items-center ${
                  currentPath === "dashboard"
                    ? "bg-primary-blue text-white"
                    : "text-black"
                }`}
              >
                {currentPath === "dashboard" ? (
                  <img
                    src={WhiteFileCsvSVG}
                    alt="CSV Analyzer"
                    className="w-5 h-5 md:w-auto md:h-auto"
                  />
                ) : (
                  <img
                    src={CSVSVG}
                    alt="Home"
                    className="w-5 h-5 md:w-auto md:h-auto"
                  />
                )}
                <div className="text-sm md:text-base">SmartSurvey</div>
              </button>
            </Link>

            <Link to={"/dashboard/pdf-analyzer"}>
              <button
                className={`w-full h-[2.125rem] pl-2 md:pl-[1rem] gap-[0.3rem] border-none flex items-center ${
                  currentPath === "pdf-analyzer"
                    ? "bg-primary-blue text-white"
                    : "text-black"
                }`}
              >
                {currentPath === "pdf-analyzer" ? (
                  <img
                    src={WhiteFilePdfSVG}
                    alt="CSV Analyzer"
                    className="w-5 h-5 md:w-auto md:h-auto"
                  />
                ) : (
                  <img
                    src={FilePdfSVG}
                    alt="Home"
                    className="w-5 h-5 md:w-auto md:h-auto"
                  />
                )}
                <div className="text-sm md:text-base">SmartExtract</div>
              </button>
            </Link>
            <Link to={"/dashboard/smartflow"}>
              <button className="w-full h-[2.125rem] pl-2 md:pl-[1rem] gap-[0.3rem] flex items-center text-black">
                <img
                  src={PaintBucketSVG}
                  alt="CES Bot"
                  className="w-5 h-5 md:w-auto md:h-auto"
                />
                <div className="text-sm md:text-base">SmartFlow</div>
              </button>
            </Link>
            <Link to={"/dashboard/file-manager"}>
              <button className="w-full h-[2.125rem] pl-2 md:pl-[1rem] gap-[0.3rem] flex items-center text-black">
                <img
                  src={FilesBlackSVG}
                  alt="Recent Files"
                  className="w-5 h-5 md:w-auto md:h-auto"
                />
                <div className="text-sm md:text-base">All Files</div>
              </button>
            </Link>
            <Link to={"/dashboard/ai-chatbot"}>
              <button className="w-full h-[2.125rem] pl-2 md:pl-[1rem] gap-[0.3rem] flex items-center text-black">
                <img
                  src={CESRobotSVG}
                  alt="CES Bot"
                  className="w-5 h-5 md:w-auto md:h-auto"
                />
                <div className="text-sm md:text-base">CX2</div>
              </button>
            </Link>
            <Link to={"/dashboard/ai-models"}>
              <button className="w-full h-[2.125rem] pl-2 md:pl-[1rem] gap-[0.3rem] flex items-center text-black">
                <img
                  src={AIGenerateIconDarkSVG}
                  alt="CES Bot"
                  className="w-5 h-5 md:w-auto md:h-auto"
                />
                <div className="text-sm md:text-base">Assist Gen AI</div>
              </button>
            </Link>
            <Link to={"/dashboard/message-overview"}>
              <button className="w-full h-[2.125rem] pl-2 md:pl-[1rem] gap-[0.3rem] flex items-center text-black">
                <img
                  src={EnvelopeIconSVG}
                  alt="CES Messaging"
                  className="w-5 h-5 md:w-auto md:h-auto"
                />
                <div className="flex gap-2 md:gap-[1rem] items-center">
                  <div className="text-sm md:text-base">Messages</div>
                  {this.props.allOverviewChatSessions && (
                    <div className="flex items-center justify-center text-white rounded-[25%] bg-primary-blue size-4 md:size-[2rem] text-xs md:text-base">
                      {this.props.allOverviewChatSessions.length}
                    </div>
                  )}
                </div>
              </button>
            </Link>
            <button className="w-full h-[2.125rem] pl-2 md:pl-[1rem] gap-[0.3rem] flex items-center text-black">
              <img
                src={SettingGearSVG}
                alt="Settings"
                className="w-5 h-5 md:w-auto md:h-auto"
              />
              <div className="text-sm md:text-base">Settings</div>
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-[1.625rem] w-full md:w-[12.5rem] h-fit mb-4 md:mb-[2rem]">
          <button
            onClick={this.handleLogout}
            className="w-full h-[2.125rem] pl-2 md:pl-[1rem] gap-[0.3rem] flex items-center text-black"
          >
            <img
              className="w-5 h-5 md:size-[1.5rem]"
              src={LogoutSVG}
              alt="Logout"
            />
            <div className="text-sm md:text-base">Logout</div>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allOverviewChatSessions: state.messaging.allOverviewChatSessions,
});

export default withRouterHooks(
  connect(mapStateToProps, { LogoutAction, FetchAllMessagesForOverview })(
    DashboardSidebar
  )
);
