import React, { Component } from "react";
import { Link } from "react-router-dom";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import LoginImage from "../../Assets/jpeg/LoginOverLay.jpg";
import {
  LoginAction,
  FlushAuthenticationAction,
} from "../../Actions/Authentication";
import { ResetAnalysisReduxStateAction } from "../../Actions/Analysis";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import Spinner from "../Animations/Spinner/Spinner";
import AuthenticationMessageDispatcher from "./AuthenticationMessageDispatcher";
import ShowPasswordToggle from "./ShowPasswordToggle";

class Login extends Component {
  state = {
    email: "",
    password: "",
    loggingIn: false,
    showErrorMessage: false,
    showPassword: false,
  };

  static propTypes = {
    LoginAction: PropTypes.func.isRequired,
    ResetAnalysisReduxStateAction: PropTypes.func.isRequired,
    FlushAuthenticationAction: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
    authenticationNotSuccessful: PropTypes.bool,
  };

  timeoutId = null;

  componentDidMount() {
    this.props.FlushAuthenticationAction();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevProps.isAuthenticated
    ) {
      this.setState({ loggingIn: true });
      this.timeoutId = setTimeout(() => {
        if (this.handlePath().path === "/login") {
          this.props.navigate("/dashboard");
        } else if (this.handlePath().path === "/admin-login") {
          this.props.navigate("/admin-dashboard");
        }
      }, 3000);
    }
    if (
      this.props.authenticationNotSuccessful &&
      this.props.authenticationNotSuccessful !==
        prevProps.authenticationNotSuccessful
    ) {
      this.setState({ showErrorMessage: true });
      this.timeoutId = setTimeout(() => {
        this.setState({ showErrorMessage: false });
        this.props.FlushAuthenticationAction();
      }, 2000);
    }
  }

  componentWillUnmount() {
    // Clear the timeout to prevent memory leaks
    clearTimeout(this.timeoutId);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.LoginAction(this.state.email, this.state.password);
    this.props.ResetAnalysisReduxStateAction();
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handlePath = () => {
    let pathVar = {
      text: "",
      path: "",
    };
    if (this.props.location.pathname === "/login") {
      pathVar.text = "Login";
      pathVar.path = "/login";
    } else if (this.props.location.pathname === "/admin-login") {
      pathVar.text = "Admin Login";
      pathVar.path = "/admin-login";
    }

    return pathVar;
  };

  toggleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    const { email, password, showPassword } = this.state;

    return (
      <div className="flex w-full min-h-screen justify-center items-center px-4 py-6 md:py-0 md:px-0">
        <div className="flex flex-col items-center w-full md:w-auto">
          <div className="flex flex-col md:flex-row w-full max-w-[1200px] shadow-lg md:shadow-none rounded-lg md:rounded-none overflow-hidden">
            {/* Form section */}
            <div className="flex items-center justify-center w-full md:w-[32.063rem] py-8 md:py-0 md:h-[55.188rem] bg-white">
              <div className="flex flex-col items-center gap-4 md:gap-[2rem] w-full max-w-[400px] md:max-w-[27.313rem] px-4 md:px-0">
                {this.state.showErrorMessage && (
                  <AuthenticationMessageDispatcher />
                )}
                <div className="flex flex-col justify-center items-center w-full gap-2 md:gap-[1rem]">
                  <div className="w-16 h-16 md:w-[4.438rem] md:h-[4.438rem]">
                    <img src={CESSVG} alt="CESSVG" className="w-full h-full" />
                  </div>
                  <div className="font-SFPro font-[700] text-xl md:text-[2.5rem] text-center">
                    {this.handlePath().text}
                  </div>
                  <div className="text-center font-SFPro font-[400] text-sm md:text-[1.25rem] text-[#443F3F]">
                    Welcome back! please enter your details
                  </div>
                  <form
                    onSubmit={this.onSubmit}
                    className="flex flex-col gap-4 md:gap-[1rem] w-full"
                  >
                    <div>
                      <div className="font-inter font-[600] text-sm md:text-base">
                        Email
                      </div>
                      <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        placeholder="Enter your email"
                        className="w-full md:w-[27.313rem] h-12 md:h-[3.75rem] border-[1px] border-primary-blue rounded-[0.625rem] mt-[0.4rem] pl-3 md:pl-[1.25rem] focus:outline-primary-blue"
                        autoComplete="on"
                      />
                    </div>
                    <div className="relative">
                      <div className="font-inter font-[600] text-sm md:text-base">
                        Password
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={this.onChange}
                        placeholder="Enter password"
                        className="w-full md:w-[27.313rem] h-12 md:h-[3.75rem] border-[1px] border-primary-blue rounded-[0.625rem] mt-[0.4rem] pl-3 md:pl-[1.25rem] focus:outline-primary-blue"
                        autoComplete="on"
                      />
                      <ShowPasswordToggle
                        showPassword={showPassword}
                        toggleShowPassword={this.toggleShowPassword}
                      />
                    </div>
                    <div className="w-full">
                      <button
                        type="submit"
                        className="flex gap-[1rem] justify-center items-center w-full h-12 md:h-[3.75rem] rounded-[2.438rem] bg-primary-blue text-white text-[600] font-inter text-base md:text-[1.5rem]"
                      >
                        <div>Login</div>
                        {this.state.loggingIn && <Spinner />}
                      </button>
                    </div>
                  </form>
                  {this.handlePath().path === "/login" ? (
                    <div className="flex flex-col items-center gap-2 text-sm md:text-base">
                      <div>
                        Don't have an account?{" "}
                        <Link to={"/signup"} className="text-primary-blue">
                          Signup
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={"/forgotpassword"}
                          className="text-primary-blue"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {/* Image section - hidden on small screens */}
            <div className="hidden md:block">
              <img
                src={LoginImage}
                alt="LoginImage"
                className="w-[45.625rem] h-[55.188rem] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication?.isAuthenticated,
  authenticationNotSuccessful:
    state.authentication?.authenticationNotSuccessful,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    LoginAction,
    FlushAuthenticationAction,
    ResetAnalysisReduxStateAction,
  })(Login)
);
