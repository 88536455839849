import React, { Component } from "react";
import ReportSVG from "../../../Assets/svg/ReportSVG.svg";
import { Link } from "react-router-dom";

class HeroSection extends Component {
  render() {
    return (
      <div className="flex justify-center items-center w-full h-fit px-4 md:px-[2rem] py-6 md:py-[3rem]">
        <div className="relative flex gap-4 md:gap-[4.5rem] flex-col justify-between items-center w-full max-w-[1300px]">
          <div className="hidden md:block absolute right-[-7rem] lg:right-[-14rem] bottom-[0rem] max-w-full">
            <img src={ReportSVG} className="w-full md:w-auto" alt="Report" />
          </div>
          <div className="font-[700] text-3xl sm:text-4xl md:text-5xl lg:text-[6rem] font-Alexandria text-center">
            <span className="text-primary-blue">Supercharge</span> Your Data
            Analytics, Quick and Easy
          </div>
          <div className="font-[500] text-base sm:text-xl md:text-2xl lg:text-[2rem] font-Alexandria text-center">
            Analyze surveys and PDFs instantly with AI-driven insights. Get
            quick, clear results to make smarter decisions without the hassle
          </div>
          <div className="flex flex-col sm:flex-row gap-4 md:gap-[2rem] font-Alexandria font-[600] text-base md:text-[1.5rem] w-full sm:w-auto">
            <button className="px-4 md:px-[2rem] py-2 md:py-[1rem] rounded-[2rem] border border-[#504A4A] text-[#303857] w-full sm:w-auto">
              Contact Us
            </button>
            <Link to="/login" className="w-full sm:w-auto">
              <button className="w-full px-4 md:px-[2rem] py-2 md:py-[1rem] bg-primary-blue rounded-[2rem] text-white">
                Analyze Data
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default HeroSection;
