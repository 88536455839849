import React, { Component } from "react";
import TrashSVG from "../../../Assets/svg/TrashSVG.svg";
import { FetchCSVAction } from "../../../Actions/Analysis";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import ArrowLeftSVG from "../../../Assets/svg/ArrowLeftSVG.svg";

class RenderOtherFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalPages: 1,
    };
  }

  static propTypes = {
    FetchCSVAction: PropTypes.func.isRequired,
    csvData: PropTypes.array,
  };

  componentDidMount() {
    // Fetch CSV files when the component mounts
    this.props.FetchCSVAction();
  }

  renderPagination = () => {
    const { currentPage, totalPages } = this.state;
    const pageNumbers = [];

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      if (currentPage > 3) pageNumbers.push("...");

      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - 2) pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }

    return (
      <div className="flex justify-between items-center gap-4 mt-4">
        {/* Previous Button */}
        <button
          onClick={() => this.handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="flex justify-center items-center gap-[2rem] px-4 py-2 border rounded-md text-gray-700 bg-white hover:bg-gray-100 disabled:opacity-50 text-[1rem]"
        >
          <img className="size-[1rem] " src={ArrowLeftSVG} alt="Next" />{" "}
          Previous
        </button>

        {/* Page Number Buttons */}
        <div className="flex gap-[2rem]">
          {pageNumbers.map((number, index) => (
            <button
              key={index}
              onClick={() =>
                typeof number === "number" && this.handlePageChange(number)
              }
              className={`px-4 py-2 rounded-md text-[1rem] ${
                currentPage === number
                  ? "bg-blue-600 text-white font-bold"
                  : "bg-white text-gray-700 hover:bg-gray-100"
              } ${number === "..." ? "cursor-default" : ""}`}
              disabled={number === "..."}
            >
              {number}
            </button>
          ))}
        </div>

        {/* Next Button */}
        <button
          onClick={() => this.handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="flex justify-center items-center gap-[2rem] px-4 py-2 border rounded-md text-gray-700 bg-white hover:bg-gray-100 disabled:opacity-50 text-[1rem]"
        >
          Next{" "}
          <img
            className=" rotate-180 size-[1rem]"
            src={ArrowLeftSVG}
            alt="Next"
          />
        </button>
      </div>
    );
  };

  render() {
    const { csvData } = this.props;

    return (
      <div className="font-[700] font-SFPro text-[1.25rem] md:text-[2rem] flex flex-col gap-[1rem] md:gap-[2rem]">
        <div>Other Files (Survey IQ & Smart Extract)</div>
        <div className="overflow-x-auto">
          {/* Header Row */}
          <div className="min-w-[800px] grid grid-cols-[1fr_1fr_1fr_1fr_1fr] py-[1rem] bg-[#F2F2F3] px-[1rem] gap-[1rem] font-inter font-[400] text-[1rem] md:text-[1.25rem]">
            <div>File Name</div>
            <div>Date Uploaded</div>
            <div>File Size</div>
            <div>Status</div>
            <div>Actions</div>
          </div>

          {/* Files Container */}
          <div className="min-w-[800px]">
            {csvData && csvData.length > 0 ? (
              csvData.map((file) => (
                <div
                  key={file.id}
                  className="grid grid-cols-[1fr_1fr_1fr_1fr_1fr] py-[1rem] bg-white px-[1rem] gap-[1rem] font-inter font-[400] text-[1rem] md:text-[1.25rem] border border-x-[#DDD9D9] border-t-0"
                >
                  <div className="truncate">{file.file_name}</div>
                  <div className="truncate">
                    {new Date(file.uploaded_at).toLocaleString()}
                  </div>
                  <div>{file.file_size}</div>
                  <div>{file.processing_status}</div>
                  <div className="flex justify-between w-full md:w-[50%]">
                    <div>View File</div>
                    <button>
                      <img src={TrashSVG} alt="TrashSVG" />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div>No files found.</div>
            )}
          </div>
          {this.renderPagination()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  csvData: state.analysis.csvData, // Adjusted to match the JSON structure
});

export default withRouterHooks(
  connect(mapStateToProps, { FetchCSVAction })(RenderOtherFiles)
); 