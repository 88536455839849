import React, { useState } from "react";
import { ReactTyped as Typed } from "react-typed";
import SmartflowIllustrationSVG from "../../../Assets/svg/SmartflowIllustration.svg";
import CESLogo from "../../../Assets/svg/CESSVG.svg";

const SmartFlowWelcomeDialogue = () => {
  const [firstTypingComplete, setFirstTypingComplete] = useState(false);

  return (
    <div className="w-[70%] h-[80%] flex flex-col justify-center items-center bg-[#FFFFFF] rounded-[1rem] gap-[3rem] py-[2rem] shadow-custom-3">
      <div className="flex justify-center">
        <img className="size-[14rem]" src={CESLogo} alt="CES Logo" />
      </div>
      <div className="flex flex-col items-center justify-center gap-[2.5rem] w-full">
        <div className="flex justify-center w-full">
          <Typed
            className="font-[700] font-inter text-[2.5rem]"
            strings={["Welcome to SmartFlow auto-form filler assistant."]}
            typeSpeed={15}
            onComplete={() => setFirstTypingComplete(true)}
            showCursor={false}
          />
        </div>
        <div className="flex justify-center w-full mt-[1rem]">
          {firstTypingComplete && (
            <Typed
              className="font-[500] font-inter text-[2rem] text-[#667085]"
              strings={["I will be assisting you in filling out your form."]}
              typeSpeed={15}
              showCursor={false}
            />
          )}
        </div>
        <div className="font-inter font-[700] text-white px-[10rem] py-[1rem] rounded-[0.625rem] text-[2rem] flex justify-center mt-[2rem] bg-[#0D6EFD]">
          Get Started
        </div>
      </div>
    </div>
  );
};

export default SmartFlowWelcomeDialogue;
