import React, { Component } from "react";
import { Link } from "react-router-dom";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import ChangePasswordImage from "../../Assets/jpeg/OverLay.jpg";
import ArrowLeftSVG from "../../Assets/svg/ArrowLeftSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import { ConfirmPasswordAction } from "../../Actions/Authentication";

class Confirmforgotpassword extends Component {
  state = {
    new_password: "",
    new_password_confirm: "",
  };

  static propTypes = {
    ConfirmPasswordAction: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    forgotPasswordEmail: PropTypes.string,
    ForgotPasswordOtp: PropTypes.string,
    isPasswordChangedSuccess: PropTypes.bool,
  };

  // Handle form submission
  onSubmit = (e) => {
    e.preventDefault();
    const { new_password, new_password_confirm } = this.state;

    if (new_password !== new_password_confirm) {
      alert("Passwords do not match.");
      return;
    }

    const { ForgotPasswordOtp, forgotPasswordEmail } = this.props;

    // Dispatch ConfirmPasswordAction with the OTP and email
    this.props.ConfirmPasswordAction(
      forgotPasswordEmail,
      ForgotPasswordOtp,
      new_password
    );
  };

  // Handle input change and update state
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.isPasswordChangedSuccess &&
      this.props.isPasswordChangedSuccess !== prevProps.isPasswordChangedSuccess
    ) {
      this.props.navigate("/login");
    }
  }

  render() {
    return (
      <div className="flex w-full min-h-screen justify-center items-center px-4 py-6 md:py-0 md:px-0">
        <div className="flex flex-col items-center w-full md:w-auto">
          <div className="flex flex-col md:flex-row w-full max-w-[1200px] shadow-lg md:shadow-none rounded-lg md:rounded-none overflow-hidden">
            {/* Form section */}
            <div className="flex items-start pt-4 md:pt-[1rem] justify-center w-full md:w-[32.063rem] h-auto md:h-[55.188rem] bg-white px-4 md:px-0">
              <div className="flex flex-col justify-center items-center w-full max-w-[400px] md:max-w-[27.313rem] gap-6 md:gap-[3rem]">
                <div className="flex flex-col gap-6 md:gap-[6rem] w-full">
                  <div className="w-16 h-8 md:w-[4.438rem] md:h-[1.925rem]">
                    <img src={CESSVG} alt="CESSVG" className="w-full h-full" />
                  </div>
                  <div className="flex flex-col">
                    <div className="font-SFPro font-[700] text-xl md:text-[2.5rem] relative right-0">
                      Set new password
                    </div>
                    <div className="font-SFPro font-[400] text-sm md:text-[1.25rem] text-[#443F3F]">
                      Must be at least 8 characters
                    </div>
                  </div>
                </div>

                <form
                  onSubmit={this.onSubmit}
                  className="flex flex-col gap-4 md:gap-[1.5rem] w-full"
                >
                  <div>
                    <input
                      type="password"
                      name="new_password"
                      value={this.state.new_password}
                      onChange={this.onChange}
                      placeholder="Enter your password"
                      className="w-full md:w-[27.313rem] h-12 md:h-[3.75rem] pl-3 md:pl-[1rem] border-x-0 border-t-0 border-[1px] border-[#8E3A90] mt-[0.4rem] focus:border-b-[#8E3A90] focus:outline-none"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="password"
                      name="new_password_confirm"
                      value={this.state.new_password_confirm}
                      onChange={this.onChange}
                      placeholder="Confirm your password"
                      className="w-full md:w-[27.313rem] h-12 md:h-[3.75rem] pl-3 md:pl-[1rem] border-x-0 border-t-0 border-[1px] border-[#8E3A90] mt-[0.4rem] focus:border-b-[#8E3A90] focus:outline-none"
                      required
                    />
                  </div>
                  <div className="w-full">
                    <button
                      type="submit"
                      className="w-full h-12 md:h-[3.75rem] bg-[#7EC14D] text-white text-[600] font-inter text-base md:text-[1.5rem]"
                    >
                      Reset Password
                    </button>
                  </div>
                </form>

                <Link
                  to={"/login"}
                  className="flex gap-2 md:gap-[1rem] items-center mt-4"
                >
                  <div className="w-6 h-6 md:w-[2rem]">
                    <img
                      src={ArrowLeftSVG}
                      alt="arrow left svg"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="text-[#443F3F] text-sm md:text-[1.125rem] font-inter font-[400]">
                    Back to login in
                  </div>
                </Link>
              </div>
            </div>

            {/* Image section - hidden on small screens */}
            <div className="hidden md:block">
              <img
                src={ChangePasswordImage}
                alt="Change Password Image"
                className="w-[45.625rem] h-[55.188rem] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPasswordEmail: state.authentication?.forgotPasswordEmail,
  ForgotPasswordOtp: state.authentication?.ForgotPasswordOtp,
  isPasswordChangedSuccess: state.authentication?.isPasswordChangedSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { ConfirmPasswordAction })(Confirmforgotpassword)
);
