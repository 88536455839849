import React from "react";
import "./gearspinner.css";

const GearsSpinner = () => {
  return (
    <div className="gears-container">
      <div className="gear gear-left"></div>
      <div className="gear gear-right"></div>
    </div>
  );
};

export default GearsSpinner;
