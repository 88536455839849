import React, { Component } from "react";
import SurveyIQ from "../../../Assets/png/SurveyIQ.png";
import SmartExtract from "../../../Assets/png/SmartExtract.png";
import SmartFlow from "../../../Assets/png/SmartFlow.png";
import AIAssistant from "../../../Assets/png/AIAssistant.png";
import Messaging from "../../../Assets/png/Messaging.png";

class OurServices extends Component {
  render() {
    const services = [
      {
        img: SurveyIQ,
        title: "SurveyIQ",
        description:
          "Analyze CSV files effortlessly and transform data into summarized reports, available for download in TXT and PDF formats.",
      },
      {
        img: SmartExtract,
        title: "SmartExtract",
        description:
          "Convert handwritten or printed paper forms into structured text or PDF files with ease.",
      },
      {
        img: SmartFlow,
        title: "SmartFlow",
        description:
          "Digitally fill out forms and extract them into paper formats, eliminating the hassle of manual paperwork.",
      },
      {
        img: AIAssistant,
        title: "AI Assistant",
        description:
          "Generate in-depth summaries of surveys using AI-powered models tailored to your needs.",
      },
      {
        img: Messaging,
        title: "Messaging",
        description:
          "Effortlessly manage customer interactions with AI. Automate bulk responses, analyze sentiment, and enhance customer experiences, all in one seamless platform.",
      },
    ];

    return (
      <div className="flex flex-col gap-4 md:gap-[2rem] justify-center items-center w-full h-fit px-4 md:px-[2rem] py-6 md:py-[3rem]">
        <div className="relative flex gap-4 md:gap-[2rem] items-center flex-col w-full max-w-[1300px] font-Alexandria">
          <div className="font-[600] text-2xl sm:text-3xl md:text-[4rem] text-primary-blue text-center">
            Our services
          </div>
          <div className="font-[500] text-base sm:text-xl md:text-2xl lg:text-[2.25rem] text-[#667085] text-center leading-normal md:leading-[50px] px-2 md:px-6">
            Explore our suite of intelligent tools designed to streamline data
            analysis, form processing, and survey insights—helping you work
            smarter and faster.
          </div>
        </div>
        <div className="w-full max-w-[1300px] px-2 sm:px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-[6rem] place-items-center">
            {services.map((service, index, arr) => (
              <div
                key={index}
                className={`flex flex-col p-4 md:p-[2.5rem] bg-[#EBEAEA] relative items-center ${
                  index === arr.length - 1
                    ? "col-span-1 sm:col-span-2 flex justify-center"
                    : ""
                } w-full`}
              >
                <div className="w-full h-auto md:w-[32.7504rem] md:h-[26.4504rem] flex items-center justify-center">
                  <img
                    className="w-full h-auto max-h-[250px] md:max-h-none object-contain"
                    src={service.img}
                    alt={service.title}
                  />
                </div>
                <div className="absolute bottom-0 w-full py-2 md:py-[2rem] bg-[#D9D9D97A]/[48%]">
                  <div className="text-center font-[700] font-SFPro text-xl md:text-[2.25rem]">
                    {service.title}
                  </div>
                  <div className="text-center px-2 md:px-[1rem] font-inter font-[500] text-sm md:text-[1.15rem]">
                    {service.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="font-[600] font-Alexandria text-base md:text-[1.5rem] text-white bg-primary-blue px-6 sm:px-8 md:px-[2rem] py-2 md:py-[1rem] rounded-[11px]">
          Learn More
        </button>
      </div>
    );
  }
}

export default OurServices;
