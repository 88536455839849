import React, { Component } from "react";
import CESSVG from "../../../Assets/svg/CESSVG.svg";
import { Link } from "react-router-dom";
import OverViewIconDarkSVG from "../../../Assets/svg/OverViewIconDarkSVG.svg";
import OverViewIconLightSVG from "../../../Assets/svg/OverViewIconLightSVG.svg";
import WhatsappIconSVG from "../../../Assets/svg/WhatsappIconSVG.svg";
import EmailIconSVG from "../../../Assets/svg/EmailIconSVG.svg";
import PhoneCallIconSVG from "../../../Assets/svg/PhoneCallIconSVG.svg";
import VoicemailIconSVG from "../../../Assets/svg/VoicemailIconSVG.svg";
import MicrosoftOutlookLogoWhiteSVG from "../../../Assets/svg/MicrosoftOutlookLogoWhiteSVG.svg";
import WhatsappLogoWhiteSVG from "../../../Assets/svg/WhatsappLogoWhiteSVG.svg";
import PhoneCallWhiteSVG from "../../../Assets/svg/PhoneCallWhiteSVG.svg";
import VoicemailWhiteSVG from "../../../Assets/svg/VoicemailWhiteSVG.svg";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import PropTypes from "prop-types";

class MessagingSideBar extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  checkPath = () => {
    const { pathname } = this.props.location;
    return pathname === "/dashboard/message-overview";
  };

  checkpath2 = () => {
    const { pathname } = this.props.location;
    if (pathname === "/dashboard/message-overview") {
      return "overview";
    }
    if (pathname.includes("/dashboard/message-overview/whatsapp")) {
      return "whatsapp";
    }
    if (pathname.includes("/dashboard/message-overview/email")) {
      return "email";
    }
  };

  render() {
    return (
      <div
        className={`fixed flex flex-col shrink-0 ${
          this.checkpath2() === "overview" ? "w-[16.625rem]" : "w-[6rem]"
        } h-screen items-center  gap-[4rem] bg-white border-r z-20`}
      >
        <div
          className={`flex flex-col mt-[3rem] gap-[2rem] overflow-scroll ${
            this.checkpath2() === "overview"
              ? ""
              : "justify-center items-center"
          }`}
        >
          <Link to={"/dashboard"}>
            <div className="w-[3.438rem]">
              <img src={CESSVG} alt="CESSVG" className="w-full h-auto" />
            </div>
          </Link>
          <div
            className={`flex flex-col gap-[1.625rem] ${
              this.checkpath2() === "overview" ? "w-[12.5rem]" : "w-fit"
            } h-fit`}
          >
            <Link to={"/dashboard/message-overview"}>
              <button
                className={`w-full h-[3.125rem] rounded-[3px] gap-[0.3rem] flex items-center  text-black font-[400] font-inter text-[1.25rem] ${
                  this.checkpath2() === "overview"
                    ? "bg-primary-blue pl-[1rem]"
                    : " justify-center"
                }`}
              >
                {this.checkpath2() === "overview" ? (
                  <img src={OverViewIconLightSVG} alt="Home" />
                ) : (
                  <img src={OverViewIconDarkSVG} alt="Home" />
                )}
                {this.checkpath2() === "overview" && (
                  <div className={`${this.checkPath ? "text-white" : ""}`}>
                    Overview
                  </div>
                )}
              </button>
            </Link>
            <Link to={"/dashboard/message-overview/whatsapp"}>
              <button
                className={`w-full h-[3.125rem] rounded-[3px] ${
                  this.checkpath2() === "whatsapp"
                    ? "bg-primary-blue justify-center"
                    : ""
                } ${
                  this.checkpath2() === "overview"
                    ? "pl-[1rem]"
                    : "justify-center"
                } gap-[0.3rem] flex items-center text-black font-[400] font-inter text-[1.25rem]`}
              >
                {this.checkpath2() === "whatsapp" ? (
                  <img src={WhatsappLogoWhiteSVG} alt="Recent Files" />
                ) : (
                  <img src={WhatsappIconSVG} alt="Recent Files" />
                )}

                {this.checkPath() && <div>Whatsapp</div>}
              </button>
            </Link>

            <Link to={"/dashboard/message-overview/email"}>
              <button
                className={`w-full h-[3.125rem] rounded-[3px] ${
                  this.checkpath2() === "email"
                    ? "bg-primary-blue justify-center"
                    : ""
                } ${
                  this.checkpath2() === "overview"
                    ? "pl-[1rem]"
                    : "justify-center"
                } gap-[0.3rem] flex items-center text-black font-[400] font-inter text-[1.25rem]`}
              >
                {this.checkpath2() === "email" ? (
                  <img src={MicrosoftOutlookLogoWhiteSVG} alt="CES Messaging" />
                ) : (
                  <img src={EmailIconSVG} alt="CES Messaging" />
                )}
                {this.checkPath() && <div>Email</div>}
              </button>
            </Link>
            <Link to={"/dashboard/message-overview/calls"}>
              <button className="w-full h-[3.125rem] rounded-[3px] pl-[1rem] gap-[0.3rem] flex items-center  text-black font-[400] font-inter text-[1.25rem]">
                <img src={PhoneCallIconSVG} alt="Settings" />
                {this.checkPath() && <div>Call</div>}
              </button>
            </Link>
            <button className="w-full h-[3.125rem] rounded-[3px] pl-[1rem] gap-[0.3rem] flex items-center  text-black font-[400] font-inter text-[1.25rem]">
              <img src={VoicemailIconSVG} alt="Settings" />
              {this.checkPath() && <div>Voice Mail</div>}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouterHooks(connect(null, {})(MessagingSideBar));
