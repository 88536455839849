import React, { Component } from "react";
import CSVSVGReceive from "../../../Assets/svg/CSVSVGRecieve.svg";
import { SendToMail, RenderOnSite } from "../../../Actions/Analysis";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";

class CSVReceiveMethodPopUp extends Component {
  static propTypes = {
    SendToMail: PropTypes.func.isRequired,
    RenderOnSite: PropTypes.func.isRequired,
    GetUserAction: PropTypes.func.isRequired,
  };

  handleSendToMail = () => {
    this.props.SendToMail();
  };

  handleRenderOnSite = () => {
    this.props.RenderOnSite();
  };
  render() {
    return (
      <div className="flex flex-col gap-[2rem] w-[44.938rem] h-[29.375rem] bg-white justify-center rounded-lg items-center z-100">
        <img
          src={CSVSVGReceive}
          alt="AccountCreatedSuccessSVG"
          className="w-[5.25rem] h-[5.018rem]"
        />
        <div className="font-[600] font-inter text-[1.5rem] w-[30.027rem] text-center leading-[1.828rem]">
          Where would you like to receive your analyzed data?
        </div>
        <div className="flex w-[34.063rem] h-[2.75rem] justify-evenly font-SFPro leading-[1.193rem]">
          <button
            onClick={this.handleSendToMail}
            className="w-[11.875rem] h-[2.75rem] border-[1px] text-primary-blue font-[500] text[1px] border-primary-blue-light"
          >
            Email
          </button>
          <button
            onClick={this.handleRenderOnSite}
            className="w-[11.875rem] h-[2.75rem] border-[1px] bg-[#55BC0A] text-[#F9F9F9] font-[700]"
          >
            Here in the App
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  csvAnalysisSuccess: state.analysis.csvAnalysisSuccess,
  isSentToMail: state.analysis.isSentToMail,
  isRenderOnSite: state.analysis.isRenderOnSite,
  user: state.authentication.user,
  userFetchSuccess: state.authentication.userFetchSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { SendToMail, RenderOnSite })(CSVReceiveMethodPopUp)
);
