import axios, { refreshAxios } from "./Axios";
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAILED,
  ACCOUNT_VERIFICATION_SUCCESS,
  ACCOUNT_VERIFICATION_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS,
  FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_FAIL,
  PASSWORD_CHANGED_SUCCESS,
  PASSWORD_CHANGED_FAIL,
  FLUSH_ALL_AUTHENTICATION_REDUX_STATE,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAIL,
} from "./Types";
import { returnErrors } from "./Errors";
import { FLUSH } from "redux-persist";

// SIGNUP USER
export const SignupAction =
  (
    first_name,
    last_name,
    middle_name,
    company_name,
    email,
    password,
    confirm_password
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      middle_name,
      company_name,
      email,
      password,
      confirm_password,
    });

    axios
      .post(`/api/userauth/create-user/`, body, config)
      .then((res) => {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: SIGNUP_FAIL,
        });
      });
  };

// Verify Email
export const VerifyEmailAction = (email, otp) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, otp });

  return axios
    .post(`/api/userauth/verify-user-email/`, body, config)
    .then((res) => {
      dispatch({
        type: ACCOUNT_VERIFICATION_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: ACCOUNT_VERIFICATION_FAIL,
      });
    });
};

// Login User
export const LoginAction = (email, password) => (dispatch) => {
  // Set headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Convert data to JSON
  const body = JSON.stringify({ email, password });

  // Make the login request
  return axios
    .post(`/api/userauth/login/`, body, config)
    .then((res) => {
      // Dispatch login success with response data
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      // Optionally, store tokens in local storage
      if (res.data.access) {
        localStorage.setItem("access", res.data.access);
        localStorage.setItem("refresh", res.data.refresh);
      }

      // Return response data if needed for further actions
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        // Server responded with an error (e.g., 400 or 500)
        dispatch(returnErrors(err.response.data, err.response.status));
      } else if (err.request) {
        // No response was received (e.g., server timeout or network issue)
        dispatch(returnErrors("No response received from server", 500));
      } else {
        // Something happened in setting up the request
        dispatch(returnErrors("Error in setting up the request", 500));
      }

      // Dispatch login failure
      dispatch({
        type: LOGIN_FAIL,
        payload: "Login failed Invalid credentials",
      });
    });
};

// Forgot Password
export const ForgotPasswordAction = (email) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  return axios
    .post(`/api/userauth/reset-password/`, body, config)
    .then((res) => {
      // Manually include the email in the action payload
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: {
          message: res.data.message, // Assuming the API returns a message
          email: email, // Manually include the email
        },
      });
      return res.data;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
      });
    });
};

// Add Forgot Password OTP to Redux
export const AddForgotPasswordToOTPAction = (otp) => (dispatch) => {
  dispatch({
    type: FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS,
    payload: otp,
  });
};

//Comfirm Password
export const ConfirmPasswordAction =
  (email, otp, new_password) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email, otp, new_password });

    return axios
      .post(`/api/userauth/change-password/`, body, config)
      .then((res) => {
        dispatch({
          type: PASSWORD_CHANGED_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: PASSWORD_CHANGED_FAIL,
        });
      });
  };

// Token Config
export const tokenConfig = (getState) => {
  const token = localStorage.getItem("access");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

// REFRESH TOKEN
export const refreshToken = () => async (dispatch) => {
  const refresh = localStorage.getItem("refresh");

  if (!refresh) {
    console.error("No refresh token available.");
    dispatch({ type: TOKEN_REFRESH_FAILED });
    return Promise.reject(new Error("No refresh token available"));
  }

  try {
    console.log("Authentication.js: Attempting to refresh token...");
    // Use the refreshAxios instance to avoid interceptor loops
    const res = await refreshAxios.post("/api/userauth/token/refresh/", {
      refresh,
    });
    const newAccessToken = res.data.access;
    const newRefreshToken = res.data.refresh;
    console.log("Authentication.js: Tokens refreshed successfully");

    // Store the new tokens
    localStorage.setItem("access", newAccessToken);
    localStorage.setItem("refresh", newRefreshToken);

    // Dispatch a success action
    dispatch({
      type: TOKEN_REFRESH_SUCCESS,
      payload: res.data,
    });

    return { newAccessToken, newRefreshToken }; // Return the new tokens
  } catch (err) {
    console.error(
      "Authentication.js: Token refresh failed:",
      err.response ? err.response.data : err.message
    );

    // Clear tokens if refresh fails due to an invalid refresh token
    if (err.response && err.response.status === 401) {
      console.log("Authentication.js: Refresh token is invalid or expired.");
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
    }

    // Dispatch a failure action
    dispatch({ type: TOKEN_REFRESH_FAILED });
    return Promise.reject(err);
  }
};

// LOGOUT USER
export const LogoutAction = () => (dispatch) => {
  const refresh = localStorage.getItem("refresh");
  const access = localStorage.getItem("access");

  // Prepare for logout regardless of token presence
  const cleanupAndDispatch = () => {
    // Clean up tokens from localStorage
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    // Dispatch logout action to update Redux state
    dispatch({ type: LOGOUT_SUCCESS });
    dispatch({ type: FLUSH_ALL_AUTHENTICATION_REDUX_STATE });
    console.log("Logout completed. Tokens removed and state cleared.");
  };

  // If no tokens exist, just clean up and return
  if (!refresh || !access) {
    cleanupAndDispatch();
    return;
  }

  // Attempt to notify the server about logout
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
  };

  // Send logout request to invalidate tokens on server
  axios
    .post("/api/userauth/logout/", { refresh }, config)
    .then((response) => {
      console.log("Server-side logout successful:", response.data);
    })
    .catch((err) => {
      console.error(
        "Server-side logout failed:",
        err.response ? err.response.data : err.message
      );
    })
    .finally(() => {
      // Always clean up, even if server request fails
      cleanupAndDispatch();
    });
};

// Flush all Authentication data (this can be used elsewhere if needed)
export const FlushAuthenticationAction = () => (dispatch) => {
  dispatch({ type: FLUSH_ALL_AUTHENTICATION_REDUX_STATE });
};

export const GetUserAction = () => (dispatch, getState) => {
  axios
    .get(`/api/userauth/get-user/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FETCH_USER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: FETCH_USER_FAIL,
      });
    });
};

export const ResendOTP = (email) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  return axios
    .post(`/api/userauth/send-opt/`, body, config)
    .then((res) => {
      dispatch({
        type: RESEND_OTP_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: RESEND_OTP_FAIL,
      });
    });
};
