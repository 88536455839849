import React, { Component } from "react";
import TrustSVG from "../../../Assets/svg/Trust.svg";

class AboutUs extends Component {
  render() {
    return (
      <div className="flex justify-center items-center w-full h-fit px-4 md:px-[2rem] py-6 md:py-[3rem]">
        <div className="relative flex gap-4 md:gap-[2rem] items-center flex-col w-full max-w-[1300px]">
          <div className="w-full max-w-[250px] md:max-w-none">
            <img src={TrustSVG} className="w-full h-auto" alt="Trust" />
          </div>
          <div className="font-[700] font-Alexandria text-2xl sm:text-3xl md:text-[4rem] text-center">
            <span className="text-primary-blue">Trust</span> Us with your Data
          </div>
          <div className="font-[500] font-Alexandria text-base sm:text-xl md:text-2xl lg:text-[2rem] text-[#667085] text-center leading-normal md:leading-[50px] px-2 md:px-6">
            At CES, we're more than a service provider – we're your partner in
            turning data into actionable insights. Focused on innovation and
            understanding your customers, we help your business thrive with
            impactful experiences.
          </div>
          <button className="font-[600] font-Alexandria text-base md:text-[1.5rem] text-white bg-primary-blue px-6 sm:px-8 md:px-[3rem] py-2 md:py-[1rem] rounded-[11px]">
            Learn More
          </button>
        </div>
      </div>
    );
  }
}

export default AboutUs;
