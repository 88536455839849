import React, { Component } from "react";
import Customize from "./Customize";
import { connect } from "react-redux";
import { addMessageToRedux } from "../../../../Actions/Messaging";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import PropTypes from "prop-types";

class CustomizeAndMoreInformation extends Component {
  state = {
    isCustomizeActive: false,
  };

  static propTypes = {
    addMessageToRedux: PropTypes.func.isRequired,
    message: PropTypes.string,
    ShowMessageIntent: PropTypes.func.isRequired,
    phone_number: PropTypes.string,
  };

  handleCustomizeResponse = () => {
    // Toggle dropdown visibility
    this.setState((prevState) => ({
      isCustomizeActive: !prevState.isCustomizeActive,
    }));
  };

  closeCustomize = () => {
    this.setState({ isCustomizeActive: false });
  };

  handleAddMessageToRedux = () => {
    const { message, addMessageToRedux } = this.props;
    if (message) {
      console.log("Adding message to Redux:", message);
      addMessageToRedux(message);
    }
  };

  render() {
    const { ShowMessageIntent, phone_number } = this.props;
    console.log("Phone number:", phone_number);

    return (
      <div className="absolute flex flex-col w-[17rem] z-50 right-0 shadow-custom-2">
        {/* Show the Customize component only when isCustomizeActive is true */}
        {this.state.isCustomizeActive && (
          <Customize closeCustomize={this.closeCustomize} />
        )}
        {/* <div
          className="px-[1rem] py-[1rem] text-center bg-white hover:bg-[#EEEDED] font-inter font-[400] text-[1.5rem] cursor-pointer"
          onClick={this.handleCustomizeResponse} // Toggle the dropdown
        >
          Customize response
        </div> */}
        <div
          onClick={() => {
            this.handleAddMessageToRedux(); // Add message to Redux
            ShowMessageIntent(phone_number); // Trigger ShowMessageIntent
          }}
          className="px-[1rem] py-[1rem] text-center bg-white hover:bg-[#EEEDED] font-inter font-[400] text-[1.5rem] cursor-pointer"
        >
          More information
        </div>
      </div>
    );
  }
}

export default withRouterHooks(
  connect(null, { addMessageToRedux })(CustomizeAndMoreInformation)
);
