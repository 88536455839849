import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardSidebar from "./DashboardSidebar";
import DashboardHome from "../Dashboard/AnalysisAndExtract/DashboardHome";
import Void from "./Voids/Void";
import Void2 from "./Voids/Void2";
import Void3 from "./Voids/Void3";
import DataAnalysisSummary from "../Dashboard/AnalysisAndExtract//DataAnalysisSummary";
import AIChatBotSideBar from "./AIChatBot/AIChatBotSideBar";
import AIChatBot from "./AIChatBot/AIChatBot";
import AIModelsChatBotSideBar from "./AIModules/AIChatBot/AIChatBotSideBar";
import AIModelsChatBot from "./AIModules/AIChatBot/AIChatBot";
import PDFExtractorComparator from "../Dashboard/AnalysisAndExtract//PDFExtractorComparator";
import CollapseWindowBottonSVG from "../../Assets/svg/CollapseWindowBottonSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import { CollapseCX2SidebarActiveAction } from "../../Actions/Interactions";
import FileProcessedSuccess from "../Dashboard/AnalysisAndExtract/FileProcessedSuccess";
import { ResetAnalysisReduxStateAction } from "../../Actions/Analysis";
import Messaging from "./Messaging/Overview/Messaging";
import MessagingSideBar from "./Messaging/MessagingSideBar";
import Whatsapp from "./Messaging/WhatsApp/Whatsapp";
import Email from "./Messaging/Email/Email";
import Smartflow from "../Dashboard/SmartFlow/SmartFlowHome";
import AIModuleHome from "./AIModules/AIModuleHome";
import SmartflowPreview from "./SmartFlow/SmartflowPreview";
import Calls from "./Messaging/PhoneCalls/Calls";
import FileManagerHome from "./FileManager/FileManagerhome";

class DashboardRouter extends Component {
  static propTypes = {
    isCollapsed: PropTypes.bool,
    CollapseCX2SidebarActiveAction: PropTypes.func.isRequired,
    CollapseCX2SidebarInactiveAction: PropTypes.func.isRequired,
    pdfAnalysisSuccess: PropTypes.bool,
    ResetAnalysisReduxStateAction: PropTypes.func.isRequired,
    isSentToMail: PropTypes.bool,
    csvAnalysisSuccess: PropTypes.bool,
  };

  state = {
    showSuccessPopup: false,
    showMobileSidebar: false,
  };

  popupTimeout = null;

  handleCollapseEnable = () => {
    this.props.CollapseCX2SidebarActiveAction();
  };

  toggleMobileSidebar = () => {
    this.setState({ showMobileSidebar: !this.state.showMobileSidebar });
  };

  componentDidUpdate(prevProps) {
    if (
      (this.props.pdfAnalysisSuccess &&
        this.props.pdfAnalysisSuccess !== prevProps.pdfAnalysisSuccess &&
        !this.props.isSentToMail) ||
      (this.props.csvAnalysisSuccess &&
        this.props.csvAnalysisSuccess !== prevProps.csvAnalysisSuccess &&
        !this.props.isSentToMail)
    ) {
      console.log("this got triggered");
      this.setState({ showSuccessPopup: true });

      // Clear any previous timeout before setting a new one
      clearTimeout(this.popupTimeout);
      this.popupTimeout = setTimeout(() => {
        this.setState({ showSuccessPopup: false });
        if (
          this.props.location.pathname.includes("/dashboard/result-summary/")
        ) {
          return;
        } else {
          this.props.ResetAnalysisReduxStateAction();
        }
      }, 8000);
    }
  }

  componentWillUnmount() {
    // Clear timeout if component unmounts to prevent memory leaks
    clearTimeout(this.popupTimeout);
  }

  render() {
    const { showMobileSidebar } = this.state;

    // Mobile menu button component
    const MobileMenuButton = () => (
      <button
        className="fixed z-50 top-4 left-4 md:hidden bg-white p-2 rounded-md shadow-md"
        onClick={this.toggleMobileSidebar}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d={
              showMobileSidebar
                ? "M6 18L18 6M6 6l12 12"
                : "M4 6h16M4 12h16M4 18h16"
            }
          />
        </svg>
      </button>
    );

    // Shared layout for dashboard routes
    const DashboardLayout = ({ children }) => (
      <div className="flex w-full min-h-screen overflow-x-hidden">
        {/* Desktop Sidebar */}
        <div className="hidden md:block">
          <DashboardSidebar />
        </div>

        {/* Mobile Sidebar - conditionally rendered */}
        {showMobileSidebar && (
          <div className="md:hidden fixed top-0 left-0 z-40 h-full">
            <DashboardSidebar />
          </div>
        )}

        {/* Mobile Menu Button */}
        <MobileMenuButton />

        {/* <div className="hidden md:block bg-[#F9F9F9] w-[0.5rem] shrink-0"></div> */}
        <div className="flex w-full flex-col md:flex-row">
          {/* <Void /> */}
          {children}
          {this.state.showSuccessPopup && <FileProcessedSuccess />}
        </div>
      </div>
    );

    // Same pattern for AI chatbot routes
    const AIChatbotLayout = ({ children }) => (
      <div className="flex w-full min-h-screen overflow-x-hidden">
        {/* Desktop Sidebar */}
        {this.props.isCollapsed && (
          <div className="hidden md:block relative z-[100]">
            <AIChatBotSideBar />
          </div>
        )}

        {/* Mobile Sidebar - conditionally rendered */}
        {showMobileSidebar && (
          <div className="md:hidden fixed top-0 left-0 z-[100] h-full">
            <AIChatBotSideBar />
          </div>
        )}

        {/* Collapse button for desktop */}
        {!this.props.isCollapsed && (
          <button className="fixed z-50 top-[3rem] left-[2rem] hidden md:block">
            <img
              src={CollapseWindowBottonSVG}
              onClick={this.handleCollapseEnable}
              alt="Collapse"
            />
          </button>
        )}

        {/* Mobile Menu Button */}
        <MobileMenuButton />

        <div className="hidden md:block bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
        <div className="flex w-full flex-col md:flex-row">
          {this.props.isCollapsed}
          {children}
          {this.state.showSuccessPopup && <FileProcessedSuccess />}
        </div>
      </div>
    );

    // Similar layout for Messaging routes
    const MessagingLayout = ({ children }) => (
      <div className="flex w-full min-h-screen overflow-x-hidden">
        {/* Desktop Sidebar */}
        <div className="hidden md:block">
          <MessagingSideBar />
        </div>

        {/* Mobile Sidebar - conditionally rendered */}
        {showMobileSidebar && (
          <div className="md:hidden fixed top-0 left-0 z-40 h-full">
            <MessagingSideBar />
          </div>
        )}

        {/* Mobile Menu Button */}
        <MobileMenuButton />

        <div className="flex w-full overflow-hidden flex-col md:flex-row">
          <Void3 />
          {children}
        </div>
      </div>
    );

    // Simple full-width layout for other routes
    const FullWidthLayout = ({ children }) => (
      <div className="flex w-full min-h-screen overflow-x-hidden">
        <div className="flex w-full overflow-hidden">{children}</div>
      </div>
    );

    return (
      <Routes>
        <Route
          path="/"
          element={
            <DashboardLayout>
              <DashboardHome />
            </DashboardLayout>
          }
        />
        <Route
          path="/pdf-analyzer"
          element={
            <DashboardLayout>
              <DashboardHome />
            </DashboardLayout>
          }
        />
        <Route
          path="/result-summary/:id"
          element={
            <DashboardLayout>
              <DataAnalysisSummary />
            </DashboardLayout>
          }
        />
        <Route
          path="/pdf-analyzer/result-summary/:id"
          element={
            <DashboardLayout>
              <DataAnalysisSummary />
            </DashboardLayout>
          }
        />
        <Route
          path="/ai-chatbot"
          element={
            <AIChatbotLayout>
              <AIChatBot />
            </AIChatbotLayout>
          }
        />
        <Route
          path="/ai-chatbot/:sessionId"
          element={
            <AIChatbotLayout>
              <AIChatBot />
            </AIChatbotLayout>
          }
        />
        <Route
          path="/pdf-analyzer/result-summary/:id/compare"
          element={
            <DashboardLayout>
              <PDFExtractorComparator />
            </DashboardLayout>
          }
        />
        <Route
          path="/message-overview"
          element={
            <MessagingLayout>
              <Messaging />
            </MessagingLayout>
          }
        />
        <Route
          path="/message-overview/whatsapp"
          element={
            <MessagingLayout>
              <Whatsapp />
            </MessagingLayout>
          }
        />
        <Route
          path="/message-overview/whatsapp/:id"
          element={
            <MessagingLayout>
              <Whatsapp />
            </MessagingLayout>
          }
        />
        <Route
          path="/message-overview/email"
          element={
            <MessagingLayout>
              <Email />
            </MessagingLayout>
          }
        />
        <Route
          path="/message-overview/calls"
          element={
            <MessagingLayout>
              <Calls />
            </MessagingLayout>
          }
        />
        <Route
          path="/smartflow"
          element={
            <FullWidthLayout>
              <Smartflow />
            </FullWidthLayout>
          }
        />
        <Route
          path="/ai-models"
          element={
            <FullWidthLayout>
              <AIModuleHome />
            </FullWidthLayout>
          }
        />
        <Route
          path="/smartflow/preview/:id"
          element={
            <FullWidthLayout>
              <SmartflowPreview />
            </FullWidthLayout>
          }
        />
        <Route
          path="/ai-models/ai-chatbot"
          element={
            <AIChatbotLayout>
              <AIModelsChatBot />
            </AIChatbotLayout>
          }
        />
        <Route
          path="/ai-models/ai-chatbot/:sessionId"
          element={
            <AIChatbotLayout>
              <AIModelsChatBot />
            </AIChatbotLayout>
          }
        />
        <Route
          path="/file-manager"
          element={
            <DashboardLayout>
              <FileManagerHome />
            </DashboardLayout>
          }
        />
      </Routes>
    );
  }
}

const mapStateToProps = (state) => ({
  isCollapsed: state.interactions.isCollapsed,
  pdfAnalysisSuccess: state.analysis.pdfAnalysisSuccess,
  isSentToMail: state.analysis.isSentToMail,
  csvAnalysisSuccess: state.analysis.csvAnalysisSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    CollapseCX2SidebarActiveAction,
    ResetAnalysisReduxStateAction,
  })(DashboardRouter)
);
