// AIInput.js

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SendIconSVG from "../../../Assets/svg/SendIconSVG.svg";
import StopCircleSVG from "../../../Assets/svg/StopCircleSVG.svg";
import {
  chatInputAction,
  FetchIndividualChatSessionAction,
  resetHasResponded,
  StartNewChatSessionAction,
  ContinueChatSessionAddSessionIDToReduxAction,
  FetchChatSessionsAction,
  hasClicked,
  hasNotClicked,
  AddAIModelToRedux,
  addOptimisticMessage,
} from "../../../Actions/AI";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";

class AIInput extends Component {
  state = {
    message: "",
    isLoading: false,
    loadingTimeout: null,
  };

  static propTypes = {
    FetchIndividualChatSessionAction: PropTypes.func.isRequired,
    continueChatSessionId: PropTypes.number,
    chatInputAction: PropTypes.func.isRequired,
    hasResponded: PropTypes.bool,
    resetHasResponded: PropTypes.func.isRequired,
    isNewChatSession: PropTypes.bool,
    isContinueChatSessionIdAddedToRedux: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
    FetchChatSessionsAction: PropTypes.func.isRequired,
    StartNewChatSessionAction: PropTypes.func.isRequired,
    ContinueChatSessionAddSessionIDToReduxAction: PropTypes.func.isRequired,
    isClicked: PropTypes.bool,
    model: PropTypes.string,
    moduleName: PropTypes.string,
  };

  componentDidUpdate(prevProps) {
    // If we got a response, reset loading state
    if (!prevProps.hasResponded && this.props.hasResponded) {
      const id = this.props.location.pathname.split("/").pop();
      this.props.FetchIndividualChatSessionAction(id);

      // Clear any existing timeout
      if (this.state.loadingTimeout) {
        clearTimeout(this.state.loadingTimeout);
      }

      // Reset states - both loading and clicked
      this.setState({ isLoading: false, loadingTimeout: null });

      setTimeout(() => {
        this.props.resetHasResponded();
        this.props.hasNotClicked();
      }, 1000);
    }
  }

  componentWillUnmount() {
    // Clear any pending timeouts when component unmounts
    if (this.state.loadingTimeout) {
      clearTimeout(this.state.loadingTimeout);
    }
  }

  handleInputChange = (event) => {
    this.setState({ message: event.target.value });
  };

  // Handle Enter key press
  handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && !this.state.isLoading) {
      event.preventDefault();
      this.handleSendClick();
    }
  };

  handleSendClick = () => {
    const { message, isLoading } = this.state;
    // Don't do anything if already loading or message is empty
    if (isLoading || !message.trim()) return;

    const {
      continueChatSessionId,
      chatInputAction,
      isNewChatSession,
      StartNewChatSessionAction,
      ContinueChatSessionAddSessionIDToReduxAction,
      navigate,
      FetchChatSessionsAction,
      location,
    } = this.props;

    // Set loading state and clear input
    this.setState({
      isLoading: true,
      message: "", // Reset the message input immediately
      // Set a timeout that will clear the loading state if no response in 15 seconds
      loadingTimeout: setTimeout(() => {
        console.log("Request timed out - resetting send button state");
        this.setState({ isLoading: false, loadingTimeout: null });
        this.props.hasNotClicked();
      }, 15000), // 15 seconds timeout
    });

    this.props.hasClicked();

    try {
      if (isNewChatSession && !continueChatSessionId) {
        // Start a new chat session
        StartNewChatSessionAction()
          .then((data) => {
            if (data && data.id) {
              // Update Redux with the new session ID
              ContinueChatSessionAddSessionIDToReduxAction(data.id);
              // Navigate to the new chat session
              navigate(`/dashboard/ai-chatbot/${data.id}`);
              // Send the message with the new session ID
              try {
                chatInputAction(message, data.id);
              } catch (error) {
                console.error("Error sending message:", error);
                this.setState({ isLoading: false });
                this.props.hasNotClicked();
              }
              // Fetch updated chat sessions
              FetchChatSessionsAction();
            } else {
              // Reset loading state if we didn't get a valid session
              this.setState({ isLoading: false });
              this.props.hasNotClicked();
            }
          })
          .catch((error) => {
            // Handle error when starting new chat session
            console.error("Error starting new chat session:", error);
            this.setState({ isLoading: false });
            this.props.hasNotClicked();
          });
      } else if (continueChatSessionId && !isNewChatSession) {
        // Extract session ID from the current path
        const path = location?.pathname?.split("/");
        const id = path[path.length - 1];
        if (id) {
          // Send the message with the extracted session ID
          try {
            chatInputAction(message, id);
          } catch (error) {
            console.error("Error sending message:", error);
            this.setState({ isLoading: false });
            this.props.hasNotClicked();
          }
        } else {
          // Reset loading state if we couldn't get a session ID
          this.setState({ isLoading: false });
          this.props.hasNotClicked();
        }
      }
    } catch (error) {
      // Global error handler
      console.error("Error in handleSendClick:", error);
      this.setState({ isLoading: false });
      this.props.hasNotClicked();
    }
  };

  render() {
    const { message, isLoading } = this.state;
    const isButtonDisabled = isLoading || message.trim() === "";

    return (
      <div
        className="w-full flex flex-col gap-2 md:gap-[0.5rem]"
        style={{ zIndex: 1 }}
      >
        <div className="relative w-full">
          <input
            type="text"
            value={message}
            onChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            disabled={isLoading}
            className="w-full h-10 md:h-[4.688rem] placeholder:text-sm md:placeholder:text-[1.25rem] text-sm md:text-[1.25rem] bg-[#F7F8FA] rounded-full md:rounded-[2.063rem] border-[1px] border-[#D5D7DA] focus:border-transparent focus:outline-none px-3 md:px-[2rem] py-2"
            placeholder="Chat with CESGPT"
          />
          <button
            onClick={this.handleSendClick}
            disabled={isButtonDisabled}
            className={`absolute bottom-1 md:bottom-[0.75rem] right-1 md:right-[1rem] rounded-full md:rounded-[6.313rem] flex w-auto md:w-[8.5rem] h-8 md:h-[3.188rem] ${
              isButtonDisabled ? "opacity-60 cursor-not-allowed" : ""
            } bg-primary-blue justify-center items-center px-3 md:px-6`}
          >
            {isLoading ? (
              <div className="pulsing-icon">
                <img
                  src={StopCircleSVG}
                  alt="Stop Circle"
                  className="w-5 h-5 md:w-auto md:h-auto"
                />
              </div>
            ) : (
              <div className="flex gap-1 md:gap-[0.625rem] items-center">
                <div className="text-[#FFFFFF] font-[600] font-inter text-xs md:text-[1.5rem] hidden md:block">
                  Send
                </div>
                <img
                  src={SendIconSVG}
                  alt="Send Icon"
                  className="w-5 h-5 md:w-auto md:h-auto"
                />
              </div>
            )}
          </button>
        </div>
        <div className="font-[500] font-SFPro text-xs md:text-[1.25rem] flex flex-wrap items-center justify-center text-center">
          <span>All data exchange is safe with us</span>
          <Link to="/learn-more" className="text-[#3366CC] ml-1 font-[400]">
            Learn more
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  continueChatSessionId: state.ai.continueChatSessionId,
  hasResponded: state.ai.hasResponded,
  isNewChatSession: state.ai.isNewChatSession,
  isContinueChatSessionIdAddedToRedux:
    state.ai.isContinueChatSessionIdAddedToRedux,
  isClicked: state.ai.isClicked,
  model: state.ai.model,
  moduleName: state.ai.modelName,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    chatInputAction,
    FetchIndividualChatSessionAction,
    resetHasResponded,
    StartNewChatSessionAction,
    ContinueChatSessionAddSessionIDToReduxAction,
    FetchChatSessionsAction,
    hasClicked,
    hasNotClicked,
    AddAIModelToRedux,
    addOptimisticMessage,
  })(AIInput)
);
